import React, { useState } from "react";
import { View, SafeAreaView, StyleSheet } from "react-native";
import { KeyboardAvoidingView } from "react-native-web";
import { Layout, Text, Input } from "@ui-kitten/components";
import { useSelector } from "react-redux";
import AppIcon from "../components/AppIcon";
import { LockIcon } from "../components/Icons";
import ErrorBoundary from "../components/ErrorBoundary";

const Lock = ({ onPinSubmit }) => {
  /**states */
  const [pin, setPin] = useState("");

  /**selectors */
  const account = useSelector((state) => state.dataStore?.account);

  /**event-handlers */
  const handlePinChange = (val) => {
    setPin(val);
    //see if length is 4 && is number
    if (isNaN(val)) return;
    else if (val.length === 4) onPinSubmit(val);
  };

  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <ErrorBoundary>
        <Layout style={styles.form} level="1">
          {/*<Avatar
            source={{ uri: account?.profile_photo_url }}
            style={{ width: 74, height: 74 }}
          />*/}
          <AppIcon height="100" width="100" color={"#2D3748"} />

          <View style={{ paddingVertical: 12 }}>
            <Text category="s1">{`Welcome back, ${account?.name?.given_name}`}</Text>
            <Text category="s2" appearance="hint">
              {"Enter your PIN code"}
            </Text>
          </View>

          <KeyboardAvoidingView>
            <Input
              style={styles.input}
              //label="PIN"
              placeholder="PIN"
              keyboardType="numeric"
              maxLength={4}
              //  value={number}
              onChangeText={handlePinChange}
              //  onSubmitEditing={() => onsubmit()}
              secureTextEntry={true}
              accessoryRight={LockIcon}
            />
          </KeyboardAvoidingView>
        </Layout>
        </ErrorBoundary>
      </SafeAreaView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "background-basic-color-2",
  },
  form: {
    flex: 1,
    paddingHorizontal: 4,
    paddingVertical: 24,
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    marginHorizontal: 12,
    marginVertical: 8,
  },
  middleContainer: {
    flexDirection: "row",
  },
  middleInput: {
    width: 128,
  },
  addButton: {
    marginHorizontal: 16,
    marginVertical: 24,
  },
});

export default React.memo(Lock);
