import React, { useState, useCallback, useRef } from "react";
import {
  VirtualizedList,
  RefreshControl,
  View,
  SafeAreaView,
  FlatList,
} from "react-native-web";

//<!--libs-->
import { FixedSizeList } from "react-window";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { Divider, Layout } from "@ui-kitten/components";

//<!--redux-->

/**local */
import JournalItem from "./JournalItem";

//const journalExtractKey = ({ guid }) => guid;

const JournalList = (props) => {
  /**destructure */
  const { journals, toggleFavorites, deleteJournal, navigateToDetails } = props;

  /**custom-hooks */
  const inputRef = useRef();

  /**states */
  const [refreshing, setRefreshing] = useState(false);

  /**functions */
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 500);
  }, []);

  const isItemLoaded = (index) => {
    // Check if the item at the index has already been loaded
    return Boolean(journals[index]);
  };

  /**renderers */
  const renderJournalItem = ({ item }) => (
    <JournalItem
      item={item}
      index={item?.guid}
      toggleFavorites={toggleFavorites}
      deleteJournalItem={deleteJournal}
      navigateToDetails={navigateToDetails}
    />
  );

  const _renderItem = useCallback(
    ({ item: journal }) => (
      <>
        <JournalItem
          key={journal.guid}
          item={journal}
          index={journal.guid}
          toggleFavorites={toggleFavorites}
          deleteJournal={deleteJournal}
          navigateToDetails={navigateToDetails}
        />
        <Divider />
      </>
    ),
    [journals]
  );

  return (
    <Layout style={{ flex: 1 }} level="3">
      {journals?.length > 0 && (
        //<ReactVirtualizedAutoSizer>
        //  {({ height, width }) => (
        //    <InfiniteLoader
        //      isItemLoaded={isItemLoaded}
        //      itemCount={journals?.length}
        //    >
        //      {({ onItemsRendered, ref }) => (
        //        <FixedSizeList
        //          ref={inputRef}
        //          //useIsScrolling={true}
        //          onItemsRendered={onItemsRendered}
        //          itemCount={journals?.length}
        //          itemSize={82}
        //          height={height}
        //          width={width}
        //        >
        //          {({ index, style }) => (
        //            <View style={style}>
        //              {_renderItem({ item: journals[index] })}
        //            </View>
        //          )}
        //        </FixedSizeList>
        //      )}
        //    </InfiniteLoader>
        //  )}
        //</ReactVirtualizedAutoSizer>
        //<FlatList
        //  keyExtractor={(item) => item?.guid}
        //  virtualized={true}
        //  data={journals}
        //  ItemSeparatorComponent={Divider}
        //  renderItem={renderJournalItem}
        //  removeClippedSubviews={true}
        ///>
        <VirtualizedList
          keyExtractor={(item) => item?.guid}
          virtualized={true}
          data={journals}
          renderItem={renderJournalItem}
          ItemSeparatorComponent={Divider}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          removeClippedSubviews={true}
          initialNumToRender={12}
          getItem={(data, index) => data[index]}
          getItemCount={(data) => data?.length}
        />
      )}
    </Layout>
  );
};

export default React.memo(JournalList);
