import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { Layout, Text } from "@ui-kitten/components";
import { LocationIcon } from "./Icons";

const LocationContainer = ({ locationInfo }) => {
  return (
    <Layout
      style={{
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      {locationInfo ? (
        <>
          <LocationIcon style={{ marginTop: -6 }} />
          <Text category="p2" appearance="hint" style={{ paddingLeft: 5 }}>
            {locationInfo}
          </Text>
        </>
      ) : null}
    </Layout>
  );
};

export default React.memo(LocationContainer);
