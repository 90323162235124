import React from "react";
import { View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

/**UI */
import { Layout, StyleService } from "@ui-kitten/components";

/**local */
import AppIcon from "../../components/AppIcon";

const Header = (props) => (
  <Layout style={styles.header} level="2">
    <SafeAreaView>
      <View style={styles.profileContainer}>
        {/*<Avatar size="giant" source={require("../assets/icon.png")} />*/}
        <AppIcon
          height="48"
          width="48"
          color={props.selectedTheme === "light" ? "#000" : "#F7FAFC"}
        />
      </View>
    </SafeAreaView>
  </Layout>
);

const styles = StyleService.create({
  header: {
    paddingVertical: 16,
    paddingHorizontal: 16,
    justifyContent: "center",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingLeft: 16,
    paddingBottom: 3,
  },
  profileContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  profileName: {
    marginHorizontal: 16,
  },

  icon: {
    width: 22,
    height: 22,
    marginRight: 8,
  },
});

export default React.memo(Header);
