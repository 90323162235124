// export const ticksToDate = (ticks) => {
//   return Number.isInteger(ticks)
//     ? new Date(ticks / 1e4 + new Date("0001-01-01T00:00:00Z").getTime())
//     : null;
// };

export const dateToTicks = (date) => {
  return 621355968e9 + date.getTime() * 1e4;
};

export const ticksToDate = (ticks) => {
  if (Number.isInteger(ticks)) {
    const date = new Date(
      ticks / 1e4 + new Date("0001-01-01T00:00:00Z").getTime()
    );

    const localDateTime = new Date(date.toISOString());
    //new Date(date.toLocaleString());

    return {
      date: date,
      utcDate: ("0" + date.getUTCDate()).slice(-2),
      utcMonth: date.toLocaleString("default", { month: "long" }),
      utcYear: date.getUTCFullYear().toString(),

      localDate: ("0" + localDateTime.getDate()).slice(-2),
      localYear: localDateTime.getFullYear(),
    };
  } else {
    console.log(`[dateUtils] NULL NULL NULL`);
    return null;
  }
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const ticksToCustomDateFormat = (ticks) => {
  try {
    const dtObj = ticksToDate(ticks);

    const date = dtObj.localDate;
    const month = dtObj.utcMonth.toString().slice(0, 3);
    const year = dtObj.localYear;
    const day = dtObj.date.toLocaleString("default", { weekday: "short" });
    const time = dtObj.date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
    //const month = monthNames[dtObj.date.getMonth()];

    return {
      date: date,
      month: month,
      year: year,
      day: day,
      time: time,
    };
  } catch (error) {
    console.log("❎[ERR][ticksToCustomDateFormat]", error);
  }
};
