import React, { useEffect, useContext, useState } from "react";

/*<!-- libs-->*/
//import { createNativeStackNavigator } from "react-native-screens/native-stack";
import { NavigationContainer } from "@react-navigation/native";
import { Spinner, Layout } from "@ui-kitten/components";

/*<!-- redux-->*/
import { connect } from "react-redux";
import { refreshAccessToken } from "./../redux/actions/dataAction";
import { setLoading } from "./../redux/actions/dispatchers";

/*<!--others-->*/
import isEmpty from "lodash.isempty";

/*<!--local-->*/
import { DrawerNavigator } from "./Drawer";
//import DrawerNavigator from "./Drawers";
import { AuthStackScreens } from "./Stacks";
import { BottomTabNavigator } from "./Tabs";
import Lock from "../screens/Lock";
import { ThemeContext } from "./../skin/theme-context";
import { compareHash } from "../utils/customUtils";
import HorizontalProgressBar from "../components/HorizontalProgressBar";

//<!--NAVIGATOR-->
const Navigator = (props) => {
  /**destructure */
  const { refreshToken, selectedTheme, isLoggedIn, pin, isLoading } = props;

  //context: app
  const themeContext = useContext(ThemeContext);

  //state for valid token
  const [loading, setLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState();
  const [showPin, setShowPin] = useState(false);

  /**hooks */
  useEffect(() => {
    if (isLoggedIn && !isEmpty(pin)) {
      console.log("[Navigator]-> 🔒Pin is set");
      setShowPin(true);
    }
  }, []);

  useEffect(() => {
    //async function to refresh tokens
    async function checkAndRefreshAccessToken() {
      console.log("[Navigator]-> 🍭Refreshing token..");
      await refreshAccessToken();
      console.log("[Navigator]->✅Refresh done");
      setIsValidToken(true);
      setLoading(false);
    }

    try {
      if (selectedTheme === "dark") themeContext.toggleTheme();

      if (isEmpty(refreshToken)) {
        console.log("[Navigator]-> 🚫No refresh token");
        setIsValidToken(false);
        setLoading(false);
      } else checkAndRefreshAccessToken();
    } catch (error) {
      console.log("❎[ERR][Navigator]->", error);
    }

    return () => {
      /**cleanup */
    };
  }, [refreshToken]);

  /**event-handlers */
  const onPinSubmit = (inputPin) => {
    const isCorrect = compareHash(inputPin, pin);

    if (isCorrect) {
      console.log("🔓pin is correct, unlocking");
      setShowPin(false);
    } else alert("🔒pin is incorrect");
  };

  /**Main */
  if (loading) {
    return (
      <>
        <Layout
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <Spinner size="giant" />
        </Layout>
      </>
    );
  }

  return (
    <>
      {isLoading && <HorizontalProgressBar />}
      {showPin ? (
        <Lock onPinSubmit={onPinSubmit} />
      ) : (
        <NavigationContainer>
          {isValidToken === true ? (
            <DrawerNavigator selectedTheme={selectedTheme} />
          ) : (
            <AuthStackScreens />
          )}
        </NavigationContainer>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  refreshToken: state.dataStore?.refreshToken,
  selectedTheme: state.dataStore?.selectedTheme,
  isLoggedIn: state.dataStore?.isLoggedIn,
  pin: state.dataStore?.pin,
  isLoading: state.dataStore?.loading,
});

export default connect(mapStateToProps, {})(Navigator);
