import { combineReducers } from "redux";
import dataReducer from "./dataReducer";

const applicationReducers = combineReducers({
  dataStore: dataReducer,
});

const rootReducer = (state, action) => {
  return applicationReducers(state, action);
};

export default rootReducer;
