import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 256 256"
    >
      <rect
        width="223.345"
        height="191.333"
        x="16.327"
        y="12.544"
        fill="#cecece"
        rx="15.327"
        ry="15.327"
      ></rect>
      <path
        fill="#afafaf"
        d="M26.327 188.55V27.871a15.327 15.327 0 0115.328-15.327h-10a15.327 15.327 0 00-15.328 15.327V188.55a15.327 15.327 0 0015.327 15.327h10a15.327 15.327 0 01-15.327-15.327z"
      ></path>
      <path
        fill="#e2e2e2"
        d="M229.673 188.55V27.871a15.327 15.327 0 00-15.327-15.327h10a15.327 15.327 0 0115.327 15.327V188.55a15.327 15.327 0 01-15.327 15.327h-10a15.327 15.327 0 0015.327-15.327z"
      ></path>
      <path
        fill="#f8af23"
        d="M239.673 27.871a15.327 15.327 0 00-15.327-15.327H31.655a15.327 15.327 0 00-15.328 15.327v26.673h223.346z"
      ></path>
      <path
        fill="#f87023"
        d="M41.655 12.544h-10a15.327 15.327 0 00-15.328 15.327v26.673h10V27.871a15.327 15.327 0 0115.328-15.327z"
      ></path>
      <path
        fill="#f8d323"
        d="M214.345 12.544h10a15.327 15.327 0 0115.327 15.327v26.673h-10V27.871a15.327 15.327 0 00-15.327-15.327z"
      ></path>
      <circle cx="43.688" cy="33.544" r="7" fill="#f46275"></circle>
      <circle cx="70.65" cy="33.544" r="7" fill="#f46275"></circle>
      <circle cx="97.612" cy="33.544" r="7" fill="#f46275"></circle>
      <rect
        width="99.24"
        height="10.667"
        x="120.073"
        y="28.669"
        fill="#f46275"
        rx="5.333"
        ry="5.333"
      ></rect>
      <circle cx="128" cy="152.357" r="80.126" fill="#f46275"></circle>
      <path
        fill="#f43075"
        d="M57.874 152.357A80.118 80.118 0 01133 72.4a82.762 82.762 0 00-5-.171 80.126 80.126 0 100 160.251c1.681 0 3.345-.069 5-.171a80.118 80.118 0 01-75.126-79.952z"
      ></path>
      <path
        fill="#f48875"
        d="M198.126 152.357A80.118 80.118 0 00123 72.4c1.655-.1 3.319-.171 5-.171a80.126 80.126 0 110 160.251c-1.681 0-3.345-.069-5-.171a80.118 80.118 0 0075.126-79.952z"
      ></path>
      <path
        fill="#3f3679"
        d="M27.323 241.956h-18a1.5 1.5 0 100 3h18a1.5 1.5 0 100-3zM246.68 241.956h-18a1.5 1.5 0 000 3h18a1.5 1.5 0 000-3zM218.479 241.956H37.521a1.5 1.5 0 000 3h180.958a1.5 1.5 0 000-3zM9.32 233.983h237.36a1.5 1.5 0 000-3h-96.809a81.77 81.77 0 0040.114-25.605h34.36a16.846 16.846 0 0016.827-16.827V27.871a16.846 16.846 0 00-16.827-16.827H31.655a16.846 16.846 0 00-16.827 16.827v160.68a16.846 16.846 0 0016.827 16.827h34.36a81.77 81.77 0 0040.114 25.605H9.32a1.5 1.5 0 100 3zm8.508-206.112a13.843 13.843 0 0113.827-13.827h192.691a13.843 13.843 0 0113.827 13.827v25.173H17.828zm13.827 174.507a13.843 13.843 0 01-13.827-13.827V56.044h220.345v132.507a13.843 13.843 0 01-13.827 13.827h-31.911a81.626 81.626 0 10-128.869 0zm17.719-50.021A78.626 78.626 0 11128 230.983a78.715 78.715 0 01-78.626-78.626z"
      ></path>
      <path
        fill="#3f3679"
        d="M43.687 25.044a8.5 8.5 0 108.5 8.5 8.51 8.51 0 00-8.5-8.5zm0 14a5.5 5.5 0 115.5-5.5 5.507 5.507 0 01-5.5 5.5zM70.65 25.044a8.5 8.5 0 108.5 8.5 8.51 8.51 0 00-8.5-8.5zm0 14a5.5 5.5 0 115.5-5.5 5.507 5.507 0 01-5.5 5.5zM97.612 25.044a8.5 8.5 0 108.5 8.5 8.51 8.51 0 00-8.5-8.5zm0 14a5.5 5.5 0 115.5-5.5 5.507 5.507 0 01-5.5 5.5zM213.979 27.169h-88.573a6.833 6.833 0 100 13.667h88.573a6.833 6.833 0 000-13.667zm0 10.667h-88.573a3.833 3.833 0 110-7.667h88.573a3.833 3.833 0 010 7.667zM101.319 152.016a5.5 5.5 0 007.778-7.777l-8.449-8.449 8.449-8.449a5.5 5.5 0 00-7.778-7.777l-8.448 8.448-8.448-8.448a5.5 5.5 0 00-7.778 7.777l8.449 8.449-8.449 8.449a5.5 5.5 0 107.778 7.777l8.448-8.448zM170.907 135.79l8.449-8.449a5.5 5.5 0 10-7.777-7.777l-8.449 8.449-8.449-8.449a5.5 5.5 0 10-7.777 7.777l8.449 8.449-8.449 8.449a5.5 5.5 0 107.777 7.777l8.449-8.449 8.449 8.449a5.5 5.5 0 007.777-7.777zM128 173.439a30.65 30.65 0 00-27.038 16.184 5.5 5.5 0 109.691 5.2 19.679 19.679 0 0134.693 0 5.5 5.5 0 009.691-5.2A30.65 30.65 0 00128 173.439z"
      ></path>
    </svg>
  );
}

export default Icon;