import { manipulateAsync, SaveFormat } from "expo-image-manipulator";

/**libs */
import NetInfo from "@react-native-community/netinfo";
import { Dropbox } from "dropbox";
import isEmpty from "lodash.isempty";
import once from "lodash.once";
import chunk from "lodash.chunk";

/**locals */
import { store } from "./../store";
import { ADD_JOURNAL, UPDATE_JOURNAL_SUCCESS, DELETE_JOURNALS } from "./types";
import {
  setLoading,
  setRefreshToken,
  setAccount,
  setCursor,
  logout,
} from "./dispatchers";
import JournalEntry from "../../models/journalEntry";
import {
  getJournalEntries as getClientJournalEntries,
  getDeletedJournalEntries,
  upsertJournalEntry,
  addJournalEntry,
  updateJournalEntry,
  deleteJournalEntry,
  purgeJournalEntry,
  deleteJournalEntries,
  isJournalEntryExists,
} from "./../../data/db";
import { dateToTicks, ticksToDate } from "./../../utils/dateUtils";
import { asyncForEach } from "./../../utils/commonUtils";
import { getLocationInfo } from "../../utils/customUtils";
import schema from "../../utils/schema";
import { Constants } from "../../constants";
import ScreenLayout from "./../../constants/Layout";

/**global variables */
const _limit = 100;
const _screenWidth = ScreenLayout.window.width;

let _dbx;
let _isMonitoring = false;
let _isLoading = false;

const initDropboxClient = (refreshTokenParam) => {
  try {
    const { refreshToken } = store.getState().dataStore;
    const isRefreshTokenProvided =
      !isEmpty(refreshToken) || !isEmpty(refreshTokenParam);

    if (isEmpty(_dbx)) {
      console.log(`🌙>>[initDropbox]....`);

      _dbx = new Dropbox({
        clientId: "fymo7vmrqb2tasr",
        clientSecret: "yp5huw80tcbb6jv",
        refreshToken: isRefreshTokenProvided
          ? refreshToken || refreshTokenParam
          : undefined,
      });
    }

    if (isRefreshTokenProvided) {
      console.log(`🌙>>[initDropbox]-> Attaching refresh token...`);
      _dbx.auth.setRefreshToken(refreshTokenParam || refreshToken);
    }
  } catch (error) {
    console.log(`💥[initDropbox][error]=>${error}`);
  }
};

//@desc: Gets both the access-token and refresh-token
const getTokens = async (code) => {
  initDropboxClient();

  //redirect uri based on the environment
  const redirectUri = window.location.origin + "/redirect";
  //process.env.NODE_ENV === "development"
  //  ? "http://localhost:19006/redirect"
  //  : "https://app.getglimpses.com/redirect";

  let response = await _dbx.auth.getAccessTokenFromCode(redirectUri, code);
  return response?.result;
};

export const setAccessToken = (result) => async (dispatch) => {
  const { code } = result;
  const tokenResult = await getTokens(code);

  //attach refreshToken to the _dbx object
  const { refresh_token: refreshToken, account_id } = tokenResult;
  initDropboxClient(refreshToken);

  dispatch(setRefreshToken(refreshToken));

  const response = await _dbx.usersGetAccount({
    account_id: account_id,
  });

  dispatch(setAccount(response.result));
};

export const refreshAccessToken = async () => {
  try {
    initDropboxClient();
    await _dbx.auth.checkAndRefreshAccessToken();
  } catch (error) {
    console.log(`❎ERR::[refreshAccessToken]:: ${error}`);
  }
};

// @desc: Download & retrieve metadata.
export const getFileMetadata = async (path) => {
  try {
    const fileMetadata = await _dbx.filesDownload({
      path: `${path}`,
    });
    return fileMetadata;
  } catch (error) {
    console.log(`❎ERR::[getFileMetadata]=>${error}`);
  }
};

export const getLinksMetadata = async (path) => {
  try {
    //get all the links
    const response = await _dbx.sharingListSharedLinks();

    let linksMetadata = response.result;

    linksMetadata.links = linksMetadata?.links
      ?.filter((p) => p[".tag"] === "file")
      .filter((p) => p.name.endsWith(".html"))
      .sort(
        (a, b) => new Date(b.client_modified) - new Date(a.client_modified)
      );

    return linksMetadata;
  } catch (error) {
    console.error(`❎ERR::[dataAction][getServerMetadata]=>${error}`);
  }
};

// @desc: Upload
const uploadJournals = (clientEntries) => async (dispatch) => {
  try {
    console.log(
      `⬆⤴️[uploadJournals][count::${clientEntries?.length}] Uploading journals...`
    );

    asyncForEach(clientEntries, async (clientEntry) => {
      await updateJournal(clientEntry, false)(dispatch);
    });
  } catch (error) {
    console.log(`❎ERR::[uploadJournals]=>${error}`);
  }
};

export const updateJournal =
  (journalItem, isNewEntry = false) =>
  async (dispatch) => {
    //check if journalItem already exists
    const isJournalItemExists = await isJournalEntryExists(journalItem.guid);
    console.log(
      `[dataAction][updateJournal][isJournalItemExists]=>${isJournalItemExists}`
    );

    //copy journalItem object passed with image(if exists) to journalObj, then delete imageData
    let journalObj = {};
    Object.assign(journalObj, journalItem);

    //if imageData exists, then delete the imageData
    if (!isEmpty(journalObj.imageData)) {
      journalObj.hasImage = true;
      journalObj.imageData = null;
      journalObj.imageThumbnail = null;
    }

    if (isJournalItemExists) {
      await updateJournalEntry(journalObj);
    } else {
      await addJournalEntry(journalObj);
    }

    //if journalItem has imageThumbnail but no imageData, then download the entry before upload to get the imageData
    if (
      !isEmpty(journalItem.imageThumbnail) &&
      isEmpty(journalItem.imageData)
    ) {
      try {
        console.log(`🗑[updateJournal][Downloading and updating imageData]`);
        //download the entry
        let response = await getFileMetadata(`/${journalItem.guid}.entry`);

        const fileMetadata = response?.result;

        if (!isEmpty(fileMetadata)) {
          //read the fileBlob
          const buffer = await new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onerror = reject;
            reader.onloadend = () => resolve(reader.result);
            reader.readAsArrayBuffer(fileMetadata.fileBlob);
          });
          //deserialize the buffer
          const entryData = schema.Entry.deserializeBinary(
            new Uint8Array(buffer)
          );
          const journalEntry = new JournalEntry(entryData.toObject());

          //=>update journalItem with imageData
          journalItem.imageData = journalEntry.imageData;
        }
      } catch (error) {
        console.log(
          `❎ERR::[dataAction][updateJournal][Download&Update->ImageData]:: ${error}`
        );
      }
    }

    try {
      let serialized;

      console.log(">>>>JournalItemImageData>>>>", journalItem.imageData);

      try {
        const entry = new schema.Entry();
        entry.setGuid(journalItem.guid);
        entry.setEntrycreateddate(journalItem.entryCreatedDate);
        entry.setEntrymodifieddate(journalItem.entryModifiedDate);
        entry.setTitle(journalItem.title);
        entry.setRichcontent(journalItem.richContent);
        entry.setClientrevision(journalItem.clientRevision);

        const retrievedImageData = getImageData(journalItem);
        if (retrievedImageData) {
          entry.setImagedata(retrievedImageData);
        }

        entry.setClientrev(journalItem.clientRev);
        entry.setIsfavorite(journalItem.isFavorite);

        journalItem.tagsList.forEach((t) => {
          const tag = new schema.Tag();
          tag.setName(t.name);
          entry.addTags(tag);
        });

        entry.setLatitude(journalItem.latitude);
        entry.setLongitude(journalItem.longitude);
        entry.setCelcius(journalItem.celcius);
        entry.setFahrenheit(journalItem.fahrenheit);
        entry.setRelativehumidity(journalItem.relativeHumidity);
        entry.setWindspeedinkph(journalItem.windSpeedInKph);

        serialized = entry.serializeBinary();
      } catch (error) {
        console.log(
          `❎ERR::[dataAction][updateJournal][SERIALIZATION]:: ${error}`
        );
      }

      try {
        //upload to dropbox
        let response = await _dbx.filesUpload({
          path: `/${journalItem.guid}.entry`,
          contents: serialized,
          mode: {
            ".tag": "overwrite",
          },
          autorename: false,
          mute: false,
        });

        let result = response.result;
        journalItem.clientRev = result.rev;
        journalItem.entryModifiedDate = dateToTicks(
          new Date(result.server_modified)
        );
      } catch (error) {
        console.log(
          `❎ERR::[dataAction][updateJournal][UPLOAD-RESPONSE]:: ${error}`
        );
      }
      //clear the image data
      journalItem.imageData = null;

      await updateJournalEntry(journalItem);
    } catch (error) {
      console.log(`❎ERR::[dataAction][updateJournal]:: ${error}`);
    }
  };

export const deleteJournal = async (guid) => {
  try {
    //delete the entry at the client
    await deleteJournalEntry(guid);

    //delete the entry at the server
    await deleteServerEntry(guid);
  } catch (error) {
    console.log(`❎ERR::[dataAction][deleteJournal]->${error}`);
  }
};

const deleteServerEntry = async (guid) => {
  try {
    await _dbx.filesDelete({
      path: `/${guid}.entry`,
    });
  } catch (error) {
    console.log(`❎ERR::[dataAction][deleteServerEntry]->${error}`);
  }
};

export const deleteLinkFileAtServer = async (guid) => {
  try {
    await _dbx.filesDelete({
      path: `/links/${guid}.html`,
    });
  } catch (error) {
    console.log(`❎ERR::[dataAction][deleteLinkFileAtServer]->${error}`);
  }
};

const deleteServerEntries = async (entriesToDeleteAtServer) => {
  try {
    //delete the entries at the server
    const { result: batchDeleteResult } = await _dbx.filesDeleteBatch({
      entries: entriesToDeleteAtServer,
    });

    console.log(
      `[deleteServerEntries][batchDeleteResult]::${batchDeleteResult}`
    );
  } catch (error) {
    console.log(`❎ERR::[dataAction][deleteServerEntries]::${error}`);
  }
};

const getServerMetadata = async (cursor) => {
  try {
    const response = cursor
      ? await _dbx.filesListFolderContinue({ cursor })
      : await _dbx.filesListFolder({ path: "", limit: _limit });
    let serverMetadata = response.result;

    serverMetadata.entries = serverMetadata?.entries
      ?.filter((p) => p[".tag"] === "file" || p[".tag"] === "deleted")
      .filter((p) => p.name.endsWith(".entry"))
      .sort((a, b) => a.size - b.size);

    return serverMetadata;
  } catch (error) {
    console.error(`❎ERR::[dataAction][getServerMetadata]=>${error}`);
  }
};

function* getEntriesGenerator(entries) {
  for (const entry of entries) {
    yield entry;
  }
}

// @desc: Downloads all the entries passed.
const processEntries = (entries, messageType) => async (dispatch) => {
  try {
    if (!_isLoading) {
      _isLoading = true;
      dispatch(setLoading(_isLoading));
    }

    let dispatchers = [];
    const batchSize = 5;
    let index = 0;

    const entriesGenerator = getEntriesGenerator(entries);

    while (index < entries.length) {
      const isLoggedIn = store.getState().dataStore.isLoggedIn;
      if (!isLoggedIn) {
        dispatch(logout());
        break;
      }

      const batch = [];
      for (let j = 0; j < batchSize; j++) {
        const next = entriesGenerator.next();
        if (next.done) break;
        batch.push(next.value);
      }

      while (batch.length > 0) {
        let entry = batch.shift();
        let { result: metadata } = await getFileMetadata(entry.path_lower);

        let buffer = await new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onerror = reject;
          reader.onloadend = () => resolve(reader.result);
          reader.readAsArrayBuffer(metadata?.fileBlob);
        });

        if (!buffer) continue;

        let entryData;

        try {
          entryData = schema.Entry.deserializeBinary(new Uint8Array(buffer));
        } catch (error) {
          console.log(
            `❎ERR::[dataAction][processEntries][deserializeBinary]=>${error}`
          );
        }

        if (isEmpty(entryData)) continue;

        let journalEntry = new JournalEntry(entryData.toObject());
        journalEntry.clientRev = metadata.rev;
        journalEntry.entryModifiedDate = dateToTicks(
          new Date(metadata.server_modified)
        );

        if (!isEmpty(journalEntry.imageData)) {
          let reducedImage = await manipulateAsync(
            journalEntry.imageData?.startsWith(Constants.ImagePrefix)
              ? `${journalEntry.imageData}`
              : `${Constants.ImagePrefix}${journalEntry.imageData}`,
            // pass in the base64 image data here
            [{ resize: { width: 500, maxWidth: 800 } }],
            {
              compress: 0.7,
              format: SaveFormat.JPEG,
              base64: true,
            }
          );

          journalEntry.imageThumbnail = reducedImage.base64;
        }

        //if journalEntry has lat/long, and no location then getLocationInfo and update journalEntry
        const { latitude, longitude, location } = journalEntry;
        if (latitude && longitude && !location) {
          const locationInfo = await getLocationInfo(latitude, longitude);
          if (!isEmpty(locationInfo)) {
            journalEntry.location = locationInfo;
          }
        }

        journalEntry.imageData = null;

        await upsertJournalEntry(journalEntry);

        //release memory
        entryData = null;
        journalEntry = null;
        metadata = null;
        buffer = null;
      }

      index += batchSize;
    }
  } catch (error) {
    console.log(`❎ERR::[dataAction][processEntries]=>${error}`);
    //throw error;
  } finally {
    console.log("FINALLY::processEntries");
    _isLoading = false;
    dispatch(setLoading(_isLoading));
  }
};

//@desc: Gets all the journals from the server
export const getJournals = () => async (dispatch, getState) => {
  initDropboxClient();

  console.log(`🧾[dataAction][getJournals]`);

  try {
    let dataStore = getState().dataStore;
    let storedCursor = dataStore.cursor;

    //let clientEntries = dataStore.journals;
    const clientEntries = await getClientJournalEntries();
    const deletedJournalEntries = await getDeletedJournalEntries();

    let deletedClientEntries = deletedJournalEntries.map((e) => {
      e.isTrash = true;
      return e;
    });

    const allClientEntries = [
      ...new Set([...clientEntries, ...deletedClientEntries]),
    ];

    //maps for quick lookup
    const allClientEntriesMap = new Map(
      allClientEntries.map((clientEntry) => [clientEntry.guid, clientEntry])
    );

    let serverMetadata;
    let hasMore = false;

    let entriesToDeleteAtClient = [];
    let entriesToDownload = [];
    let entriesToUpdate = [];
    let entriesToUpload = [];
    let entriesToDeleteAtServer = [];

    //to handle the server-deleted entries with .tag = "deleted"
    if (storedCursor) {
      try {
        let cont_cursor = storedCursor;

        do {
          const server_metadata = await getServerMetadata(cont_cursor);
          if (!server_metadata) return;

          const { cursor, has_more } = server_metadata;
          hasMore = has_more;
          cont_cursor = cursor;

          const { entries: server_entries } = server_metadata;
          console.log(
            `[getJournals][continue][server_entries]->${server_entries?.length}`
          );

          for (const server_entry of server_entries) {
            // check if the server entry is deleted
            if (server_entry[".tag"] === "deleted") {
              entriesToDeleteAtClient = [
                ...entriesToDeleteAtClient,
                server_entry.name.slice(0, -6),
              ];
              continue;
            }
          }
        } while (hasMore);
      } catch (error) {
        console.log(`❎ERR:: While fetching server deleted entries ${error}`);
      } finally {
        dispatch(setCursor(""));
        hasMore = false;
      }
    }

    do {
      const prevCursor = store.getState().dataStore.cursor;

      serverMetadata = await getServerMetadata(prevCursor);

      if (!serverMetadata) {
        console.log(`🚀[getJournals]-> No serverMetadata`);
        return;
      }

      const { cursor, has_more } = serverMetadata;
      hasMore = has_more;

      const { entries: serverEntries } = serverMetadata;
      console.log(`[getJournals][serverEntries]->${serverEntries?.length}`);

      for (const serverEntry of serverEntries) {
        // check if the server entry is deleted
        if (serverEntry[".tag"] === "deleted") {
          entriesToDeleteAtClient = [
            ...entriesToDeleteAtClient,
            serverEntry.name.slice(0, -6),
          ];
          continue;
        }

        // check if there is a matching client entry
        const matchingClientEntry = allClientEntriesMap.get(
          serverEntry.name.slice(0, -6)
        );
        if (!matchingClientEntry) {
          // server entry not found in client entries
          entriesToDownload = [
            ...entriesToDownload,
            { path_lower: serverEntry.path_lower },
          ];
          continue;
        }

        // found matching client entry, compare client,server modified-time
        let serverModifiedTime = Date.parse(serverEntry.server_modified);
        let clientModifiedTime = Date.parse(
          ticksToDate(Number(matchingClientEntry.entryModifiedDate)).date
        );

        if (matchingClientEntry.isTrash) {
          if (clientModifiedTime >= serverModifiedTime) {
            // client entry is the latest & is in trash, delete at server
            entriesToDeleteAtServer = [
              ...entriesToDeleteAtServer,
              { path: serverEntry.path_lower },
            ];
          } else if (clientModifiedTime < serverModifiedTime) {
            // server entry is the latest, purge entry from trash and download it
            await purgeJournalEntry(matchingClientEntry.guid);

            entriesToDownload = [
              ...entriesToDownload,
              { path_lower: serverEntry.path_lower },
            ];
          }
        } else {
          if (clientModifiedTime > serverModifiedTime) {
            // client entry is the latest, upload client entry
            entriesToUpload = [...entriesToUpload, matchingClientEntry];
          } else if (clientModifiedTime < serverModifiedTime) {
            // server entry is the latest, update client entry
            entriesToUpdate = [
              ...entriesToUpdate,
              { path_lower: serverEntry.path_lower },
            ];
          }
        }
      }

      dispatch(setCursor(cursor));

      /** process delete@client/download/update*/
      printToConsole({
        entriesToDeleteAtClient: entriesToDeleteAtClient.length,
        entriesToDownload: entriesToDownload.length,
        entriesToUpdate: entriesToUpdate.length,
      });

      //delete the entries at the client
      if (entriesToDeleteAtClient.length > 0) {
        await deleteJournalEntries(entriesToDeleteAtClient);
      }

      //update the entries at the client
      if (entriesToUpdate.length > 0)
        await processEntries(entriesToUpdate, UPDATE_JOURNAL_SUCCESS)(dispatch);

      //download the entries from the server
      if (entriesToDownload.length > 0) {
        if (entriesToDownload.length > 10) {
          const entriesToDownloadInBatches = chunk(entriesToDownload, 10);

          while (entriesToDownloadInBatches.length) {
            const batch = entriesToDownloadInBatches.shift();
            await processEntries(batch, ADD_JOURNAL)(dispatch);
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        } else await processEntries(entriesToDownload, ADD_JOURNAL)(dispatch);
      }

      //release memory
      serverMetadata = null;
      entriesToDeleteAtClient = [];
      entriesToDownload = [];
      entriesToUpdate = [];
    } while (hasMore);

    /** process delete@server & upload after processing current server-entries */
    printToConsole({
      entriesToDeleteAtServer: entriesToDeleteAtServer.length,
      entriesToUpload: entriesToUpload.length,
    });

    //delete the entries at the server
    if (entriesToDeleteAtServer.length > 0)
      await deleteServerEntries(entriesToDeleteAtServer);

    //get all clientEntries that having clientRev is null/undefined & add it to the entriesToUpload
    const clientEntriesToUpload = allClientEntries.filter(
      (clientEntry) => !clientEntry.clientRev
    );
    entriesToUpload = [...entriesToUpload, ...clientEntriesToUpload];

    if (entriesToUpload.length > 0)
      await uploadJournals(entriesToUpload)(dispatch);
  } catch (error) {
    console.log(`❎ERR::[dataAction][getJournals]=>${error}`);
  } finally {
    dispatch(setLoading(false));
    console.log(`🧾[dataAction][getJournals][FINALLY]`);
    startMonitoring(dispatch);
  }
};

export const getShareableLink = async (file) => {
  try {
    //upload the file to the links folder
    const { result: uploadResult } = await _dbx.filesUpload({
      path: `/links/${file?.name}`,
      contents: file?.data,
      mode: {
        ".tag": "overwrite",
      },
      autorename: false,
      mute: false,
    });

    //check if the file was uploaded successfully
    if (uploadResult.path_display !== `/links/${file?.name}`) {
      console.log(`❎ERR::[dataAction][getShareableLink]::upload failed`);
      return;
    }

    const { result: sharedLink } = await _dbx.sharingCreateSharedLink({
      path: `/links/${file?.name}`,
    });
    console.log(`[getShareableLink]::${sharedLink.url}`);

    let sharedLinkURL = sharedLink.url.replace(
      Constants.DROPBOX_WEBSITE,
      Constants.DROPBOX_URL_COMPONENT
    );

    //get url id
    var urlId = sharedLinkURL.substring(
      sharedLinkURL.indexOf(Constants.DROPBOX_PUBLIC_URL_COMPONENT) +
        Constants.DROPBOX_PUBLIC_URL_COMPONENT.length
    );

    if (file.title)
      sharedLinkURL = `${Constants.APP_PUBLISH_URL}t=${file.title}&s=${urlId}`;
    else sharedLinkURL = `${Constants.APP_PUBLISH_URL}s=${urlId}`;

    return sharedLinkURL;
  } catch (error) {
    console.log(`❎ERR::[dataAction][getShareableLink]::${error}`);
  }
};

const printToConsole = (data) => {
  const {
    entriesToDeleteAtClient,
    entriesToDownload,
    entriesToUpdate,
    entriesToDeleteAtServer,
    entriesToUpload,
  } = data;

  //check for any truthy value
  if (
    !entriesToDeleteAtClient &&
    !entriesToDownload &&
    !entriesToUpdate &&
    !entriesToDeleteAtServer &&
    !entriesToUpload
  )
    return;

  console.log(`\n\n<----------------------BEGIN-XXXX-----------------------`);
  if (entriesToDeleteAtClient)
    console.log(`❌ [entriesToDeleteAtClient]->`, entriesToDeleteAtClient);

  if (entriesToDownload)
    console.log(`⬇️ [entriesToDownload]->`, entriesToDownload);

  if (entriesToUpdate) console.log(`↘️ [entriesToUpdate]->`, entriesToUpdate);

  if (entriesToUpload) console.log(`⬆️ [entriesToUpload]->`, entriesToUpload);

  if (entriesToDeleteAtServer)
    console.log(`🟥 [entriesToDeleteAtServer]->`, entriesToDeleteAtServer);

  console.log(`------------------------END-XXXX------------------------>`);
};

const refreshServerEvents =
  (deltaEntries, clientEntries) => async (dispatch) => {
    let entriesToDelete = [],
      entriesToDownload = [],
      entriesToUpdate = [];

    for (let i = 0; i < deltaEntries?.length; i++) {
      const isLoggedIn = store.getState()?.dataStore.isLoggedIn;
      if (!isLoggedIn) {
        dispatch(logout());
        return;
      }
      const deltaEntry = deltaEntries[i];

      if (deltaEntry[".tag"] === "deleted") {
        entriesToDelete.push(deltaEntry.name.slice(0, -6));
      } else {
        let matchingClientEntry = clientEntries?.find((p) => {
          return p.guid === deltaEntry.name.split(".")[0];
        });

        if (isEmpty(matchingClientEntry)) {
          entriesToDownload.push(deltaEntry);
        } else if (matchingClientEntry.clientRev !== deltaEntry.rev) {
          entriesToUpdate.push(deltaEntry);
        }
      }
    }
    console.log("<----------------------ALT-BEGIN-XXXX-----------------------");
    console.log(`❗[to-delete]=>${entriesToDelete?.length}`);
    console.log(`📥[to-download]=>${entriesToDownload?.length}`);
    console.log(`📮[to-update]=>${entriesToUpdate?.length}`);
    console.log("<----------------------ALT-END-XXXX-------------------------");

    if (entriesToDelete.length) {
      await deleteJournalEntries(entriesToDelete);
    }
    if (entriesToDownload.length) {
      await processEntries(entriesToDownload, ADD_JOURNAL)(dispatch);
    }
    if (entriesToUpdate.length) {
      await processEntries(entriesToUpdate, UPDATE_JOURNAL_SUCCESS)(dispatch);
    }
  };

const startMonitoring = once((dispatch) => {
  console.log(`♾️[dataAction][startMonitoring]`);

  NetInfo.configure({
    //reachabilityUrl: 'https://clients3.google.com/generate_204',
    //reachabilityTest: async (response) => response.status === 204,
    reachabilityLongTimeout: 120 * 1000, // 60s
    reachabilityShortTimeout: 180 * 1000, // 5s
    //reachabilityRequestTimeout: 120 * 1000, // 15s
    reachabilityShouldRun: () => true,
    //useNativeReachability: false
  });

  // Register a listener that will be called when the network status changes
  NetInfo.addEventListener(async (state) => {
    if (state.isInternetReachable) {
      console.log(`⚡[CONNECTED][NET-STATUS]::${state.isConnected}`);
      if (!_isMonitoring) {
        _isMonitoring = true;
        initMonitoring()(dispatch);
      }
    } else if (
      state.type !== "unknown" &&
      state.isInternetReachable === false
    ) {
      _isMonitoring = false;
      console.log(
        `⚡[NOT CONNECTED][NET-STATUS]::${state.isInternetReachable}`
      );
    }
  });
});

const initMonitoring = () => async (dispatch) => {
  try {
    console.log(`📡[dataAction][initMonitoring]`);
    const dataStore = store.getState().dataStore;

    //get cursor from the store
    let { cursor } = dataStore;

    if (isEmpty(cursor)) {
      _isMonitoring = false;
      return;
    }

    do {
      //fetch the folder updates
      const { result: folderResult } = await _dbx.filesListFolderContinue({
        cursor,
      });

      //update the cursor
      cursor = folderResult.cursor;
      dispatch(setCursor(cursor));

      const { entries: delta } = folderResult;
      console.log(`[initMonitoring][delta] entries ->${delta.length}`);

      //filter for entries
      const deltaEntries = delta.filter((p) => {
        return (
          (p[".tag"] === "file" || p[".tag"] === "deleted") &
          p.name.endsWith(".entry")
        );
      });

      if (deltaEntries?.length > 0) {
        console.log(
          `🎯[initMonitoring][deltaEntries] >>>${deltaEntries?.length}`
        );

        const clientEntries = await getClientJournalEntries();
        console.log(
          `🎯[initMonitoring][clientEntries] >>>${clientEntries?.length}`
        );

        refreshServerEvents(deltaEntries, clientEntries)(dispatch);
      }

      //check for more changes
      let hasMoreChanges = folderResult.has_more;
      while (!hasMoreChanges) {
        //use the longpoll to monitor for changes
        const { result: longpollDeltaResult } =
          await _dbx.filesListFolderLongpoll({ cursor, timeout: 30 });

        hasMoreChanges = longpollDeltaResult.changes;

        //if there are no changes, wait for 30 seconds and check again
        if (!hasMoreChanges) {
          console.log(`[initMonitoring][NO CHANGES][Timeout]-> polling again`);
        }

        //check for a back off time
        const backoff = longpollDeltaResult.backoff;
        if (backoff > 0) {
          console.log(
            `[initMonitoring][BACKOFF REQUESTED] Sleeping for ${backoff} seconds...`
          );
          await new Promise((resolve) => setTimeout(resolve, backoff * 1000));
        }
      }
    } while (navigator.onLine);
  } catch (error) {
    _isMonitoring = false;
    console.error(`❎ERR::[dataAction][initMonitoring]::${error}`);
  }
};

export const toggleFavorites = (journalItem) => async (dispatch) => {
  try {
    journalItem.isFavorite = !journalItem.isFavorite;
    journalItem.entryModifiedDate = dateToTicks(new Date());
    console.log(`⭐[dataAction][toggleFavorites]=>${journalItem.isFavorite}`);

    await updateJournalEntry(journalItem);

    await dispatch(updateJournal(journalItem));
  } catch (error) {
    console.log(
      `❎ERR::[dataAction][toggleFavorites][TOGGLE FAV]:: ${JSON.stringify(
        error
      )}`
    );
  }
};

function getImageData(item) {
  try {
    if (isEmpty(item.imageData)) {
      return item.imageThumbnail.startsWith("data:image")
        ? item.imageThumbnail.split(",", 2)[1]
        : item.imageThumbnail;
    } else {
      return item.imageData.startsWith("data:image")
        ? item.imageData.split(",", 2)[1]
        : item.imageData;
    }
  } catch (error) {
    console.log(`❎ERR::[dataAction][getImageData]:: ${JSON.stringify(error)}`);
  }
}
