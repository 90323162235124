import React from "react";
import { StyleSheet, View } from "react-native";

//<!--lib-->
//import Markdown from "react-native-easy-markdown";
import Markdown from "react-native-markdown-display";
import { StyleService, useStyleSheet } from "@ui-kitten/components";

//<!-- MARKDOWNVIEW-->
const MarkdownView = (props) => {
  //destructure
  const { isRetroMode } = props;
  //console.log(`ISRETRO======>${isRetroMode}`);

  //state
  let styles;
  if (isRetroMode) styles = useStyleSheet(mdStyles2);
  else styles = useStyleSheet(mdStyles);

  return (
    //<Markdown useDefaultStyles markdownStyles={styles}>
    <Markdown style={styles}>{props.children}</Markdown>
  );
};

const mdStyles = StyleService.create({
  // heading: {
  //   borderBottomWidth: 1,
  //   borderColor: "#000000",
  // },
  // heading1: {
  //   fontSize: 32,
  //   backgroundColor: "#000000",
  //   color: "#FFFFFF",
  // },
  // heading2: {
  //   fontSize: 24,
  // },
  // heading3: {
  //   fontSize: 18,
  // },
  // heading4: {
  //   fontSize: 16,
  // },
  // heading5: {
  //   fontSize: 13,
  // },
  // heading6: {
  //   fontSize: 11,
  // },
  body: {
    color: "text-basic-color",
    fontFamily: "Lora-Regular",
    fontSize: 17,
    lineHeight: 25,
  },
});

const mdStyles2 = StyleService.create({
  body: {
    color: "text-basic-color",
    fontFamily: "Tox-Typewriter",
    fontSize: 17,
    lineHeight: 25,
  },
});

export default MarkdownView;
