import React from "react";
import { View, StyleSheet, Linking, SafeAreaView } from "react-native";

//<!--expo-->
import * as WebBrowser from "expo-web-browser";

//<!--libs-->
import {
  List,
  ListItem,
  Divider,
  Layout,
  Text,
  Icon,
  TopNavigation,
  TopNavigationAction,
} from "@ui-kitten/components";

//<!--local-->
import AppIcon from "./../components/AppIcon";
import { CloseIcon2, ChevronIcon } from "./../components/Icons";
import { getVersion } from "../utils/customUtils";
import Colors from "./../constants/Colors";
import ScreenLayout from "./../constants/Layout";

//<!--ABOUT-->
const About = ({ navigation }) => {
  const navigateBack = () => {
    navigation.goBack();
  };

  const BackAction = () => (
    <TopNavigationAction icon={CloseIcon2} onPress={navigateBack} />
  );

  const _handleLinkClick = async (url) => {
    console.log(`[ABOUT_SCREEN_CLICK]::${url}`);

    if (url.includes("twitter")) {
      try {
        return await Linking.openURL(url);
      } catch (e) {
        return await WebBrowser.openBrowserAsync(
          "https://twitter.com/" + `getglimpses`
        );
      }
    } else {
      await WebBrowser.openBrowserAsync(url);
    }
  };

  const renderItem = ({ item }) => (
    <ListItem
      title={`${item.title}`}
      description={`${item.description}`}
      accessoryLeft={(props) => (
        <Icon fill={Colors.listIcon} {...props} name={item.icon} />
      )}
      accessoryRight={ChevronIcon}
      onPress={() => {
        _handleLinkClick(item.url);
      }}
    />
  );
  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <TopNavigation
          alignment="center"
          accessoryLeft={BackAction}
          title="About glimpses"
        />
        <Divider />
        <Layout style={styles.container}>
          <View
            style={{
              height: "30.3%",
              alignItems: "center",
              justifyContent: "center",
              marginVertical: 20,
              // borderWidth: 1,
              // borderColor: "red",
            }}
          >
            <View
              style={{
                height: 72,
                width: 72,
                borderRadius: 36,
                //backgroundColor: Colors.accentColor,
                backgroundColor: "#1f2937",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 6,
                marginBottom: 3,
              }}
            >
              <AppIcon height="48" width="48" color={"#fff"} />
            </View>
            <Text
              appearance="hint"
              style={{
                textAlign: "center",
                fontWeight: "300",
                fontSize: 14,
                marginTop: 3,
              }}
            >
              {/*Version 1.0.0*/}
              {/*{`Version ${Constants.manifest?.version}`}*/}
              {`Version ${getVersion()}`}
            </Text>
          </View>
          <Divider />
          <List
            data={MENU_ITEMS}
            renderItem={renderItem}
            ItemSeparatorComponent={Divider}
          />
        </Layout>
      </SafeAreaView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    // borderWidth: 1,
    // borderColor: "green",
  },
});

const MENU_ITEMS = [
  {
    icon: "globe-outline",
    title: "Website",
    description: "getglimpses.com",
    url: `https://getglimpses.com`,
  },
  {
    icon: "globe-2-outline",
    title: "Blog",
    description: "blog.getglimpses.com",
    url: `https://blog.getglimpses.com`,
  },
  {
    icon: "question-mark-circle-outline",
    title: "Support",
    description: "Knowledge Base",
    url: `https://support.getglimpses.com/`,
  },
  {
    icon: "twitter-outline",
    title: "Follow us on Twitter",
    description: "@getglimpses",
    url: `twitter://user?screen_name=getglimpses`,
  },
  {
    icon: "at-outline",
    title: "Terms of Service",
    description: "",
    url: `https://getglimpses.com/terms-of-service.html`,
  },
  {
    icon: "shield-outline",
    title: "Privacy Policy",
    description: "",
    url: `https://getglimpses.com/privacy.html`,
  },
  // {
  //   icon: "navigation-2-outline",
  //   title: "Rate us on the Appstore",
  //   description: "",
  //   url: ``,
  // },
];

export default About;
