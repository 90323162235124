/**no pagination*/
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  SafeAreaView,
  View,
  ActivityIndicator,
  Keyboard,
  TouchableWithoutFeedback,
} from "react-native";

//<!--expo-->

//<!--libs-->
import filter from "lodash.filter";
import {
  Divider,
  Text,
  Layout,
  TopNavigation,
  TopNavigationAction,
  Input,
  Spinner,
} from "@ui-kitten/components";

//import { FlatList, RectButton } from "react-native-gesture-handler";
import { FixedSizeList } from "react-window";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { useLiveQuery } from "dexie-react-hooks";

//<!-- redux-->
import { useDispatch } from "react-redux";
import {
  getJournals as getJournalsAction,
  toggleFavorites,
  deleteJournal,
} from "../redux/actions/dataAction";
import { getJournals } from "../selectors";

//<!-- local-->
import useIsMounted from "../hooks/useIsMounted";
import { SearchIcon, CloseIcon, MenuIcon } from "../components/Icons";
import EmptyView from "../components/EmptyView";
import Fab from "../components/Fab";
import JournalItem from "../components/JournalItem";
import JournalEntry from "../models/journalEntry";
import { contains } from "../utils/commonUtils";
import Enumerations from "../constants/Enumerations";
import Colors from "../constants/Colors";
import {
  getJournalEntries,
  getJournalEntriesCount,
  getPaginatedJournalEntries,
} from "../data/db";
import ErrorBoundary from "../components/ErrorBoundary";

const extractKey = ({ guid }) => guid;

//<!--JOURNALS-->
const AllJournals = React.memo((props) => {
  //destructuring props
  const {
    navigation,
    //journals
  } = props;

  //constants
  const inputAccessoryViewID = "uniqueID";

  /**states */
  const [refreshing, setRefreshing] = useState(false);
  const [query, setQuery] = useState("");
  const [searchVisible, setSearchVisible] = useState(false);
  const [itemsToDisplay, setItemsToDisplay] = useState(10);

  /**custom hooks */
  const inputRef = useRef();
  const nav = useNavigation();
  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  if (!isMounted) {
    return null;
  }

  /**query-hooks */
  //const journals = useSelector((state) => state.dataStore.journals);
  const journals = useLiveQuery(() => getJournalEntries(), []);
  const journalCount = useLiveQuery(() => getJournalEntriesCount());

  /**hooks */
  useEffect(() => {
    try {
      console.log(`📘[Journals] Loading...`);
      dispatch(getJournalsAction());
    } catch (error) {
      console.log(`❎[ERR][Journals]::${error}`);
    }
  }, []);

  useFocusEffect(
    useCallback(() => {
      console.log(`📘[Journals] Focused...`);
      setSearchVisible(false);
      setQuery("");
    }, [])
  );

  /**<!--functions--> */
  const filteredData = useMemo(
    () => filter(journals, (journal) => contains(journal, query.toLowerCase())),
    [journals, query]
  );

  const paginatedData = useMemo(
    () => filteredData.slice(0, itemsToDisplay),
    [filteredData, itemsToDisplay]
  );

  const handleLoadMore = () => {
    console.log(`📖[Journals] Loading more...`);
    //itemsToDisplay should not go beyond the length of the filteredData
    if (itemsToDisplay >= journalCount) {
      return;
    }

    setItemsToDisplay(itemsToDisplay + 10);
  };

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    dispatch(getJournals());
    setRefreshing(false);
  }, [refreshing]);

  const navigateToDetails = (item) => {
    console.log(`📝[Journals] Navigating to details...`, item.latitude);
    navigation.navigate("Journal-Detail", { journal: item });
  };

  /**<!--event-handlers--> */
  const isItemLoaded = (index) => {
    // Check if the item at the index has already been loaded
    return Boolean(journals[index]);
  };

  const handleToggleSearch = () => {
    setSearchVisible(!searchVisible);
  };

  const handleSearch = (text) => {
    setQuery(text);
  };

  const handleClearSearch = () => {
    console.log(`Clearing search query..`);
    Keyboard.dismiss();
    handleSearch("");
  };

  const handleCreateEntry = () => {
    let journalEntry = new JournalEntry();

    navigation.navigate("Journal-Detail", {
      journal: journalEntry,
      isNewEntry: true,
    });
  };

  /**<!--components/renderers--> */
  const renderDrawerAction = () => (
    <TopNavigationAction
      icon={MenuIcon}
      onPress={props.navigation.toggleDrawer}
    />
  );

  const renderCloseIcon = (props) => (
    <TouchableWithoutFeedback onPress={handleClearSearch}>
      <CloseIcon style={{ width: 16, height: 16 }} />
    </TouchableWithoutFeedback>
  );

  const renderRightAccessory = () => (
    <TopNavigationAction
      icon={SearchIcon}
      onPress={handleToggleSearch}
      style={{ marginTop: 32 }}
    />
  );

  const _renderItem = useCallback(
    ({ item: journal }) => (
      <>
        <JournalItem
          key={journal.guid}
          item={journal}
          index={journal.guid}
          toggleFavorites={toggleFavorites}
          deleteJournal={deleteJournal}
          navigateToDetails={navigateToDetails}
        />
        <Divider />
      </>
    ),
    [paginatedData]
  );

  if (!paginatedData || !journals) {
    return (
      <>
        <Layout
          style={{
            flex: 1,
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 999,
            backgroundColor: "transparent",
            //alignItems: "center", justifyContent: "center"
          }}
        >
          <Spinner size="giant" />
        </Layout>
      </>
    );
  }

  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <TopNavigation
          appearance="default"
          title={() => (
            <Text
              category="h4"
              style={{
                //paddingTop: 30,
                //paddingLeft: 6,
                fontWeight: "700",
                fontSize: 32,
              }}
            >{`Timeline`}</Text>
          )}
          accessoryLeft={renderDrawerAction}
          accessoryRight={renderRightAccessory}
        />
        <ErrorBoundary>

        <Divider style={{ marginBottom: 3 }} />

        <Layout style={{ flex: 1 }}>
          {searchVisible && (
            <Layout>
              <Input
                autoFocus={true}
                ref={inputRef}
                value={query}
                placeholder="Search"
                onChangeText={handleSearch}
                size="small"
                //accessoryRight={renderCloseIcon}
                style={{
                  marginVertical: 12,
                  marginHorizontal: 16,
                  borderRadius: 16,
                }}
              />
            </Layout>
          )}

          {refreshing ? (
            <Layout
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ActivityIndicator size="large" color={Colors.listIcon} />
            </Layout>
          ) : (
            <Layout style={{ flex: 1 }}>
              {paginatedData.length > 0 ? (
                <ReactVirtualizedAutoSizer>
                  {({ height, width }) => (
                    <InfiniteLoader
                      isItemLoaded={isItemLoaded}
                      //loadMoreItems={loadMoreItems}
                      itemCount={paginatedData.length}
                    >
                      {({ onItemsRendered, ref }) => (
                        <FixedSizeList
                          ref={inputRef}
                          useIsScrolling={true}
                          onItemsRendered={({
                            overscanStartIndex,
                            overscanStopIndex,
                            visibleStartIndex,
                            visibleStopIndex,
                          }) => {
                            if (visibleStopIndex >= paginatedData.length - 5) {
                              handleLoadMore();
                            }
                          }}
                          itemCount={paginatedData.length}
                          itemSize={76}
                          height={height}
                          width={width}
                        >
                          {
                            ({ index, style, isScrolling }) => (
                              <View style={style}>
                                {_renderItem({ item: paginatedData[index] })}
                              </View>
                            )
                            //isScrolling ? (
                            //  <>
                            //    <Skeleton style={style} />
                            //  </>
                            //) : (
                            //  <View style={style}>
                            //    {_renderItem({ item: journals[index] })}
                            //  </View>
                            //)
                          }
                        </FixedSizeList>
                      )}
                    </InfiniteLoader>
                  )}
                </ReactVirtualizedAutoSizer>
              ) : (
                <>
                  {paginatedData?.length === 0 && (
                    <EmptyView
                      information={`No journals are found,\n please press '➕' to create new`}
                      icon={Enumerations.Journals}
                    />
                  )}
                </>
              )}
            </Layout>
          )}
        </Layout>

        <Fab createNewEntry={handleCreateEntry} />
        </ErrorBoundary>
      </SafeAreaView>
    </Layout>
  );
});

export default AllJournals;
