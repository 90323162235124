import React, { useState, useEffect } from "react";

/**ui */
import {
  Text,
  Divider,
  Layout,
  TopNavigation,
  TopNavigationAction,
  OverflowMenu,
  MenuItem,
} from "@ui-kitten/components";

/**libs */
import { useLiveQuery } from "dexie-react-hooks";
import { SafeAreaView } from "react-native-safe-area-context";

/**redux */
import { useDispatch, useSelector } from "react-redux";

/**local */
import { getDeletedJournalEntries, purgeJournalEntries } from "../data/db";
import JournalList from "../components/JournalList";
import { EmptyTrash, MenuIcon, OverflowMenuIcon } from "../components/Icons";
import EmptyView from "../components/EmptyView";
import Enumerations from "../constants/Enumerations";
import useIsMounted from "../hooks/useIsMounted";

export const Trash = (props) => {
  /**destructure */
  const { navigation, route } = props;
  console.log("🗑️[Trash] Loading...");

  /**state */
  const [menuVisible, setMenuVisible] = useState(false);

  /**custom-hooks */
  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  if (!isMounted) return null;

  /**selectors */
  const isRetroMode = useSelector((state) => state.dataStore.isRetroMode);
  const trashEntries = useLiveQuery(() => getDeletedJournalEntries(), []);

  /**hooks */
  useEffect(() => {
    return () => {
      console.log("🗑️[Trash] Unmounting...");
    };
  }, []);

  /**functions */
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handlePurgeJournals = async () => {
    setMenuVisible(false);
    if (trashEntries?.length === 0) return;

    await purgeJournalEntries();
  };

  /**components/renderers */
  const BackAction = () => (
    <TopNavigationAction icon={MenuIcon} onPress={navigation.toggleDrawer} />
  );

  const renderMenuAction = () => (
    <TopNavigationAction icon={OverflowMenuIcon} onPress={toggleMenu} />
  );

  const renderRightActions = () => {
    return (
      <>
        <OverflowMenu
          anchor={renderMenuAction}
          visible={menuVisible}
          onBackdropPress={toggleMenu}
        >
          {/* Purge All */}
          <MenuItem
            accessoryLeft={EmptyTrash}
            title="Empty Bin"
            onPress={handlePurgeJournals}
          />
        </OverflowMenu>
      </>
    );
  };

  /**event-handlers */
  const navigateToDetails = (item) => {
    navigation.navigate("TrashJournalDetail", {
      journal: item,
      isRetroMode: isRetroMode,
    });
  };

  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <TopNavigation
          appearance="default"
          accessoryLeft={BackAction}
          accessoryRight={renderRightActions}
          title={() => (
            <Text
              category="h4"
              style={{
                fontWeight: "700",
                fontSize: 32,
              }}
            >{`Bin`}</Text>
          )}
        />
        <Divider />

        {trashEntries?.length > 0 ? (
          <JournalList
            journals={trashEntries}
            navigateToDetails={navigateToDetails}
          />
        ) : (
          <EmptyView information={`Bin is empty!`} icon={Enumerations.Trash} />
        )}
      </SafeAreaView>
    </Layout>
  );
};

export default React.memo(Trash);
