/**libs */
import {
  SET_REFRESH_TOKEN,
  SET_ACCOUNT,
  SET_THEME,
  TOGGLE_RETRO_MODE,
  SET_CURSOR,
  GET_METADATA_BEGIN,
  GET_METADATA_SUCCESS,
  GET_METADATA_ERROR,
  UPDATE_JOURNAL_ERROR,
  DELETE_JOURNAL_ERROR,
  SET_PIN,
  SET_LOADING,
  LOGOUT,
} from "./types";
const schema = require("../../utils/schema");

//<!------------------------------------DISPATCHERS----------------------------------

export const setRefreshToken = (refreshToken) => {
  return {
    type: SET_REFRESH_TOKEN,
    payload: refreshToken,
  };
};

export const setAccount = (account) => {
  return {
    type: SET_ACCOUNT,
    payload: account,
  };
};

//metadata
export const getMetadataBegin = () => {
  return {
    type: GET_METADATA_BEGIN,
  };
};

export const getMetadataSuccess = () => {
  return {
    type: GET_METADATA_SUCCESS,
  };
};

export const getMetadataError = (error) => {
  return {
    type: GET_METADATA_ERROR,
    payload: error,
  };
};

export const setCursor = (cursor) => {
  return {
    type: SET_CURSOR,
    payload: cursor,
  };
};

export const updateJournalError = (error) => {
  return {
    type: UPDATE_JOURNAL_ERROR,
    payload: error,
  };
};

export const deleteJournalError = (error) => {
  return {
    type: DELETE_JOURNAL_ERROR,
    payload: error,
  };
};

export const setPin = (hash) => {
  return {
    type: SET_PIN,
    payload: hash,
  };
};

export const toggleRetroMode = () => {
  console.log(`[onToggleRetroMode2]`);
  return {
    type: TOGGLE_RETRO_MODE,
  };
};

export const toggleSelectedTheme = () => {
  return {
    type: SET_THEME,
  };
};

export const setLoading = (val) => {
  return {
    type: SET_LOADING,
    payload: val,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};
