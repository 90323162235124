import React, { useEffect, useCallback } from "react";
import { View, SafeAreaView, Dimensions } from "react-native";

//<!--libs-->
import { useLiveQuery } from "dexie-react-hooks";
import { Layout, Divider } from "@ui-kitten/components";
import { FixedSizeGrid } from "react-window";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";

//<!-- redux-->

//<!--local-->
import PhotoItem from "../components/PhotoItem";
import EmptyView from "./../components/EmptyView";
import ScreenHeader from "../components/ScreenHeader";
import { ticksToDate } from "./../utils/dateUtils";
import Enumerations from "./../constants/Enumerations";
import { getPhotoEntries } from "../data/db";
import ErrorBoundary from "../components/ErrorBoundary";

const extractKey = ({ guid }) => guid;
const screenWidth = Dimensions.get("window").width;

//<!-- PHOTOS-->
const Photos = ({ navigation }) => {
  const numColumns = screenWidth > 500 ? 3 : 2;

  /**refs */

  /**custom-hooks */
  const photos = useLiveQuery(async () => await getPhotoEntries(), []);
  console.log("🎨[Photos] photos", photos?.length);

  /**hooks */
  useEffect(() => {
    console.log("🎨[Photos] Loading...");
  }, []);

  /**components/renderers */
  const renderPostItem = useCallback(
    ({ columnIndex, rowIndex, style }) => {
      const item = photos[rowIndex * numColumns + columnIndex];

      if (!item) return null;

      const ticks = Number(item.entryCreatedDate);
      const dt = ticksToDate(ticks);
      return item.imageThumbnail ? (
        <View style={[style, {}]}>
          <PhotoItem item={item} dt={dt} navigation={navigation} />
        </View>
      ) : null;
    },
    [photos, numColumns]
  );

  /**functions */
  const getItemLayout = (data, index) => {
    return {
      length: 250,
      offset: 250 * index,
      index,
    };
  };

  const isItemLoaded = (index) => {
    // Check if the item at the index has already been loaded
    return Boolean(photos[index]);
  };

  // If default values are returned, queries are still loading:
  if (!photos) return null;

  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <ErrorBoundary>
        <ScreenHeader title="Photos" />
        <Divider />
        {photos?.length > 0 ? (
          <View
            style={{
              height: "100vh",
              width: "100vw",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <ReactVirtualizedAutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={photos?.length}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeGrid
                      keyExtractor={extractKey}
                      onItemsRendered={onItemsRendered}
                      useIsScrolling={true}
                      ref={ref}
                      //ref={(list) => {
                      //  // @ts-ignore next-line
                      //  setRef?.(list); // Gives the list ref to the parent InfiniteLoader
                      //  setListRef(list);
                      //}}
                      columnCount={numColumns}
                      rowCount={Math.ceil(photos?.length / numColumns)}
                      columnWidth={width / numColumns}
                      rowHeight={numColumns === 2 ? 200 : 250}
                      height={height}
                      width={width + 18}
                    >
                      {renderPostItem}
                    </FixedSizeGrid>
                  )}
                </InfiniteLoader>
              )}
            </ReactVirtualizedAutoSizer>
          </View>
        ) : (
          <EmptyView
            information={`No photos to display!`}
            icon={Enumerations.Photos}
          />
        )}
        </ErrorBoundary>
      </SafeAreaView>
    </Layout>
  );
};

export default Photos;
