import React from "react";
import { Image } from "expo-image";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  //Image
} from "react-native";
import { areEqual } from "react-window";
import { Text } from "@ui-kitten/components";
import Colors from "./../constants/Colors";
import { Constants } from "./../constants";

const blurhash =
  "|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[";

const PhotoItem = ({ item, dt, navigation }) => {
  return (
    <View style={styles.container} key={item.guid}>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("Journal-Detail", { journal: item });
        }}
      >
        <Image
          placeholder={blurhash}
          source={{
            uri: item.imageThumbnail?.startsWith(Constants.ImagePrefix)
              ? `${item.imageThumbnail}`
              : `${Constants.ImagePrefix}${item.imageThumbnail}`,
          }}
          style={styles.image}
          loading="lazy"
          decoding="async"
        />
        {item.isFavorite ? (
          <Text category="h1" style={[styles.text, styles.favText]}>
            {dt.localDate}
          </Text>
        ) : (
          <Text category="h1" style={styles.text}>
            {dt.localDate}
          </Text>
        )}
        <Text
          style={[styles.text, { marginTop: 45, fontWeight: "300" }]}
        >{`${dt.utcMonth}, ${dt.utcYear}`}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative",
  },
  image: {
    //resizeMode: "cover",
    contentFit: "cover",
    justifyContent: "center",
    width: "100%",
    height: 250,
  },
  text: {
    position: "absolute",
    top: "50%",
    right: "7%",
    zIndex: 1,
    textAlign: "right",
    color: Colors.appWhite,
  },
  favText: {
    top: "50%",
    right: "7%",
    zIndex: 1,
    textAlign: "right",
    color: "gold",
  },
});

export default React.memo(PhotoItem, areEqual);
