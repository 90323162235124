import React from "react";
import { View, SafeAreaView, ScrollView } from "react-native";

/*<!--libs-->*/
import {
  Layout,
  Text,
  Divider,
  TopNavigation,
  TopNavigationAction,
  Avatar,
} from "@ui-kitten/components";

/*<!--others-->*/

/*<!--redux-->*/
import { connect } from "react-redux";

//<!--local-->
import Profile from "../components/Profile";
import { CloseIcon2 } from "./../components/Icons";
import Colors from "./../constants/Colors";
import ErrorBoundary from "../components/ErrorBoundary";

//<!--ACCOUNT-->
const Account = ({ navigation, account }) => {
  const navigateBack = () => {
    navigation.goBack();
  };

  const BackAction = () => (
    <TopNavigationAction icon={CloseIcon2} onPress={navigateBack} />
  );

  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <TopNavigation
          alignment="center"
          accessoryLeft={BackAction}
          title="Profile"
        />
        <ErrorBoundary>
        <Divider />
        <Layout style={{ flex: 1 }}>
          <View
            style={{
              height: "30.3%",
              alignItems: "center",
              justifyContent: "center",
              //borderWidth: 1,
              //borderColor: "red",
            }}
          >
            {/* image */}
            <View
              style={{
                height: 75,
                width: 75,
                borderRadius: 37.5,
                backgroundColor: Colors.profileBackground,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {account && (
                <>
                  {/* style={{ fontWeight: "700", fontSize: 26 }} */}
                  {account.profile_photo_url ? (
                    <Avatar
                      source={{ uri: account.profile_photo_url }}
                      style={{ width: 74, height: 74 }}
                    />
                  ) : (
                    <Text category="h3">{account?.name?.abbreviated_name}</Text>
                  )}
                </>
              )}
            </View>
          </View>
          <Divider />

          {account && (
            <ScrollView>
              <Profile Hint="First Name" Value={account.name?.given_name} />
              <Profile Hint="Last Name" Value={account.name?.surname} />
              <Profile Hint="Email" Value={account?.email} />
            </ScrollView>
          )}
        </Layout>
        </ErrorBoundary>
      </SafeAreaView>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  account: state.dataStore.account,
});

export default connect(mapStateToProps, {})(Account);
