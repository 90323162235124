//import Jimp from "jimp";
import bcrypt from "bcryptjs";
import Showdown from "showdown";
import packageJson from "../package.json";

//export const generateThumbnail = async (base64Image) => {
//  try {
//    const imageData = Buffer.from(base64Image, "base64");
//    const image = await Jimp.read(imageData);
//    image.rotate(0);
//    image.cover(250, 250);
//    //Jimp.RESIZE_BEZIER);
//    const thumbnail = await image.getBase64Async(Jimp.MIME_JPEG, {
//      quality: 50,
//    });
//    return thumbnail;
//  } catch (error) {
//    console.log("❌Error in generateThumbnail: ", error);
//  }
//};

export const generateHash = (password) => {
  try {
    const salt = bcrypt.genSaltSync(10);
    const hash = bcrypt.hashSync(password, salt);
    return hash;
  } catch (error) {
    console.log("❌Error in generateHash: ", error);
  }
};

export const compareHash = (password, hash) => {
  try {
    const isMatch = bcrypt.compareSync(password, hash);
    return isMatch;
  } catch (error) {
    console.log("❌Error in compareHash: ", error);
  }
};

export const getHtml = (markdownText, imageData) => {
  const converter = new Showdown.Converter();
  const html = converter.makeHtml(markdownText);
  return html;
};

export const sanitize = (str) => {
  // Replace any special characters with an underscore
  str = str.replace(/[^a-zA-Z0-9 ]/g, "_");
  // Replace any spaces with an underscore
  str = str.replace(/\s/g, "_");
  // Trim any leading or trailing underscores
  str = str.replace(/^_|_$/g, "");
  return str;
};

/**
 * {
  "place_id": 337626560,
  "licence": "Data © OpenStreetMap contributors, ODbL 1.0. https://osm.org/copyright",
  "osm_type": "way",
  "osm_id": 1076837057,
  "lat": "11.269903090972822",
  "lon": "75.82288586680649",
  "place_rank": 26,
  "category": "highway",
  "type": "residential",
  "importance": 0.09999999999999998,
  "addresstype": "road",
  "name": null,
  "display_name": "Thondayad, Kozhikode, Kozhikode district, Kerala, 673017, India",
  "address": {
    "village": "Thondayad",
    "county": "Kozhikode",
    "state_district": "Kozhikode district",
    "state": "Kerala",
    "ISO3166-2-lvl4": "IN-KL",
    "postcode": "673017",
    "country": "India",
    "country_code": "in"
  },
  "boundingbox": [
    "11.2689737",
    "11.270293",
    "75.8228293",
    "75.8230207"
  ]
}
 */
export async function getLocationInfo(latitude, longitude) {
  try {
    console.log("📌");
    //return "Sandyford, County Dublin, IE-D";

    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    );
    const data = await response.json();
    if (data.address) {
      let villageOrCity =
        data.address.village ||
        data.address.town ||
        data.address.city ||
        data.address.city_district ||
        "";
      let county =
        data.address.county ||
        data.address.state_district ||
        data.address.state ||
        data.address.region ||
        "";
      let iso =
        data.address["ISO3166-2-lvl4"] ||
        data.address["ISO3166-2-lvl6"] ||
        data.address["ISO3166-2-lvl5"] ||
        data.address["ISO3166-2-lvl3"] ||
        data.address["ISO3166-2-lvl2"] ||
        data.address["ISO3166-2-lvl1"] ||
        data.address.country_code;

      //return `${villageOrCity}, ${county}, ${iso}`;
      let locationString = "";
      locationString += villageOrCity ? villageOrCity : "";
      locationString += county ? ", " + county : "";
      locationString += iso ? ", " + iso : "";
      return locationString;
    }
    throw new Error("Reverse geocoding failed");
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const getVersion = () => {
  return packageJson.version;
};
