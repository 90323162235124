export const MenuItems = [
  {
    icon: "person-outline",
    title: "Account",
    description: "See information about  your account.",
  },
  {
    icon: "shield-outline",
    title: "Secure",
    description: "Protect your journal",
  },
  { icon: "at-outline", title: "Contact", description: "Contact us" },
  { icon: "color-palette-outline", title: "About", description: "About us" },
  {
    icon: "flash-outline",
    title: "Theme",
    description: "Toggle theme",
  },
  {
    icon: "text-outline",
    title: "Retro",
    description: "Enable retro mode",
  },
  {
    icon: "log-out-outline",
    title: "Logout",
    description: "Logout & Clear Data",
  },
];

export const Constants = Object.freeze({
  DROPBOX_WEBSITE: "www.dropbox.com",
  DROPBOX_URL_COMPONENT: "dl.dropboxusercontent.com",
  DROPBOX_PUBLIC_URL_COMPONENT: "https://dl.dropboxusercontent.com/s/",
  APP_PUBLISH_URL: "https://getglimpses.com/articles.html?",
  ImagePrefix: "data:image/jpeg;base64,",
});
