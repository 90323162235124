import React, { Component } from "react";
import { Layout, Text } from "@ui-kitten/components";
import PageBrokenIcon from "./icons/Error"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  // componentDidCatch(error, errorInfo) {
  //   // implement SENTRY - page specific errors
  // }
  
  render() {
    return this.state.hasError ? (
      <Layout style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
          <PageBrokenIcon/>
          <Text category="h2">
            Something went&nbsp;
            <Text category="h2" status="warning">wrong.</Text>
          </Text>
          <Text category="s1">
            Please contact support or Reload.
          </Text>
      </Layout>
    ) : this.props.children;
  }
}

export default ErrorBoundary;
