import React from "react";
import { StyleSheet } from "react-native";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { Icon } from "@ui-kitten/components";
import Colors from "./../constants/Colors";

export const BackIcon = (props) => (
  <Icon
    {...props}
    name="arrow-back"
    fill={Colors.listIcon}
    style={{ width: 26, height: 26 }}
  />
);

export const EditIcon = (props) => (
  <Icon {...props} name="edit" fill={Colors.listIcon} />
);

export const FavIcon = (props) => (
  <Icon {...props} name="star" fill={Colors.favorite} />
);

export const UnFavIcon = (props) => (
  <Icon {...props} name="star-outline" fill={Colors.listIcon} />
);

export const DoneIcon = (props) => (
  <Icon {...props} name="done-all-outline" fill={Colors.listIcon} />
);

export const OverflowMenuIcon = (props) => (
  <Icon {...props} name="more-vertical" fill={Colors.listIcon} />
);

export const CameraIcon = (props) => (
  <Icon name="camera-outline" fill={Colors.listIcon} {...props} />
);

export const ClockIcon = (props) => (
  <Icon {...props} name="clock-outline" fill={Colors.listIcon} />
);

export const ShareIcon = (props) => (
  <Icon {...props} name="share-outline" fill={Colors.listIcon} />
);

export const ChevronIcon = (props) => (
  <Icon {...props} fill={Colors.hintColor} name="chevron-right-outline" />
);

export const AddIcon = (props) => (
  <Ionicons {...props} size={28} name="md-add" color="#F7FAFC" />
);

export const GlassIcon = (props) => (
  <Ionicons
    name="glasses-outline"
    size={24}
    color={Colors.listIcon}
    {...props}
  />
);

export const EmptyGlassIcon = (props) => (
  <Ionicons name="glasses-outline" size={28} color={"#22543D"} {...props} />
);

export const SearchIcon = (props) => (
  <Icon {...props} fill={Colors.listIcon} name="search-outline" />
);

export const CloseIcon = (props) => (
  <Icon fill={Colors.listIcon} name="close-circle" {...props} />
);

export const CloseIcon2 = (props) => (
  <Ionicons name="ios-close" size={32} color={Colors.listIcon} />
);

export const TrashIcon = (props) => (
  <Icon name="trash-2-outline" {...props} fill={Colors.listIcon} />
);

export const AltTrashIcon = (props) => (
  <Icon
    name="trash"
    {...props}
    fill={Colors.listIcon}
    style={{ width: 16, height: 16 }}
  />
);

export const EmptyJournalIcon = (props) => (
  <Icon name="book-outline" fill="#22543D" style={styles.icon} {...props} />
);

export const EmptyCameraIcon = (props) => (
  <Icon name="camera-outline" fill="#22543D" style={styles.icon} {...props} />
);

export const EmptyFavIcon = (props) => (
  <Icon name="star-outline" fill="#22543D" style={styles.icon} {...props} />
);

export const EmptyTrashIcon = (props) => (
  <Icon name="trash-2-outline" fill="#22543D" style={styles.icon} {...props} />
);

export const AltEmptyTrashIcon = (props) => (
  <Icon name="trash-2" fill={Colors.listIcon} style={styles.icon} {...props} />
);

//tab icons
export const JournalIcon = (props) => (
  <Icon {...props} name="book-outline" fill={Colors.listIcon} />
);

export const PhotoIcon = (props) => (
  <Icon {...props} name="color-palette-outline" fill={Colors.listIcon} />
);

export const FavoritesIcon = (props) => (
  <Icon {...props} name="star-outline" fill={Colors.listIcon} />
);

export const CalendarIcon = (props) => (
  <Icon {...props} name="calendar-outline" fill={Colors.listIcon} />
);

export const AltBookmarkIcon = (props) => (
  <Icon {...props} name="bookmark" fill={Colors.listIcon} />
);

export const AltBookIcon = (props) => (
  <Icon {...props} name="book" fill={Colors.listIcon} />
);

export const AltUndoIcon = (props) => (
  <Icon
    name="undo"
    {...props}
    fill={Colors.listIcon}
    style={{ width: 16, height: 16 }}
  />
);

export const ShieldIcon = (props) => (
  <Icon
    name="shield-outline"
    fill={Colors.listIcon}
    style={styles.icon}
    {...props}
  />
);

export const LockIcon = (props) => (
  <Icon
    name="lock-outline"
    fill={Colors.listIcon}
    style={styles.icon}
    {...props}
  />
);

export const PdfIcon = (props) => (
  <Icon
    name="paper-plane-outline"
    fill={Colors.listIcon}
    style={styles.icon}
    {...props}
  />
);

export const LocationIcon = (props) => {
  const { style } = props;
  const iconStyle = StyleSheet.flatten([styles.icon, style]);
  return <Icon name="pin-outline" fill={Colors.listIcon} style={iconStyle} />;
};

//export const TagIcon = (props) => {
//  const { style } = props;
//  const iconStyle = StyleSheet.flatten([styles.icon, style]);
//  return (
//    <Icon name="bookmark-outline " fill={Colors.listIcon} style={iconStyle} />
//  );
//};
export const TagIcon = (props) => (
  <Icon name="bookmark-outline" fill={Colors.listIcon} {...props} />
);

export const HashIcon = (props) => (
  <Icon name="hash-outline" fill={Colors.listIcon} {...props} />
);

export const AltMenuIcon = (props) => (
  <Icon name="menu-outline" fill={Colors.listIcon} {...props} />
);

export const InsightsIcon = (props) => (
  <Icon name="bar-chart-2-outline" fill={Colors.listIcon} {...props} />
);

export const LinkIcon = (props) => (
  <Icon name="link-outline" fill={Colors.listIcon} {...props} />
);

export const MenuIcon = (props) => (
  <MaterialIcons name="sort" size={25} color={Colors.listIcon} />
);

export const EmptyTrash = (props) => (
  <Ionicons {...props} name="ios-trash" size={24} color={Colors.listIcon} />
);

export const BotIcon = (props) => (
  <Icon
    name="flash"
    color={Colors.listIcon}
    fill={Colors.listIcon}
    {...props}
  />
);

const styles = StyleSheet.create({
  icon: {
    height: 24,
    width: 24,
  },
});
