import React, { useState, useEffect, useRef } from "react";
import {
  SafeAreaView,
  View,
  ScrollView,
  KeyboardAvoidingView,
  Image,
  TextInput,
  Platform,
  ActivityIndicator,
  Share,
  InputAccessoryView,
  Keyboard,
  TouchableHighlight,
  Linking,
} from "react-native";

import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Audio } from "expo-av";
import Constants from "expo-constants";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { StyleSheet } from "react-native-web";

//<!-- libs-->
import {
  Divider,
  Layout,
  Text,
  TopNavigation,
  TopNavigationAction,
  OverflowMenu,
  MenuItem,
  StyleService,
  useStyleSheet,
  Calendar,
  Button,
} from "@ui-kitten/components";
import _, { isEmpty } from "lodash";
import { manipulateAsync, SaveFormat } from "expo-image-manipulator";
import html2pdf from "html2pdf.js";

// <!--redux-->
import {
  updateJournal,
  toggleFavorites,
  deleteJournal,
  getShareableLink,
} from "./../redux/actions/dataAction";
import { setLoading } from "./../redux/actions/dispatchers";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";

//<!--local-->
import {
  BackIcon,
  EditIcon,
  FavIcon,
  UnFavIcon,
  DoneIcon,
  OverflowMenuIcon,
  CameraIcon,
  ClockIcon,
  ShareIcon,
  TrashIcon,
  PdfIcon,
  BotIcon,
  LinkIcon,
} from "./../components/Icons";
import MarkdownView from "./../components/MarkdownView";
import DateContainer from "../components/DateContainer";
import LocationContainer from "../components/LocationContainer";
import MapView from "../components/MapView";
import useIsMounted from "../hooks/useIsMounted";
import { getHtml, getLocationInfo, sanitize } from "../utils/customUtils";
import { openImageLibrary } from "./../utils/imageUtils";
import { ticksToDate, dateToTicks } from "./../utils/dateUtils";
import { Constants as localConstant } from "./../constants";
import Colors from "./../constants/Colors";
import { pdfStyle } from "../constants/pdfStyle";
import ErrorBoundary from "../components/ErrorBoundary";

//import KeyboardAvoidingView from "../components/KeyboardAvoidingView";
//import WorkerBuilder from "./../worker/worker-builder";
//import PdfWorker from "./../worker/pdf.worker";

const CalendarModal = ({ visible, onSelectDate, onCancel }) => {
  //event handler of onSelect
  const onSelect = (date) => {
    console.log(`📅[CalendarModal] onSelect: ${date}`);
    onSelectDate(date);
  };

  return (
    visible && (
      <Layout style={styles.modalContainer} level="1">
        <Calendar
          onSelect={onSelect}
          style={{
            boxShadow: "0 5px 15px rgba(0,0,0,0.18)",
          }}
        />
        <Button onPress={onCancel} style={{ marginTop: 7 }}>
          Cancel
        </Button>
      </Layout>
    )
  );
};

//<!--JOURNAL-DETAIL-->
const JournalDetail = (props) => {
  //de-structure from props
  const { navigation, route, updateJournal, toggleFavorites, isRetroMode } =
    props;

  //de-structure from route-params
  let { journal, isNewEntry = false } = route.params;

  /**constants */
  const inputAccessoryViewID = "uniqueID";

  /**states */
  const [activeJournal, setActiveJournal] = useState(null);
  const [date, setDate] = useState(null);
  const [content, setContent] = useState();
  const [favoriteStatus, setFavoriteStatus] = useState(false);
  const [hasJournalImage, setHasJournalImage] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [position, setPosition] = useState(null);
  const [locationInfo, setLocationInfo] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  //const [selection, setSelection] = useState({
  //  start: 0,
  //  end: 0,
  //});

  /**refs */
  const textInputRef = useRef(null);

  //<--custom hooks-->
  const styles = useStyleSheet(mdStyles);
  const insets = useSafeAreaInsets();
  const { showActionSheetWithOptions } = useActionSheet();
  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  if (!isMounted) return null;

  /**hooks */
  useEffect(() => {
    console.log(`📖[JournalDetail] Loading...`);

    if (isNewEntry) {
      toggleEditMode();
      getPosition();
    } else {
      //todo
    }

    //set active journal
    let journalObj = {};
    Object.assign(journalObj, journal);
    setActiveJournal(journalObj);

    //set content
    let tempContent = "";
    if (journal.title) {
      tempContent = `${journal.title}\n${journal.richContent}`;
    } else {
      tempContent = `${journal.richContent}`;
    }

    //append tagContent
    tempContent = tempContent || "";
    const tagContent = journal.tagsList
      ?.map((tag) => `#${tag?.name}`)
      .join(" ");

    if (tempContent.endsWith("\n\n")) {
      tempContent += `⌘: ${tagContent}`;
    } else if (tempContent.endsWith("\n")) {
      tempContent += `\n⌘: ${tagContent}`;
    } else {
      tempContent += `\n\n⌘: ${tagContent}`;
    }

    setContent(tempContent);

    //set date
    const ticks = Number(journal.entryCreatedDate);
    const dt = ticksToDate(ticks);
    setDate(dt);

    //set favorite status
    if (activeJournal) setFavoriteStatus(activeJournal?.isFavorite);
    else setFavoriteStatus(journal?.isFavorite);

    return () => {
      console.log("JournalDetail: Unloading...");
      setActiveJournal(null);
    };
  }, []);

  //<!--functions-->
  const getImageFromLibrary = async () => {
    if (activeJournal.imageThumbnail) {
      const options = ["Remove Photo", "Cancel"];
      const destructiveButtonIndex = 0;
      const cancelButtonIndex = 1;

      showActionSheetWithOptions(
        {
          title: "",
          options,
          cancelButtonIndex,
          destructiveButtonIndex,
        },
        (buttonIndex) => {
          // Do something here depending on the button index selected
          if (buttonIndex == 0) {
            console.log("[JournalDetail] Removing selected image..");
            activeJournal.imageData = undefined;
            activeJournal.imageThumbnail = undefined;
            toggleSetImage();

            if (menuVisible) toggleMenu();
          }
        }
      );
    } else {
      console.log(`[JournalDetail] Browsing photo-lib`);
      const result = await openImageLibrary();

      //toggle menu if visible
      if (menuVisible) toggleMenu();

      if (result) {
        let imageData;

        //if platform is web
        if (Constants.platform.web) {
          const reducedImage = await manipulateAsync(
            result.uri,
            //[{ resize: { width: 0.135 * result?.width } }],
            //[{ resize: { aspectRatio: 1.5, maxAspectRatio: 2 } }],
            [{ resize: { width: 500, maxWidth: 800 } }],
            {
              compress: 0.7,
              format: SaveFormat.JPEG,
              base64: true,
            }
          );
          //imageData = reducedImage?.uri.split(",", 2)[1];
          imageData = reducedImage?.uri;
        } else imageData = result.base64;

        activeJournal.imageData = imageData;

        const reducedImage = await manipulateAsync(
          `${activeJournal.imageData}`, // pass in the base64 image data here
          //[{ resize: { width: 500 } }],
          [{ resize: { width: 500, maxWidth: 800 } }],
          {
            compress: 0.7,
            format: SaveFormat.JPEG,
            base64: true,
          }
        );
        activeJournal.imageThumbnail = reducedImage?.base64;
        toggleSetImage();
      }
    }
  };

  const isJournalDirty = () => {
    const textContent = sanitizeTextForTags(content)?.trim();

    if (textContent.includes("\n")) {
      const [title, ...richContent] = textContent.split("\n");
      activeJournal.title = title.trim();
      activeJournal.richContent = richContent.join("\n").trim();
    } else {
      activeJournal.title = "";
      activeJournal.richContent = textContent;
    }

    //console.log(`📖[journalPassed]::${JSON.stringify(journal)}`);
    //console.log(`📖[activeJournal]::${JSON.stringify(activeJournal)}`);

    return JSON.stringify(journal) !== JSON.stringify(activeJournal);
  };

  const toggleSetImage = () => {
    setHasJournalImage(!hasJournalImage);
  };

  const getPosition = async () => {
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setPosition(position);
          console.log(`📌[JournalDetail]::${JSON.stringify(position)}`);
        },
        (error) => {
          //setErrorMsg(error);
          console.log(`📌[JournalDetail]::${JSON.stringify(error)}`);
        }
      );
    } catch (error) {
      console.log(`📌[ERR][JournalDetail]::${JSON.stringify(error)}`);
    }
  };

  const getTagsFromContent = (text) => {
    let fullText = text ? text : content;
    let tags = fullText?.match(/#(\w+)/g);

    //remove character '#' if any
    tags = tags?.map((tag) => tag.replace("#", ""));
    tags = [...new Set(tags)];
    return tags;
  };

  const sanitizeTextForTags = (text) => {
    //const result = text?.replace(/\n\n⌘:.*$/g, "");
    const result = text?.replace(/⌘:.*$/g, "");
    return result;
  };

  const getContentSplit = (text) => {
    let fullText = text ? text : content;
    fullText = sanitizeTextForTags(fullText).trim();

    let title, description;
    if (fullText.includes("\n")) {
      [title, ...description] = fullText.split("\n");
      title = title.trim();
      description = description.join("\n").trim();
    } else {
      title = "";
      description = fullText;
    }

    return { title, description };
  };

  function getDateTime(journal) {
    let date = ticksToDate(Number(journal.entryCreatedDate));
    let day = new Date(date.date).toLocaleDateString("default", {
      weekday: "short",
    });
    let month = date.utcMonth.toString().slice(0, 3);
    let time = new Date(date.date).toLocaleTimeString("default", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return { month, day, time };
  }

  function buildHtmlString(journal) {
    let { month, day, time } = getDateTime(journal);

    const headTemplate = `<head>${pdfStyle}</head>`;
    const bodyTemplate = `
          <body>        
            <div class="entry">
              <div class="date-container">
                <div class="date">${date.localDate}</div>
                <div class="month-year-container">
                  <div class="month-year">${month} ${date.localYear}</div>
                  <div class="day-time">${day}, ${time}</div>
                </div>
              </div>
            <hr>
            ${
              journal.imageThumbnail
                ? `<div style="display: grid; place-items: center;"><img style="margin: auto;" src="${
                    journal.imageThumbnail?.startsWith(
                      localConstant.ImagePrefix
                    )
                      ? `${journal.imageThumbnail}`
                      : `${localConstant.ImagePrefix}${journal.imageThumbnail}`
                  }" /></div>`
                : ""
            }        
              ${getHtml(`# ${journal.title} \n ${journal.richContent}`)}
              <div class="spacer-6"></div>
            <hr>
            </div>
            <div style=text-align:center><a href=http://getglimpses.com target=_blank title=get your glimpses>Created &nbsp;with &nbsp;glimpses</a></div>        
          </body>`;

    //requestIdleCallback(async () => {});
    const htmlString = `<html>${headTemplate}${bodyTemplate}</html>`;
    return htmlString;
  }

  //<!--event-handlers-->
  const navigateBack = async () => {
    if (editMode) {
      //save changes if any in case of a new entry
      if (isNewEntry) {
        let isDirty = isJournalDirty();
        console.log(`[JournalDetail][IsDirty]=>${isDirty}`);
        if (isDirty) onDone();
      }
    }
    navigation.goBack();
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);

    if (editMode) {
      onDone();
    }
  };

  const toggleActiveFavorites = () => {
    toggleFavorites(activeJournal);
    setFavoriteStatus(activeJournal.isFavorite);
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleShare = async () => {
    try {
      toggleMenu();
      const result = await Share.share({
        message: `${activeJournal?.title}\n${activeJournal?.richContent}`,
      });

      if (result?.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result?.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const handleTextChange = (content) => {
    setContent(content);
  };

  const handleKeyPress = async (e) => {
    if (!isRetroMode) return;

    e.persist();

    let soundObject;
    switch (e.key) {
      case "Enter":
        //console.log(`enter`);
        soundObject = new Audio.Sound();
        await soundObject.loadAsync(require("./../assets/sounds/enter.wav"));
        break;

      case " ":
        //console.log(`space`);
        soundObject = new Audio.Sound();
        await soundObject.loadAsync(require("./../assets/sounds/space.wav"));
        break;

      case "Backspace":
        //console.log(`back`);
        try {
          soundObject = new Audio.Sound();
          await soundObject.loadAsync(require("./../assets/sounds/delete.wav"));
        } catch (error) {
          console.log(error);
        }
        break;

      default:
        //console.log(`key`);
        soundObject = new Audio.Sound();
        await soundObject.loadAsync(require("./../assets/sounds/key.wav"));
        break;
    }
    await soundObject.playFromPositionAsync(0);
  };

  const handleDeleteJournal = async () => {
    try {
      if (menuVisible) toggleMenu();

      await deleteJournal(activeJournal.guid);

      navigateBack();
    } catch (error) {
      console.log("❎[JournalDetail][handleDeleteJournal]=>", error);
    }
  };

  const handleExportPdf = () => {
    if (menuVisible) toggleMenu();
    try {
      if (!activeJournal || !activeJournal.entryCreatedDate) {
        console.error("Active journal or entry created date is not found.");
        return;
      }

      const htmlString = buildHtmlString(activeJournal);

      const options = {
        filename: `${sanitize(
          activeJournal.title ? activeJournal.title : "glimpses"
        )}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf().from(htmlString).set(options).save();
    } catch (error) {
      console.log(error);
    }
  };

  //save journal
  const onDone = async () => {
    const tags = getTagsFromContent();
    //console.log(">>>>>>Tags=>", tags);

    if (isEmpty(tags)) activeJournal.tagsList = [];
    else {
      activeJournal.tagsList = tags.map((tag) => {
        return { name: tag?.replace("#", "") };
      });
    }
    console.log(
      `[JournalDetail][onDone][TAGS]=> ${JSON.stringify(
        activeJournal.tagsList
      )}`
    );

    let journalDirty = isJournalDirty();

    if (isNewEntry) journalDirty = true;

    if (!journalDirty) {
      console.log(`[JournalDetail][onDone][NO CHANGE DETECTED]`);
      return;
    }

    console.log(
      `✅[JournalDetail][onDone][CHANGE DETECTED]=> Uploading journal...`
    );

    if (!activeJournal.latitude) {
      activeJournal.latitude = position?.coords?.latitude;
      activeJournal.longitude = position?.coords?.longitude;
    }

    //if location is not set and lat/long is there, then set location
    let { latitude, longitude } = activeJournal;
    if (!activeJournal.location && latitude && longitude) {
      let locInfo = await getLocationInfo(latitude, longitude);
      activeJournal.location = locInfo;
      setLocationInfo(locInfo);
    }

    activeJournal.entryModifiedDate = dateToTicks(new Date());

    updateJournal(activeJournal);
    isNewEntry = false;
  };

  const toggleCalendar = () => {
    setMenuVisible(false);
    setShowCalendar(!showCalendar);
  };

  const onRephrase = async () => {
    if (menuVisible) toggleMenu();

    textInputRef.current.focus();

    const originalContent = content;
    const { title: originalTitle, description: originalDescription } =
      getContentSplit();
    let contentToRephrase = originalDescription;

    //return if contentToRephrase is empty
    if (contentToRephrase.trim() === "") {
      console.log(`🚀[onRephrase][NOT ENOUGH CONTENT TO REPHRASE]`);
      return;
    }

    //let prompt = `Generate rephrasing of the original.\n Original: ${contentToRephrase}`;
    let prompt = `I want you to act as a rephrasing specialist. I will provide you a journal text, and you will rephrase it while maintaining the same meaning and tone. Your rephrased text should be clear, and professional. Let's begin. My journal text is as follows:${contentToRephrase}`;
    if (prompt.slice(-1) !== ".") prompt = prompt + ".";

    setContent("Generating ...");
    //================

    try {
      const response = await fetch(
        "https://rephraser-ai.vercel.app/api/generate",
        //"http://localhost:3000/api/generate",
        {
          method: "POST",
          headers: {
            accept: "*/*",
            "content-type": "text/plain;charset=UTF-8",
          },
          body: JSON.stringify({
            prompt,
          }),
        }
      );

      console.log("🚀Edge function returned.");

      if (!response.ok) {
        console.log("RESPONSE NOT OK");
        throw new Error(response.statusText);
      }

      // This data is a ReadableStream
      const data = response.body;
      if (!data) {
        console.log("🚀No data");
        return;
      }

      const reader = data.getReader();
      const decoder = new TextDecoder();
      let done = false;

      setContent("");
      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);
        setContent((prev) => prev + chunkValue);
      }

      console.log("🚀[Rephrase][Done] Content =>", content);

      //append the tags back to the content
      const tags = getTagsFromContent(originalContent);
      console.log("🚀[Rephrase] Tags found=>", tags);

      let tagContent = `\n\n⌘: ${tags?.map((tag) => `#${tag}`).join(" ")}`;
      console.log("🚀[Rephrase] TagContent=>", tagContent);

      setContent((prev) => `${originalTitle}\n${prev}${tagContent}`);
    } catch (error) {
      console.log("❎[ERR]", error);
      setContent(`${originalContent}\n\n❎ ${error}`);
    }
    //================
  };

  const onSelectDate = (date) => {
    try {
      let ticks = dateToTicks(date);
      activeJournal.entryCreatedDate = ticks;

      let dt = ticksToDate(ticks);
      setDate(dt);
      toggleCalendar();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePublish = async () => {
    try {
      dispatch(setLoading(true));
      if (menuVisible) toggleMenu();

      const htmlStr = buildHtmlString(activeJournal);
      const file = {
        name: `${activeJournal.guid}.html`,
        title: sanitize(activeJournal.title),
        data: htmlStr,
      };
      const sharedLink = await getShareableLink(file);
      console.log("🔗[handlePublish] sharedLink=>", sharedLink);

      if (sharedLink) {
        //await WebBrowser.openBrowserAsync(sharedLink);
        await await Linking.openURL(sharedLink);
      }
    } catch (error) {
      console.log("❎[ERR][handlePublish]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  //<!--components/renderers-->
  const BackAction = () => (
    <TopNavigationAction icon={BackIcon} onPress={navigateBack} />
  );

  const renderMenuAction = () => (
    <TopNavigationAction icon={OverflowMenuIcon} onPress={toggleMenu} />
  );

  const renderRightActions = () => {
    return (
      <>
        {editMode ? (
          <>
            <TopNavigationAction icon={DoneIcon} onPress={toggleEditMode} />
            <OverflowMenu
              anchor={renderMenuAction}
              visible={menuVisible}
              onBackdropPress={toggleMenu}
            >
              <MenuItem
                accessoryLeft={CameraIcon}
                title="Library"
                onPress={getImageFromLibrary}
              />
              <MenuItem
                accessoryLeft={ClockIcon}
                title="Pick date"
                onPress={toggleCalendar}
              />
              <MenuItem
                accessoryLeft={BotIcon}
                title="Rephrase"
                onPress={onRephrase}
              />
            </OverflowMenu>
          </>
        ) : (
          <>
            {favoriteStatus ? (
              <TopNavigationAction
                icon={FavIcon}
                onPress={toggleActiveFavorites}
              />
            ) : (
              <TopNavigationAction
                icon={UnFavIcon}
                onPress={toggleActiveFavorites}
              />
            )}
            <TopNavigationAction icon={EditIcon} onPress={toggleEditMode} />
            <OverflowMenu
              anchor={renderMenuAction}
              visible={menuVisible}
              onBackdropPress={toggleMenu}
            >
              <MenuItem
                accessoryLeft={ShareIcon}
                title="Share"
                onPress={handleShare}
              />
              <MenuItem
                accessoryLeft={PdfIcon}
                title="Export to PDF"
                onPress={handleExportPdf}
              />
              <MenuItem
                accessoryLeft={LinkIcon}
                title="Publish"
                onPress={handlePublish}
              />
              <MenuItem
                accessoryLeft={TrashIcon}
                title="Delete"
                onPress={handleDeleteJournal}
              />
            </OverflowMenu>
          </>
        )}
      </>
    );
  };

  return (
    <Layout
      style={{
        //paddingBottom: insets.bottom,
        flex: 1,
      }}
    >
      <SafeAreaView style={{ flex: 1 }}>
        {/* <!--header--> */}
        <TopNavigation
          alignment="center"
          //title={editMode ? `${date.localDate}-${date.localYear}` : ""}

          accessoryLeft={BackAction}
          accessoryRight={renderRightActions}
          title={(evaProps) => (
            <Text
              {...evaProps}
              style={{
                ...evaProps.style,
                //fontFamily: isRetroMode ? "Tox-Typewriter" : "Lora-Regular",
                fontFamily: "Tox-Typewriter",
                fontSize: 14,
                fontWeight: "bold",
                //color: isRetroMode ? "white" : "black",
              }}
            >
              {/*{editMode ? `${date.localDate}-${date.localYear}` : ""}*/}
              {editMode
                ? `${new Date(date?.date).toLocaleDateString(
                    navigator.language
                  )}`
                : ""}
            </Text>
          )}
        />
        <ErrorBoundary>
          <Divider />

          {/* <!--MainView--> */}
          <>
            {activeJournal ? (
              <Layout style={{ flex: 1 }}>
                {editMode ? (
                  //<KeyboardAvoidingView behavior="padding" style={{ flex: 1 }}>
                  <KeyboardAvoidingView style={{ flex: 1 }}>
                    <Layout style={{ flex: 1 }}>
                      {/* TextInput */}
                      <TextInput
                        ref={textInputRef}
                        multiline={true}
                        autoFocus={true}
                        enablesReturnKeyAutomatically={true}
                        allowFontScaling={true}
                        autoCapitalize="sentences"
                        inputAccessoryViewID={inputAccessoryViewID}
                        style={{
                          ...styles.text,
                          fontFamily: isRetroMode
                            ? "Tox-Typewriter"
                            : "Lora-Regular",
                          flex: 1,
                          paddingHorizontal: 14,
                          paddingBottom: 100,
                          lineHeight: 17 * 1.5,
                        }}
                        underlineColorAndroid="transparent"
                        placeholder="What is on your mind?"
                        placeholderTextColor={Colors.hintColor}
                        value={content}
                        onChangeText={handleTextChange}
                        onKeyPress={handleKeyPress}
                        //onSelectionChange={(se) => {
                        //  const { nativeEvent } = se;
                        //  const { selection } = nativeEvent;
                        //  console.log("onSelectionChange", selection);
                        //  setSelection(selection);
                        //}}
                      />

                      <InputAccessoryView nativeID={inputAccessoryViewID}>
                        {/* toolbar */}
                        <View
                          style={{
                            height: 30,
                            backgroundColor: Colors.accessoryView,
                            paddingHorizontal: 12,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          {activeJournal.imageThumbnail ? (
                            <TouchableHighlight onPress={getImageFromLibrary}>
                              <Image
                                style={{
                                  height: 25,
                                  width: 25,
                                  borderRadius: 12.5,
                                  marginRight: 12,
                                }}
                                source={{
                                  uri: activeJournal.imageThumbnail?.startsWith(
                                    localConstant.ImagePrefix
                                  )
                                    ? `${activeJournal.imageThumbnail}`
                                    : `${localConstant.ImagePrefix}${activeJournal.imageThumbnail}`,
                                }}
                                loading="lazy"
                                decoding="async"
                              />
                            </TouchableHighlight>
                          ) : (
                            <Ionicons
                              name="ios-camera"
                              size={28}
                              style={{ marginRight: 12 }}
                              onPress={getImageFromLibrary}
                            />
                          )}
                          {/* <Ionicons name="ios-snow" size={25} /> */}
                          {Platform.OS !== "web" && (
                            <MaterialIcons
                              name="keyboard-hide"
                              size={24}
                              color="black"
                              onPress={() => Keyboard.dismiss()}
                            />
                          )}
                        </View>
                      </InputAccessoryView>
                    </Layout>
                  </KeyboardAvoidingView>
                ) : (
                  /*<!--ViewMode-->*/
                  <ScrollView
                    style={{
                      flex: 1,
                      //borderWidth: 2,
                      //borderColor: "orange",
                    }}
                  >
                    <Layout
                      style={{
                        //height: "100vh",
                        height: "100%",
                        //borderWidth: 2,
                        //borderColor: "magenta",
                      }}
                    >
                      {/* Image */}
                      {activeJournal.imageData ? (
                        <Image
                          source={{
                            uri: activeJournal.imageData?.startsWith(
                              localConstant.ImagePrefix
                            )
                              ? `${activeJournal.imageData}`
                              : `${localConstant.ImagePrefix}${activeJournal.imageData}`,
                          }}
                          loading="lazy"
                          decoding="async"
                          style={{
                            width: "100%",
                            resizeMode: "cover",
                            height: 400,
                          }}
                        />
                      ) : activeJournal.imageThumbnail ? (
                        <Image
                          source={{
                            uri: activeJournal.imageThumbnail?.startsWith(
                              localConstant.ImagePrefix
                            )
                              ? `${activeJournal.imageThumbnail}`
                              : `${localConstant.ImagePrefix}${activeJournal.imageThumbnail}`,
                          }}
                          loading="lazy"
                          decoding="async"
                          style={{
                            width: "100%",
                            resizeMode: "cover",
                            height: 400,
                          }}
                        />
                      ) : null}

                      {/* Content */}
                      <Layout
                        style={{
                          flex: 1,
                          paddingHorizontal: 20,
                          //borderWidth: 2,
                          //borderColor: "green",
                        }}
                      >
                        {/* date */}
                        {date && (
                          <DateContainer
                            entryCreatedLongDate={
                              activeJournal?.entryCreatedDate
                            }
                          />
                        )}

                        {/* location */}
                        {activeJournal.location && (
                          <LocationContainer
                            locationInfo={
                              activeJournal.location
                                ? activeJournal.location
                                : locationInfo
                            }
                          />
                        )}

                        <Divider />

                        {/* title */}
                        {activeJournal.title ? (
                          Platform.OS === "web" ? (
                            <Text
                              //category="h4"
                              style={{
                                fontSize: 36,
                                fontWeight: "700",
                                justifyContent: "center",
                                letterSpacing: 0.3,
                              }}
                            >
                              {activeJournal.title}
                            </Text>
                          ) : (
                            <Text style={styles.title} category="h4">
                              {activeJournal.title}
                            </Text>
                          )
                        ) : null}

                        {/* richContent */}
                        <Layout
                          style={{
                            justifyContent: "space-between",
                            paddingTop: 7,
                            paddingBottom: 12,
                            //borderWidth: 2,
                            //borderColor: "Lime",
                          }}
                        >
                          <View
                            style={{
                              flex: 1,
                              //borderWidth: 1,
                              //borderColor: "red",
                            }}
                          >
                            <MarkdownView isRetroMode={isRetroMode}>
                              {activeJournal.richContent}
                            </MarkdownView>
                          </View>

                          {/* tags */}
                          <View
                            style={{
                              flexDirection: "row",
                              flexWrap: "wrap",
                              //borderWidth: 1,
                              //borderColor: "blue",
                            }}
                          >
                            <>
                              {activeJournal.tagsList.map((tag, index) => (
                                <View
                                  key={index}
                                  style={{
                                    paddingRight: 3,
                                    paddingLeft: 6,
                                    paddingTop: 0,
                                    paddingBottom: 3,
                                    marginHorizontal: 1,
                                    marginTop: 2,
                                    backgroundColor: "#EBF8FF",
                                    borderRadius: 9,
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Text
                                    category="c2"
                                    style={{
                                      color: "#2B6CB0",
                                      textAlign: "center",
                                    }}
                                  >{`${tag?.name} `}</Text>
                                </View>
                              ))}
                            </>
                          </View>
                        </Layout>

                        {/* mapView */}
                        {activeJournal.latitude ? (
                          <Layout style={{ height: 100 }}>
                            <MapView
                              latitude={activeJournal.latitude}
                              longitude={activeJournal.longitude}
                            />
                          </Layout>
                        ) : null}
                      </Layout>
                    </Layout>
                  </ScrollView>
                )}
              </Layout>
            ) : (
              /**loading indicator */
              <Layout
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ActivityIndicator color={Colors.listIcon} />
              </Layout>
            )}
          </>

          <CalendarModal
            visible={showCalendar}
            onCancel={toggleCalendar}
            onSelectDate={onSelectDate}
          />
        </ErrorBoundary>
      </SafeAreaView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    //backgroundColor: "white",
    padding: 20,
    zIndex: 100,
  },
  cancelButton: {
    marginTop: 20,
    padding: 10,
    backgroundColor: "gray",
    borderRadius: 5,
  },
  cancelButtonText: {
    color: "white",
    fontWeight: "bold",
  },
});

const mdStyles = StyleService.create({
  text: {
    color: "text-basic-color",
    fontFamily: "Lora-Regular",
    fontSize: 17,
    lineHeight: 17 * 1.5,
    //lineHeight: 24,
  },
});

const mapStateToProps = (state) => ({
  isRetroMode: state.dataStore.isRetroMode,
});

const wrapper = compose(
  connect(mapStateToProps, { updateJournal, toggleFavorites })
);

export default wrapper(JournalDetail);
