//<!--redux-->
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer, autoMergeLevel2 } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";

/**libs */
import localforage from "localforage";

//<!--local-->
import rootReducer from "./reducers";

//const initialState = {};
//const middleware = [thunk];

localforage.config({
  driver: localforage.INDEXEDDB,
});

const persistConfig = {
  key: "root",
  storage: localforage,
  timeout: 50000,
  // stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== "production",
  maxAge: 10,
});

const persistor = persistStore(store);
// console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@@PURGING@@@@@@@@@@@@@@@@@@@@@@@@@@`);
//persistor.purge();

export { store, persistor };
