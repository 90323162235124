export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_THEME = "SET_THEME";
export const TOGGLE_RETRO_MODE = "TOGGLE_RETRO_MODE";
export const SET_CURSOR = "SET_CURSOR";

export const GET_METADATA_BEGIN = "GET_METADATA_BEGIN";
export const GET_METADATA_SUCCESS = "GET_METADATA_SUCCESS";
export const GET_METADATA_ERROR = "GET_METADATA_ERROR";

export const GET_JOURNALS_BEGIN = "GET_JOURNALS_BEGIN";
export const GET_JOURNALS_SUCCESS = "GET_JOURNALS_SUCCESS";
export const GET_JOURNALS_FAILURE = "GET_JOURNALS_FAILURE";

export const ADD_JOURNAL = "ADD_JOURNAL";
export const ADD_JOURNAL_ERROR = "ADD_JOURNAL_ERROR";
export const ADD_JOURNALS = "ADD_JOURNALS";

export const UPDATE_JOURNAL_BEGIN = "UPDATE_JOURNAL_BEGIN";
export const UPDATE_JOURNAL_SUCCESS = "UPDATE_JOURNAL_SUCCESS";
export const UPDATE_JOURNAL_ERROR = "UPDATE_JOURNAL_ERROR";

export const DELETE_JOURNAL_BEGIN = "DELETE_JOURNAL_BEGIN";
export const DELETE_JOURNAL_SUCCESS = "DELETE_JOURNAL_SUCCESS";
export const DELETE_JOURNAL_ERROR = "DELETE_JOURNAL_ERROR";
export const DELETE_JOURNALS = "DELETE_JOURNALS";

export const PURGE_JOURNAL = "PURGE_JOURNAL";
export const PURGE_JOURNALS = "PURGE_JOURNALS";

export const GET_PHOTOS = "GET_PHOTOS";
export const UPSERT_PHOTO = "UPSERT_PHOTO";
export const DELETE_PHOTO = "DELETE_PHOTO";

export const SET_PIN = "SET_PIN";
export const SET_LOADING = "SET_LOADING";
export const LOGOUT = "LOGOUT";
