import _ from "lodash";

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const contains = (item, query) => {
  const { title, richContent } = item;
  if (
    title?.toLowerCase().includes(query) ||
    richContent?.toLowerCase().includes(query)
  )
    return true;
  else return false;
};

export const getObjectHash = (obj) =>
  Object.keys(obj).reduce((hash, key) => hash + obj[key], 0);

export function base64ToByteArray(base64String) {
  //return Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
  const byteArray = new Uint8Array(
    atob(base64String)
      .split("")
      .map((c) => c.charCodeAt(0))
  );
  return byteArray;
}

export function byteArrayToBase64(byteArray) {
  try {
    const base64String = btoa(String.fromCharCode(...byteArray));
    console.log(typeof byteArray, byteArray);
    return base64String;
  } catch (error) {
    console.log("❎[byteArrayToBase64]", error);
    throw error;
  }
}

//first letter of each word to uppercase
export const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};
