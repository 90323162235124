import React from "react";
import { Text, TopNavigation } from "@ui-kitten/components";

const ScreenHeader = ({ title }) => {
  return (
    <TopNavigation
      appearance="default"
      title={() => (
        <Text
          category="h4"
          style={{
            paddingTop: 30,
            paddingLeft: 6,
            fontWeight: "700",
            fontSize: 32,
          }}
        >
          {title}
        </Text>
      )}
    />
  );
};

export default React.memo(ScreenHeader);
