import React from "react";

/*<!-- libs-->*/
//import { createStackNavigator } from "@react-navigation/stack";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

/*<!--local-->*/
import LockScreen from "./../screens/Lock";
import WelcomeScreen from "./../screens/Welcome";
import AllJournalsScreen from "../screens/AllJournals";
import JournalScreen from "../screens/Journals";
import JournalDetail from "./../screens/JournalDetail";
import FavoritesScreen from "./../screens/Favorites";
import PhotosScreen from "./../screens/Photos";
import SettingsScreen from "./../screens/Settings";
import AccountScreen from "./../screens/Account";
import AboutScreen from "../screens/About";
import SecurityScreen from "../screens/Security";
import CalendarScreen from "./../screens/Calendar";
import TagsScreen from "../screens/Tags";
import TrashScreen from "../screens/Trash";
import TrashJournalDetail from "../screens/TrashJournalDetail";
import ScrapScreen from "../screens/Scrap";
import ArticleScreen from "../screens/Articles";

//<!--Global: AppStacks-->

//AuthStack
const AuthStack = createNativeStackNavigator();
export const AuthStackScreens = () => (
  <AuthStack.Navigator screenOptions={{ headerShown: false }}>
    <AuthStack.Screen name="Welcome" component={WelcomeScreen} />
  </AuthStack.Navigator>
);

//JournalStack
const JournalStack = createNativeStackNavigator();
export const JournalStackScreens = () => (
  <JournalStack.Navigator screenOptions={{ headerShown: false }}>
    <JournalStack.Screen
      name="AllJournals"
      component={AllJournalsScreen}
      options={{
        gestureEnabled: true,
        animation: "fade",
        title: "",
      }}
    />
    <JournalStack.Screen
      name="Journal-Detail"
      component={JournalDetail}
      options={{
        gestureEnabled: true,
      }}
    />
  </JournalStack.Navigator>
);

//PhotosStack
const PhotosStack = createNativeStackNavigator();
export const PhotosStackScreens = () => (
  <PhotosStack.Navigator screenOptions={{ headerShown: false }}>
    <PhotosStack.Screen name="Photos" component={PhotosScreen} />
    <PhotosStack.Screen name="Journal-Detail" component={JournalDetail} />
  </PhotosStack.Navigator>
);

//FavoritesStack
const FavoritesStack = createNativeStackNavigator();
export const FavoritesStackScreens = () => (
  <FavoritesStack.Navigator screenOptions={{ headerShown: false }}>
    <FavoritesStack.Screen name="Favorites" component={FavoritesScreen} />
    <FavoritesStack.Screen name="Journal-Detail" component={JournalDetail} />
  </FavoritesStack.Navigator>
);

//CalendarStack
const CalendarStack = createNativeStackNavigator();
export const CalendarStackScreens = () => (
  <CalendarStack.Navigator screenOptions={{ headerShown: false }}>
    <CalendarStack.Screen name="Calendar" component={CalendarScreen} />
    <CalendarStack.Screen name="Journal-Detail" component={JournalDetail} />
  </CalendarStack.Navigator>
);

//SettingsStack
const SettingsStack = createNativeStackNavigator();
export const SettingsStackScreens = () => (
  <SettingsStack.Navigator screenOptions={{ headerShown: false }}>
    <SettingsStack.Screen name="Settings" component={SettingsScreen} />
    <SettingsStack.Screen name="Account" component={AccountScreen} />
    <SettingsStack.Screen name="About" component={AboutScreen} />
    <SettingsStack.Screen name="Secure" component={SecurityScreen} />
    <SettingsStack.Screen name="Scrap" component={ScrapScreen} />
  </SettingsStack.Navigator>
);

//TagsStack
const TagsStack = createNativeStackNavigator();
export const TagsStackScreens = () => (
  <TagsStack.Navigator screenOptions={{ headerShown: false }}>
    <TagsStack.Screen name="Tags" component={TagsScreen} />
    <TagsStack.Screen name="Journals" component={JournalScreen} />
    <TagsStack.Screen name="Journal-Detail" component={JournalDetail} />
  </TagsStack.Navigator>
);

//ArticlesStack
const ArticleStack = createNativeStackNavigator();
export const ArticleStackScreens = () => (
  <ArticleStack.Navigator screenOptions={{ headerShown: false }}>
    <ArticleStack.Screen name="Articles" component={ArticleScreen} />
  </ArticleStack.Navigator>
);

//TrashStack
const TrashStack = createNativeStackNavigator();
export const TrashStackScreens = () => (
  <TrashStack.Navigator screenOptions={{ headerShown: false }}>
    <TrashStack.Screen name="Bin" component={TrashScreen} />
    <TrashStack.Screen name="Journals" component={JournalScreen} />
    <TrashStack.Screen
      name="TrashJournalDetail"
      component={TrashJournalDetail}
    />
  </TrashStack.Navigator>
);

//ScrapStack
const ScrapStack = createNativeStackNavigator();
export const ScrapStackScreens = () => (
  <ScrapStack.Navigator screenOptions={{ headerShown: false }}>
    <ScrapStack.Screen name="Bin" component={ScrapScreen} />
  </ScrapStack.Navigator>
);
