import { dark } from "@eva-design/eva";

const customDarkTheme = {
  ...dark,

  /* Basic colors - for backgrounds and borders and texts */
  "color-basic-100": "#EDF2F7",
  "color-basic-200": "#E2E8F0",
  "color-basic-300": "#CBD5E0",
  "color-basic-400": "#A0AEC0",
  "color-basic-500": "#718096",
  //"color-basic-600": "#6B7C8E",
  "color-basic-600": "#8295A5",
  "color-basic-700": "#2D3748",
  "color-basic-800": "#1A202C",
  "color-basic-900": "#171923",
  "color-basic-1000": "#0F111A",
  "color-basic-1100": "#07080F",
};

export default customDarkTheme;
