import "react-native-gesture-handler";
import React, { useState, useEffect } from "react";
import { StatusBar } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";

/*<!-- expo-->*/
import AppLoading from "expo-app-loading";
//import { StatusBar } from "expo-status-bar";
import { useFonts } from "@use-expo/font";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";

/*<!-- redux-->*/
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { store, persistor } from "./redux/store";

/*<!-- libs-->*/
import _ from "lodash";
//import * as eva from "@eva-design/eva";
import { light, mapping } from "@eva-design/eva";
import { ApplicationProvider, IconRegistry } from "@ui-kitten/components";
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { enableScreens } from "react-native-screens";

/*<!--local-->*/
import Navigator from "./navigation/Navigator";
import { ThemeContext } from "./skin/theme-context";
import customDarkTheme from "./custom-theme";
//import { default as theme } from "./skin/theme.json";
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

enableScreens();

const themes = {
  light,
  dark: customDarkTheme, // Use the custom dark theme instead of the default dark theme
};

const App = () => {
  //state

  let [fontsLoaded] = useFonts({
    "Lora-Regular": require("./assets/fonts/Lora-Regular.ttf"),
    "Tox-Typewriter": require("./assets/fonts/Tox-Typewriter.ttf"),
  });

  const [theme, setTheme] = useState("light");

  //hooks
  useEffect(() => {
    console.log(`~~~~~~~~~~~~~~~~~~~~App loading...~~~~~~~~~~~~~~~~~~~~`);
  }, []);

  //functions
  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      StatusBar.setBarStyle("light-content");
    } else {
      setTheme("light");
      StatusBar.setBarStyle("dark-content");
    }
  };

  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <>
        <IconRegistry icons={EvaIconsPack} />
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
          <ApplicationProvider mapping={mapping} theme={themes[theme]}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <ActionSheetProvider>
                  <GestureHandlerRootView style={{ flex: 1 }}>
                    <Navigator />
                  </GestureHandlerRootView>
                </ActionSheetProvider>
              </PersistGate>
            </Provider>
          </ApplicationProvider>
        </ThemeContext.Provider>
      </>
    );
  }
};

export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
