import React, { useEffect, useRef } from "react";
import mapboxgl, { Map } from "maplibre-gl";
import "./../skin/map.css";

const MapView = ({ latitude, longitude }) => {
  const mapContainer = useRef(null);

  useEffect(() => {
    console.log(`[MapView] latitude: ${latitude}, longitude: ${longitude} `);
    if (!latitude || !longitude) return;

    const myAPIKey = "wYGlWW3cK3mGKl97lTCP";
    const mapStyle = "https://api.maptiler.com/maps/streets-v2/style.json";

    const initialState = {
      lng: longitude,
      lat: latitude,
      zoom: 10,
    };

    const map = new Map({
      container: mapContainer.current,
      style: `${mapStyle}?key=${myAPIKey}`,
      center: [initialState.lng, initialState.lat],
      zoom: initialState.zoom,
    });
    // Create a new marker
    const marker = new mapboxgl.Marker()
      .setLngLat([longitude, latitude])
      .addTo(map);
  }, [mapContainer.current]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
      className="map-container"
      ref={mapContainer}
    ></div>
  );
};

export default MapView;
