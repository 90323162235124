import React, { useState, useEffect, useMemo, useCallback } from "react";
import { View } from "react-native";

//<!--libs-->
import { ListItem, Text } from "@ui-kitten/components";
import { Image } from "react-native-expo-image-cache";

import { areEqual } from "react-window";

//<-- redux-->
import { useSelector } from "react-redux";

//<!--local-->
import JournalTitle from "./JournalTitle";
import JournalDescription from "./JournalDescription";
import useIsMounted from "../hooks/useIsMounted";
import { ticksToDate } from "../utils/dateUtils";
import Colors from "../constants/Colors";
import { Constants } from "../constants";

/** --JOURNAL ITEM-- */
export const JournalItem = (props) => {
  /**destructure */
  const {
    item,
    index,
    navigateToDetails,
    //isRetroMode,
    deleteJournal,
    toggleFavorites,
  } = props;

  /**custom-hooks */
  const isMounted = useIsMounted();

  if (!isMounted) {
    return null;
  }

  /**state */
  const [preview, setPreview] = useState({
    uri: item?.imageThumbnail?.startsWith(Constants.ImagePrefix)
      ? `${item?.imageThumbnail}`
      : `${Constants.ImagePrefix}${item?.imageThumbnail}`,
  });

  /**selectors */
  const isRetroMode = useSelector((state) => state.dataStore.isRetroMode);

  /**hooks */
  useEffect(() => {
    if (isMounted) {
      setPreview({
        uri: item?.imageThumbnail?.startsWith(Constants.ImagePrefix)
          ? `${item?.imageThumbnail}`
          : `${Constants.ImagePrefix}${item?.imageThumbnail}`,
      });
    }

    return () => {
      setPreview(null);
    };
  }, [isMounted]);

  /**components */
  const renderRightAccessory = useMemo(() => {
    let ticks = Number(item?.entryCreatedDate);
    let dt = ticksToDate(ticks);
    //console.log(dt);
    return (
      <View
        key={index}
        style={{
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "center",
          paddingHorizontal: 3,
          //backgroundColor: "red",
          //minWidth: 200,
          //minHeight: 200,
        }}
      >
        {/* day */}
        <Text
          category="s2"
          style={{
            paddingRight: 4,
          }}
        >
          {new Date(dt.date)
            .toLocaleDateString(navigator.language, {
              weekday: "short",
            })
            ?.toUpperCase()}
        </Text>

        {/* date */}
        {item.isFavorite ? (
          <Text
            style={{
              color: Colors.favorite,
              alignSelf: "flex-end",
              marginVertical: -7,
            }}
            category="h1"
          >
            {dt.localDate}
          </Text>
        ) : (
          <Text
            style={{
              alignSelf: "flex-end",
              marginVertical: -7,
            }}
            category="h1"
          >
            {dt.localDate}
          </Text>
        )}

        {/* month|year */}
        <View
          style={{
            flexDirection: "row",
            //alignItems: "center",
            //justifyContent: "center",
          }}
        >
          <Text category="s2">
            {dt.utcMonth.toString().slice(0, 3)?.toUpperCase()}
          </Text>
          <Text category="s2"> {dt.localYear}</Text>
        </View>
      </View>
    );
  }, [item.entryCreatedDate, item.isFavorite]);

  const renderLeftAccessory = useCallback(() => {
    return (
      <>
        {item?.imageThumbnail && isMounted ? (
          <Image
            {...props}
            {...{ preview }}
            loading="lazy"
            decoding="async"
            style={{
              width: 72,
              height: 72,
              borderRadius: 12,
              overflow: "hidden",
              //marginBottom: 3,
              paddingVertical: 1,
              alignSelf: "center",
            }}
          />
        ) : null}
      </>
    );
  }, [isMounted]);

  /**event-handlers */
  const handleOnPress = () => {
    navigateToDetails(item);
  };

  return (
    <ListItem
      style={{
        height: 75,
        maxHeight: 80,
        overflow: "hidden",
        paddingVertical: 1,
        //border: "1px solid red",
      }}
      title={() => <JournalTitle title={item.title} />}
      description={() => (
        <JournalDescription
          description={`${item.richContent
            ?.replace(/\r?\n|\r/g, "")
            .slice(0, 75)}...`}
          isRetroMode={isRetroMode}
        />
      )}
      onPress={handleOnPress}
      accessoryLeft={renderLeftAccessory}
      accessoryRight={() => renderRightAccessory}
    />
  );
};

export default React.memo(JournalItem, areEqual);
