import React from "react";

/*<!-- libs-->*/
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { BottomNavigation, BottomNavigationTab } from "@ui-kitten/components";

/*<!--local-->*/
import {
  AuthStackScreens,
  JournalStackScreens,
  PhotosStackScreens,
  FavoritesStackScreens,
  CalendarStackScreens,
  SettingsStackScreens,
  TagsStackScreens,
} from "./Stacks";
import {
  JournalIcon,
  PhotoIcon,
  FavoritesIcon,
  CalendarIcon,
  MenuIcon,
  TagIcon,
  InsightsIcon,
} from "./../components/Icons";

//AppTabs
const AppTabs = createBottomTabNavigator();

const BottomTabBar = React.memo(({ navigation, state }) => {
  return (
    <BottomNavigation
      //appearance="noIndicator"
      indicatorStyle={{
        backgroundColor: "#4299E1",
      }}
      selectedIndex={state.index}
      style={{
        paddingBottom: 12,
      }}
      onSelect={(index) => navigation.navigate(state.routeNames[index])}
    >
      <BottomNavigationTab
        icon={JournalIcon}
        //title="Timeline"
      />
      <BottomNavigationTab
        icon={PhotoIcon}
        //title="Photos"
      />
      <BottomNavigationTab
        icon={FavoritesIcon}
        //title="Favorites"
      />
      <BottomNavigationTab
        icon={CalendarIcon}
        //title="Calendar"
      />
      <BottomNavigationTab
        icon={InsightsIcon}
        //title="Menu"
      />
      {/*<BottomNavigationTab icon={TagIcon} />*/}
    </BottomNavigation>
  );
});

export const BottomTabNavigator = () => (
  <AppTabs.Navigator
    //tabBarOptions={{ keyboardHidesTabBar: true }}
    tabBar={(props) => <BottomTabBar {...props} />}
    screenOptions={{
      headerShown: false,
      tabBarActiveTintColor: "#4299E1",
    }}
  >
    <AppTabs.Screen
      name="JOURNAL"
      component={JournalStackScreens}
      //options={{
      //  tabBarLabel: "Timeline",
      //  tabBarIcon: ({ color, size }) => (
      //    <JournalIcon style={{ width: 24, height: 24 }} />
      //  ),
      //}}
    />
    <AppTabs.Screen
      name="PHOTOS"
      component={PhotosStackScreens}
      //options={{
      //  tabBarLabel: "Photos",
      //  tabBarIcon: ({ color, size }) => (
      //    <PhotoIcon style={{ width: 24, height: 24 }} />
      //  ),
      //}}
    />
    <AppTabs.Screen
      name="FAVORITES"
      component={FavoritesStackScreens}
      //options={{
      //  tabBarLabel: "Favorites",
      //  tabBarIcon: ({ color, size }) => (
      //    <FavoritesIcon style={{ width: 24, height: 24 }} />
      //  ),
      //}}
    />
    <AppTabs.Screen
      name="CALENDAR"
      component={CalendarStackScreens}
      //options={{
      //  tabBarLabel: "Calendar",
      //  tabBarIcon: ({ color, size }) => (
      //    <CalendarIcon style={{ width: 24, height: 24 }} />
      //  ),
      //}}
    />
    <AppTabs.Screen
      name="MENU"
      component={SettingsStackScreens}
      //options={{
      //  tabBarLabel: "Insights",
      //  tabBarIcon: ({ color, size }) => (
      //    <InsightsIcon style={{ width: 24, height: 24 }} />
      //  ),
      //}}
    />
  </AppTabs.Navigator>
);
