import React, { useState, useEffect, useCallback } from "react";
import { SafeAreaView, View } from "react-native";
import * as WebBrowser from "expo-web-browser";

//<!--libs-->
import { useLiveQuery } from "dexie-react-hooks";
import {
  Text,
  Layout,
  Divider,
  TopNavigation,
  TopNavigationAction,
  List,
  ListItem,
  Icon,
  Button,
  OverflowMenu,
  MenuItem,
} from "@ui-kitten/components";
import { useNavigation } from "@react-navigation/native";

//<!-- redux-->
import {
  deleteLinkFileAtServer,
  getLinksMetadata,
  toggleFavorites,
} from "./../redux/actions/dataAction";

//<!--local-->
import useIsMounted from "./../hooks/useIsMounted";
import EmptyView from "./../components/EmptyView";
import ScreenHeader from "../components/ScreenHeader";
import Enumerations from "./../constants/Enumerations";
import { FlashList } from "@shopify/flash-list";
import { MenuIcon } from "../components/Icons";
import { dateToTicks } from "../utils/dateUtils";
import { formatDistance, formatDistanceToNow } from "date-fns";
import isEmpty from "lodash.isempty";
import {
  deleteArticleEntry,
  getArticleEntries,
  saveArticleEntries,
} from "../data/db";

//<!--FAVORITES-->
const Articles = ({ navigation }) => {
  /**state */
  const articles = useLiveQuery(() => getArticleEntries(), []);

  /**custom-hooks */
  const nav = useNavigation();
  const isMounted = useIsMounted();

  if (!isMounted) return null;

  /**hooks */
  useEffect(() => {
    console.log("🌟[Articles] Loading...");

    fetchArticles();

    return () => {
      console.log("Articles: Unloading...");
    };
  }, []);

  /**functions */
  const fetchArticles = async () => {
    const articleMetadata = await getLinksMetadata();

    if (isEmpty(articleMetadata)) return;

    const articleEntries = articleMetadata?.links.map((item) => {
      return {
        guid: item.name.split(".")[0],
        name: item.name,
        url: item.url,
        clientModified: item["client_modified"],
      };
    });

    await saveArticleEntries(articleEntries);
  };

  function getArticleURL(inputURL) {
    try {
      const parts = inputURL.split("/");
      const filename = parts[parts.length - 1].split("?")[0];
      const fileId = parts[parts.length - 2];

      const output = `https://getglimpses.com/articles.html?t=glimpses&s=${fileId}/${filename}`;
      console.log("[getArticleURL]->", output);
      return output;
    } catch (error) {
      console.log("[ERR][getArticleURL]->", error);
    }
  }

  /**event-handlers */

  /**components/renderers */
  const BackAction = () => (
    <TopNavigationAction icon={MenuIcon} onPress={navigation.toggleDrawer} />
  );

  const renderLeftAction = (props) => {
    const { item } = props;

    return (
      <Button
        style={{ margin: 2 }}
        appearance="ghost"
        status="primary"
        accessoryLeft={<Icon {...props} name="link-2-outline" />}
        onPress={async () => {
          try {
            const url = getArticleURL(item.url);
            await WebBrowser.openBrowserAsync(url);
          } catch (error) {
            console.log("[ERR][renderLeftAction]->", error);
          }
        }}
      />
    );
  };

  const renderRightAction = (props) => {
    const { item } = props;
    return (
      <Button
        style={{ margin: 2 }}
        appearance="ghost"
        status="danger"
        accessoryLeft={<Icon {...props} name="close-circle-outline" />}
        onPress={async () => {
          try {
            await deleteLinkFileAtServer(item.guid);
            await deleteArticleEntry(item.guid);
          } catch (error) {
            console.log("[ERR][renderRightAction]->", error);
          }
        }}
      />
    );
  };

  const renderArticle = useCallback(
    ({ item, index }) => (
      <>
        <ListItem
          title={`${item.name}`}
          description={`${formatDistance(
            new Date(item.clientModified),
            new Date()
          )} ago`}
          accessoryLeft={(props) => renderLeftAction({ props, item })}
          accessoryRight={(props) => renderRightAction({ props, item })}
        />
      </>
    ),
    [articles]
  );

  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <>
          <TopNavigation
            appearance="default"
            title={() => (
              <Text
                category="h4"
                style={{
                  //paddingTop: 30,
                  //paddingLeft: 6,
                  fontWeight: "700",
                  fontSize: 32,
                }}
              >{`Articles`}</Text>
            )}
            accessoryLeft={BackAction}
          />
          <Divider />
          {articles?.length > 0 ? (
            <List
              style={{ flex: 1 }}
              data={articles}
              ItemSeparatorComponent={Divider}
              renderItem={renderArticle}
            />
          ) : (
            <EmptyView
              information={`No published articles are found..`}
              icon={Enumerations.Articles}
            />
          )}
        </>
      </SafeAreaView>
    </Layout>
  );
};

export default Articles;
