import React, { useState, useEffect } from "react";
import { StyleSheet, Platform } from "react-native";

//<!--expo-->
import { Ionicons } from "@expo/vector-icons";
import { maybeCompleteAuthSession } from "expo-web-browser";

//<!--libs-->
import { Layout, Button, Text, Icon } from "@ui-kitten/components";

//<!--redux-->
import Constants from "expo-constants";
import { connect } from "react-redux";
import { setAccessToken } from "../redux/actions/dataAction";

//<!--local-->
import AppIcon from "./../components/AppIcon";
import useDropboxAuth from "./../hooks/useDropboxAuth";
import { getVersion } from "../utils/customUtils";
import Colors from "./../constants/Colors";

if (Platform.OS === "web") {
  Constants.manifest.id = `@aneesahammed/${Constants.manifest.slug}`;
  maybeCompleteAuthSession();
}

// <!-- WELCOME-->
const Welcome = ({ navigation, setAccessToken }) => {
  const [request, res, promptAsync] = useDropboxAuth();
  //console.log(`[Welcome]>>${JSON.stringify(res)}`);

  useEffect(() => {
    if (res) {
      if (res.type == "success") {
        const result = {
          code: res.params.code,
        };
        setAccessToken(result);
      }
    }
  }, [res]);

  const _handleLogin = () => {
    let response = promptAsync();
  };

  const DropboxIcon = (props) => (
    <Ionicons
      name="logo-dropbox"
      size={32}
      color={Colors.appWhite}
      style={{ marginRight: 12 }}
    />
  );

  return (
    <Layout
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AppIcon height="100" width="100" color={"#1f2937"} />
      <Text style={{ textAlign: "center", fontSize: 38, fontWeight: "500" }}>
        glimpses
      </Text>
      <Text
        appearance="hint"
        style={{
          textAlign: "center",
          fontWeight: "300",
          fontSize: 18,
          paddingVertical: 12,
        }}
      >
        {`Capture your memories\nto remember life..`}
      </Text>
      <Button
        //size="large"
        style={styles.button}
        status="primary"
        accessoryLeft={DropboxIcon}
        onPress={_handleLogin}
      >
        Log in with Dropbox
      </Button>
      <Text category="p2" appearance="hint" style={{ textAlign: "center" }}>
        {`By logging in, you agree to our Terms of Service and Privacy Policy.\n version:${getVersion()}`}
      </Text>
    </Layout>
  );
};

const styles = StyleSheet.create({
  button: {
    margin: 2,
    paddingHorizontal: 20,
    justifyContent: "center",
    alignItems: "center",
    height: 48,
  },
});

export default connect(null, { setAccessToken })(Welcome);
