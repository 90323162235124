import React from "react";
import { StyleSheet } from "react-native";

/**ui */
import {
  Text,
  Divider,
  Layout,
  List,
  ListItem,
  TopNavigation,
  Icon,
  TopNavigationAction,
} from "@ui-kitten/components";

/**libs */
import { SafeAreaView } from "react-native-safe-area-context";
import { useLiveQuery } from "dexie-react-hooks";

/**redux */
import { connect } from "react-redux";

/**local */
import { getTags } from "../data/db";
import { BackIcon, HashIcon, MenuIcon } from "../components/Icons";

export const Tags = (props) => {
  /**destructure */
  const { navigation } = props;
  console.log("🌟[Tags] Loading..");

  /**custom-hooks */
  const tags = useLiveQuery(() => getTags(), []);

  /**components/renderers */
  const BackAction = () => (
    <TopNavigationAction icon={MenuIcon} onPress={navigation.toggleDrawer} />
  );

  const renderIcon = (props) => {
    const { item } = props;

    if (item?.tag == "thoughts")
      return <Icon {...props} name="flash-outline" />;

    if (item?.tag == "ideas") return <Icon {...props} name="bulb-outline" />;
    if (item?.tag == "people") return <Icon {...props} name="people-outline" />;
    if (item?.tag == "dreams") return <Icon {...props} name="moon-outline" />;

    if (item?.tag == "quotes")
      return <Icon {...props} name="message-square-outline" />;

    if (item?.tag == "facts") return <Icon {...props} name="layers-outline" />;
    if (item?.tag == "wisdom") return <Icon {...props} name="sun-outline" />;
    // Default Icon
    return <Icon {...props} name="hash-outline" />;
  };

  const renderItem = ({ item, index }) => (
    <ListItem
      title={`${item?.tag}`}
      //description={`${item.journals?.length}`}
      accessoryLeft={(props) => renderIcon({ ...props, item })}
      accessoryRight={() => <Text category="s1">{item.journals?.length}</Text>}
      onPress={() => navigation.navigate("Journals", { tagName: item?.tag })}
    />
  );

  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <TopNavigation
          appearance="default"
          title={() => (
            <Text
              category="h4"
              style={{
                //paddingTop: 30,
                //paddingLeft: 6,
                fontWeight: "700",
                fontSize: 32,
              }}
            >{`Tags`}</Text>
          )}
          accessoryLeft={BackAction}
        />
        <Divider />
        <List
          style={styles.container}
          data={tags}
          ItemSeparatorComponent={Divider}
          renderItem={renderItem}
        />
      </SafeAreaView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default Tags;
