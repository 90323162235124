import React, { useEffect, useCallback } from "react";
import { SafeAreaView, View } from "react-native";

//<!-- redux-->
import { toggleFavorites } from "./../redux/actions/dataAction";

//<!--libs-->
import { useLiveQuery } from "dexie-react-hooks";
import { Layout, Divider } from "@ui-kitten/components";
import { useNavigation } from "@react-navigation/native";

//<!--local-->
import useIsMounted from "./../hooks/useIsMounted";
import EmptyView from "./../components/EmptyView";
import JournalItem from "./../components/JournalItem";
import ScreenHeader from "../components/ScreenHeader";
import Enumerations from "./../constants/Enumerations";
//import { VirtualizedList } from "react-native-web";
import { FlashList } from "@shopify/flash-list";
import { getFavoriteEntries } from "../data/db";
import globalStyles from "./../skin/global-styles.css";
import ErrorBoundary from "../components/ErrorBoundary";

const extractKey = ({ guid }) => guid;

//<!--FAVORITES-->
const Favorites = ({ navigation }) => {
  /**custom-hooks */
  const favorites = useLiveQuery(() => getFavoriteEntries(), []);
  const nav = useNavigation();
  const isMounted = useIsMounted();

  if (!isMounted) return null;

  /**hooks */
  useEffect(() => {
    console.log("🌟[Favorites] Loading...");

    return () => {
      console.log("Favorites: Unloading...");
    };
  }, []);

  /**event-handlers */
  const navigateToDetails = (item) => {
    navigation.navigate("Journal-Detail", { journal: item });
  };

  /*components */
  const _renderItem = useCallback(
    ({ item }) => (
      <JournalItem
        key={item.guid}
        item={item}
        index={item.guid}
        toggleFavorites={toggleFavorites}
        navigateToDetails={navigateToDetails}
      />
    ),
    [favorites]
  );

  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <ScreenHeader title="Favorites" />
        <ErrorBoundary>
        <Divider />
        {favorites?.length > 0 ? (
          <View style={{ flex: 1 }}>
            <FlashList
              estimatedItemSize={82}
              data={favorites}
              renderItem={_renderItem}
              ItemSeparatorComponent={Divider}
              contentContainerStyle={{
                paddingLeft: 3,
                paddingRight: 5,
                paddingVertical: 3,
              }}
            />
          </View>
        ) : (
          <EmptyView
            information={`No favorite journals are found..`}
            icon={Enumerations.Favorites}
          />
        )}
        </ErrorBoundary>
      </SafeAreaView>
    </Layout>
  );
};

export default Favorites;
