import React, { useState, useEffect, useMemo } from "react";
import {
  View,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  Dimensions,
} from "react-native";
import { FlatList } from "react-native-web";

//<!--expo-->

//<!--libs-->
import {
  TopNavigation,
  Divider,
  Layout,
  Text,
  Calendar,
} from "@ui-kitten/components";
import { useLiveQuery } from "dexie-react-hooks";
import { useIsFocused } from "@react-navigation/native";
import "react-calendar-heatmap/dist/styles.css";
import isEmpty from "lodash.isempty";
import every from "lodash.every";
//import CalendarHeatmap from "react-calendar-heatmap";

//<!--redux-->
import { connect } from "react-redux";
import { toggleFavorites, deleteJournal } from "./../redux/actions/dataAction";

//<!--local-->
import { getJournalEntryDates } from "./../data/db";
import JournalEntry from "./../models/journalEntry";
import useIsMounted from "../hooks/useIsMounted";
import JournalList from "../components/JournalList";
import JournalItem from "./../components/JournalItem";
import ScreenHeader from "../components/ScreenHeader";
import Fab from "./../components/Fab";
import { dateToTicks } from "./../utils/dateUtils";
import Colors from "./../constants/Colors";
import ErrorBoundary from "../components/ErrorBoundary";

//<!--globals-->
const CalendarHeatmap = React.lazy(() => import("react-calendar-heatmap"));
const extractKey = ({ guid }) => guid;
const today = new Date();
const screenWidth = Dimensions.get("window").width;

//<!--CALENDAR-->
const CalendarScreen = (props) => {
  //destructure props
  const { navigation, toggleFavorites, deleteJournal, journals } = props;

  /**states */
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [datedJournals, setDatedJournals] = useState([]);
  //const [journalMap, setJournalMap] = useState(new Map());

  /**custom-hooks */
  const isFocused = useIsFocused();
  const isMounted = useIsMounted();
  const journalDates = useLiveQuery(() => getJournalEntryDates(), []);

  if (!isMounted) {
    return null;
  }

  /**hooks */
  useEffect(() => {
    console.log(`📆[Calendar] Loading...`);
  }, []);

  const journalMap = useMemo(() => {
    console.log(`🪝[calendar] Journal hook..`);

    const selectedDateJournals =
      journalDates?.[new Date(selectedDate).setHours(0, 0, 0, 0)];

    if (selectedDateJournals?.length > 0) {
      setDatedJournals(selectedDateJournals);
    } else {
      setDatedJournals([]);
    }

    return journalDates;
  }, [journalDates]);

  useEffect(() => {
    if (!isEmpty(journalMap)) {
      //check for journals on selectedDate
      let selectedDateJournals =
        journalMap[new Date(selectedDate).setHours(0, 0, 0, 0)];
      if (selectedDateJournals?.length > 0) {
        setDatedJournals(selectedDateJournals);
      } else setDatedJournals([]);
    }
  }, [selectedDate]);

  let dateCounts = {};
  if (journalDates) {
    dateCounts = Object.entries(journalDates).reduce(
      (counts, [date, journals]) => {
        if (date in counts) counts[date] += journals.length;
        else counts[date] = journals.length;
        return counts;
      },
      {}
    );
    //console.log("[calendar] dateCounts=>", JSON.stringify(dateCounts));
  }

  const dateValues = useMemo(
    () =>
      Object.entries(dateCounts).map(([date, count]) => ({
        date: new Date(parseInt(date)).toISOString().substr(0, 10),
        count,
      })),
    [journalDates]
  );
  //console.log("[calendar] dateValues=>", JSON.stringify(dateValues));

  function shiftDate(date, numDays) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + numDays);
    return newDate;
  }

  //<!--event-handlers-->
  const navigateToDetails = (item) => {
    navigation.navigate("Journal-Detail", { journal: item });
  };

  const createNewEntry = () => {
    let journalEntry = new JournalEntry();
    journalEntry.entryCreatedDate = dateToTicks(selectedDate);

    navigation.navigate("Journal-Detail", {
      journal: journalEntry,
      isNewEntry: true,
    });
  };

  //<!--function-components-->
  const renderDayCell = ({ date }, style) => {
    let journalEntries = journalMap?.[date.setHours(0, 0, 0, 0)];
    let isFavorite;
    if (journalEntries) {
      //console.log(`JOURNAL ENTRY FOUND=>${JSON.stringify(journalEntries)}`);
      isFavorite = every(journalEntries, ["isFavorite", true]);
      //console.log(isFavorite);
    }

    return journalEntries ? (
      <View style={[styles.dayContainer, style.container]}>
        <Text style={style.text}>{`${date.getDate()}`}</Text>
        {isFavorite ? (
          <View
            style={{
              width: 10,
              height: 10,
              borderRadius: 5,
              backgroundColor: Colors.favorite,
            }}
          ></View>
        ) : (
          <View
            style={{
              width: 10,
              height: 10,
              borderRadius: 5,
              backgroundColor: Colors.appGreen,
            }}
          ></View>
        )}
      </View>
    ) : (
      <View style={[styles.dayContainer, style.container]}>
        <Text style={style.text}>{`${date.getDate()}`}</Text>
      </View>
    );
  };

  const CalendarComponent = () => {
    const now = new Date();
    const past = new Date(
      now.getFullYear() - 5,
      now.getMonth(),
      now.getDate() - 1
    );
    const future = new Date(
      now.getFullYear() + 5,
      now.getMonth(),
      now.getDate() + 1
    );

    const _onSelectingDate = (nextDate) => {
      setSelectedDate(nextDate);
    };

    return (
      <View
        style={{
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: 8,
          marginBottom: 3,
        }}
      >
        <Calendar
          date={selectedDate}
          max={future}
          min={past}
          renderDay={renderDayCell}
          onSelect={(nextDate) => _onSelectingDate(nextDate)}
        />
      </View>
    );
  };

  const _renderItem = ({ item }) => (
    <JournalItem
      item={item}
      index={item.guid}
      toggleFavorites={toggleFavorites}
      deleteJournalItem={deleteJournal}
      navigateToDetails={navigateToDetails}
    />
  );

  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <ErrorBoundary>
        <ScreenHeader title={"Calendar"} />
        <Divider />
        <Layout
          style={{
            marginVertical: 3,
            flex: 1,
          }}
        >
          <ScrollView style={{ flex: 1 }}>
            {/* HeatMap */}
            <View
              style={{
                maxHeight: 150,
                paddingHorizontal: 12,
                paddingTop: 12,
              }}
            >
              <React.Suspense fallback={<Text>Loading...</Text>}>
                <CalendarHeatmap
                  id="heatmap"
                  startDate={shiftDate(today, -182)}
                  endDate={today}
                  values={dateValues}
                  classForValue={(value) => {
                    if (!value) {
                      return "color-empty";
                    }
                    return `color-github-${value.count}`;
                  }}
                  tooltipDataAttrs={(value) => {
                    return {
                      id: `tooltip-anchor`,
                      "data-tooltip-content": `${value.date} has count: ${value.count}`,
                    };
                  }}
                  //showWeekdayLabels={true}
                />
              </React.Suspense>
            </View>

            {/* Calendar */}
            <CalendarComponent />

            <Divider />

            {/* Journal List */}
            <Layout level="2" style={{ height: "100%" }}>
              <JournalList
                journals={datedJournals}
                toggleFavorites={toggleFavorites}
                deleteJournal={deleteJournal}
                navigateToDetails={navigateToDetails}
              />
            </Layout>
          </ScrollView>
        </Layout>
        <Fab createNewEntry={createNewEntry} />
        </ErrorBoundary>
      </SafeAreaView>
    </Layout>
  );
};

//<!--Styles-->
const styles = StyleSheet.create({
  container: {
    maxHeight: 200,
  },
  dayContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    aspectRatio: 1,
  },
  value: {
    fontSize: 12,
    fontWeight: "400",
  },

  entryContainer: {
    borderRadius: 4,
    paddingHorizontal: 2,
    paddingTop: 9,
  },
});

export default CalendarScreen;
