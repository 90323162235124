// source: protoc-3.11.4-osx-x86_64/bin/entry.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

goog.exportSymbol("proto.Entry", null, global);
goog.exportSymbol("proto.Tag", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Entry = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Entry.repeatedFields_,
    null
  );
};
goog.inherits(proto.Entry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Entry.displayName = "proto.Entry";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Tag = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Tag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Tag.displayName = "proto.Tag";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Entry.repeatedFields_ = [10];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Entry.prototype.toObject = function (opt_includeInstance) {
    return proto.Entry.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Entry} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Entry.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        guid: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        entrycreateddate: jspb.Message.getFieldWithDefault(msg, 2, 0),
        entrymodifieddate: jspb.Message.getFieldWithDefault(msg, 3, 0),
        title: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
        richcontent:
          (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
        clientrevision: jspb.Message.getFieldWithDefault(msg, 6, 0),
        imagedata: msg.getImagedata_asB64(),
        clientrev: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
        isfavorite: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
        tagsList: jspb.Message.toObjectList(
          msg.getTagsList(),
          proto.Tag.toObject,
          includeInstance
        ),
        latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
        longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
        celcius: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
        fahrenheit: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
        relativehumidity: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          15,
          0.0
        ),
        windspeedinkph: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          16,
          0.0
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Entry}
 */
proto.Entry.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Entry();
  return proto.Entry.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Entry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Entry}
 */
proto.Entry.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setGuid(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setEntrycreateddate(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setEntrymodifieddate(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setTitle(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setRichcontent(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setClientrevision(value);
        break;
      case 7:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setImagedata(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setClientrev(value);
        break;
      case 9:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsfavorite(value);
        break;
      case 10:
        var value = new proto.Tag();
        reader.readMessage(value, proto.Tag.deserializeBinaryFromReader);
        msg.addTags(value);
        break;
      case 11:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setLatitude(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setLongitude(value);
        break;
      case 13:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setCelcius(value);
        break;
      case 14:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setFahrenheit(value);
        break;
      case 15:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setRelativehumidity(value);
        break;
      case 16:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setWindspeedinkph(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Entry.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Entry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Entry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Entry.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(2, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(3, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(4, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(5, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(6, f);
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBytes(7, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(8, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(9, f);
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(10, f, proto.Tag.serializeBinaryToWriter);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeDouble(11, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeDouble(12, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeFloat(13, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeFloat(14, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeFloat(15, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeFloat(16, f);
  }
};

/**
 * optional string Guid = 1;
 * @return {string}
 */
proto.Entry.prototype.getGuid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setGuid = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearGuid = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasGuid = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional int64 EntryCreatedDate = 2;
 * @return {number}
 */
proto.Entry.prototype.getEntrycreateddate = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setEntrycreateddate = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearEntrycreateddate = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasEntrycreateddate = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional int64 EntryModifiedDate = 3;
 * @return {number}
 */
proto.Entry.prototype.getEntrymodifieddate = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setEntrymodifieddate = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearEntrymodifieddate = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasEntrymodifieddate = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional string Title = 4;
 * @return {string}
 */
proto.Entry.prototype.getTitle = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setTitle = function (value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearTitle = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasTitle = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string RichContent = 5;
 * @return {string}
 */
proto.Entry.prototype.getRichcontent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setRichcontent = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearRichcontent = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasRichcontent = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional int32 ClientRevision = 6;
 * @return {number}
 */
proto.Entry.prototype.getClientrevision = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setClientrevision = function (value) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearClientrevision = function () {
  return jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasClientrevision = function () {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * optional bytes ImageData = 7;
 * @return {!(string|Uint8Array)}
 */
proto.Entry.prototype.getImagedata = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(
    this,
    7,
    ""
  ));
};

/**
 * optional bytes ImageData = 7;
 * This is a type-conversion wrapper around `getImagedata()`
 * @return {string}
 */
proto.Entry.prototype.getImagedata_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getImagedata()));
};

/**
 * optional bytes ImageData = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImagedata()`
 * @return {!Uint8Array}
 */
proto.Entry.prototype.getImagedata_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
    this.getImagedata()
  ));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setImagedata = function (value) {
  return jspb.Message.setField(this, 7, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearImagedata = function () {
  return jspb.Message.setField(this, 7, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasImagedata = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional string ClientRev = 8;
 * @return {string}
 */
proto.Entry.prototype.getClientrev = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setClientrev = function (value) {
  return jspb.Message.setField(this, 8, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearClientrev = function () {
  return jspb.Message.setField(this, 8, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasClientrev = function () {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * optional bool IsFavorite = 9;
 * @return {boolean}
 */
proto.Entry.prototype.getIsfavorite = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    9,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setIsfavorite = function (value) {
  return jspb.Message.setField(this, 9, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearIsfavorite = function () {
  return jspb.Message.setField(this, 9, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasIsfavorite = function () {
  return jspb.Message.getField(this, 9) != null;
};

/**
 * repeated Tag Tags = 10;
 * @return {!Array<!proto.Tag>}
 */
proto.Entry.prototype.getTagsList = function () {
  return /** @type{!Array<!proto.Tag>} */ (jspb.Message.getRepeatedWrapperField(
    this,
    proto.Tag,
    10
  ));
};

/**
 * @param {!Array<!proto.Tag>} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setTagsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};

/**
 * @param {!proto.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Tag}
 */
proto.Entry.prototype.addTags = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    10,
    opt_value,
    proto.Tag,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearTagsList = function () {
  return this.setTagsList([]);
};

/**
 * optional double Latitude = 11;
 * @return {number}
 */
proto.Entry.prototype.getLatitude = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    11,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setLatitude = function (value) {
  return jspb.Message.setField(this, 11, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearLatitude = function () {
  return jspb.Message.setField(this, 11, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasLatitude = function () {
  return jspb.Message.getField(this, 11) != null;
};

/**
 * optional double Longitude = 12;
 * @return {number}
 */
proto.Entry.prototype.getLongitude = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    12,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setLongitude = function (value) {
  return jspb.Message.setField(this, 12, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearLongitude = function () {
  return jspb.Message.setField(this, 12, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasLongitude = function () {
  return jspb.Message.getField(this, 12) != null;
};

/**
 * optional float _celcius = 13;
 * @return {number}
 */
proto.Entry.prototype.getCelcius = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    13,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setCelcius = function (value) {
  return jspb.Message.setField(this, 13, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearCelcius = function () {
  return jspb.Message.setField(this, 13, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasCelcius = function () {
  return jspb.Message.getField(this, 13) != null;
};

/**
 * optional float _fahrenheit = 14;
 * @return {number}
 */
proto.Entry.prototype.getFahrenheit = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    14,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setFahrenheit = function (value) {
  return jspb.Message.setField(this, 14, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearFahrenheit = function () {
  return jspb.Message.setField(this, 14, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasFahrenheit = function () {
  return jspb.Message.getField(this, 14) != null;
};

/**
 * optional float _relativeHumidity = 15;
 * @return {number}
 */
proto.Entry.prototype.getRelativehumidity = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    15,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setRelativehumidity = function (value) {
  return jspb.Message.setField(this, 15, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearRelativehumidity = function () {
  return jspb.Message.setField(this, 15, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasRelativehumidity = function () {
  return jspb.Message.getField(this, 15) != null;
};

/**
 * optional float _windSpeedInKph = 16;
 * @return {number}
 */
proto.Entry.prototype.getWindspeedinkph = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    16,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setWindspeedinkph = function (value) {
  return jspb.Message.setField(this, 16, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.clearWindspeedinkph = function () {
  return jspb.Message.setField(this, 16, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Entry.prototype.hasWindspeedinkph = function () {
  return jspb.Message.getField(this, 16) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Tag.prototype.toObject = function (opt_includeInstance) {
    return proto.Tag.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Tag} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Tag.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Tag}
 */
proto.Tag.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Tag();
  return proto.Tag.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Tag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Tag}
 */
proto.Tag.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Tag.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Tag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Tag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Tag.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(1, f);
  }
};

/**
 * optional string Name = 1;
 * @return {string}
 */
proto.Tag.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.Tag} returns this
 */
proto.Tag.prototype.setName = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Tag} returns this
 */
proto.Tag.prototype.clearName = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Tag.prototype.hasName = function () {
  return jspb.Message.getField(this, 1) != null;
};

goog.object.extend(exports, proto);
