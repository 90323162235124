import React, { useEffect, useRef } from "react";
import { SafeAreaView } from "react-native-web";
import { Layout } from "@ui-kitten/components";
import MapView from "../components/MapView";

const Scrap = () => {
  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <Layout style={{ height: 200 }}>
          <MapView />
        </Layout>
      </SafeAreaView>
    </Layout>
  );
};

export default Scrap;
