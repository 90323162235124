import React from "react";
import { View, Text, TouchableOpacity } from "react-native";

//<!--custom -->
import { AddIcon } from "./../components/Icons";

const Fab = ({ createNewEntry }) => {
  return (
    <TouchableOpacity
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        width: 56,
        height: 56,
        position: "absolute",
        bottom: 32,
        right: 18,
        borderRadius: 28,
        //backgroundColor: "#90CDF4",
        backgroundColor: "#3366FF",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        // borderWidth: 1,
        // borderColor: "red",
      }}
      onPress={() => createNewEntry()}
    >
      <AddIcon />
    </TouchableOpacity>
  );
};

export default React.memo(Fab);
