import React from "react";

//<!--lib-->
import { Layout, Text, Divider } from "@ui-kitten/components";

const Profile = ({ Hint, Value }) => {
  return (
    <>
      <Layout
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 16,
        }}
      >
        <Text appearance="hint" category="s1">
          {Hint}
        </Text>
        <Text category="s1">{Value} </Text>
      </Layout>
      <Divider />
    </>
  );
};

export default Profile;
