import Dexie from "dexie";
import groupBy from "lodash.groupby";
import isEmpty from "lodash.isempty";

export const db = new Dexie("glimpses-store", {});
db.version(2).stores({
  journalEntries:
    "++id, guid, entryCreatedDate, entryModifiedDate, title,isFavorite,imageData,imageThumbnail,*tagsList",
  deletedJournalEntries: "guid, entryCreatedDate",
  articleEntries: "guid, clientModified",
});

export function resetDatabase() {
  return db.transaction(
    "rw",
    db.journalEntries,
    db.deletedJournalEntries,
    async () => {
      await Promise.all(db.tables.map((table) => table.clear()));
    }
  );
}

//*******************JOURNALS***************************/
export async function getJournalEntries() {
  return await db.journalEntries
    .orderBy("entryCreatedDate")
    .reverse()
    .toArray();
}

export async function getJournalEntry(guid) {
  return await db.journalEntries.where({ guid }).first();
}

export async function addJournalEntry(journalEntry) {
  console.log("🧊[addJournalEntry]->");
  return await db.journalEntries.add(journalEntry);
}

export async function updateJournalEntry(journalEntry) {
  const id = journalEntry.id ? journalEntry.id : journalEntry.guid;
  console.log("🧊[updateJournalEntry]->", id);
  //const result = await db.journalEntries.update(id, journalEntry);
  const result = await db.journalEntries
    .where("guid")
    .equals(journalEntry.guid)
    .modify(journalEntry);

  console.log("🧊[updateJournalEntry][result]->", result);
}

export async function upsertJournalEntry(journalEntry) {
  console.log("🧊[upsertJournalEntry]->");
  const existing = await db.journalEntries
    .where("guid")
    .equals(journalEntry.guid)
    .toArray();
  if (existing.length === 0) {
    console.log("🧊[upsertJournalEntry][add]");
    return await db.journalEntries.add(journalEntry, journalEntry.guid);
  } else {
    console.log("🧊[upsertJournalEntry][update]");
    //return await db.journalEntries.put(journalEntry, existing[0].id);
    return await updateJournalEntry(journalEntry);
  }
}

//*******************DELETED-JOURNALS********************/
export async function getDeletedJournalEntries() {
  return await db.deletedJournalEntries.toArray();
}

export async function deleteJournalEntry(guid) {
  console.log("🧊[deleteJournalEntry]->", guid);
  try {
    const entryToBeDeleted = await db.journalEntries.where({ guid }).first();
    console.log("🧊[deleteJournalEntry][entryToBeDeleted]->", entryToBeDeleted);

    if (!entryToBeDeleted) return;

    await db.transaction(
      "rw",
      [db.journalEntries, db.deletedJournalEntries],
      async () => {
        await db.deletedJournalEntries.put(entryToBeDeleted);
        await db.journalEntries.where({ guid }).delete();
      }
    );
  } catch (error) {
    console.log("🧊❎[deleteJournalEntry][ERR]->", error);
  }
}

export async function deleteJournalEntries(guids) {
  console.log("🧊[deleteJournalEntries]->", guids);
  //bulk add to deletedJournalEntries
  const deletedJournalEntries = await db.journalEntries
    .where("guid")
    .anyOf(guids)
    .toArray();
  //await db.journalEntries.bulkGet(guids);

  //delete in bulk from journalEntries
  //await db.journalEntries.bulkDelete(guids);
  await db.journalEntries.where("guid").anyOf(guids).delete();

  if (deletedJournalEntries) {
    await db.deletedJournalEntries.bulkAdd(deletedJournalEntries);
  }
}

export async function purgeJournalEntry(guid) {
  console.log("🧊[purgeJournalEntry]->", guid);
  await db.deletedJournalEntries.delete(guid);
}

export async function purgeJournalEntries() {
  await db.deletedJournalEntries.clear();
}

//***************************QUERY*****************************/

export async function getPaginatedJournalEntries(offset, limit) {
  console.log(
    `🧊[getPaginatedJournalEntries]-> offset:${offset}, limit:${limit}`
  );
  const journalEntries = await db.journalEntries
    .orderBy("entryCreatedDate")
    .reverse()
    .offset(offset)
    .limit(limit)
    .toArray();

  //console.log("getPaginatedJournalEntries", journalEntries);
  return journalEntries;
}

export async function isJournalEntryExists(guid) {
  return (await db.journalEntries.where({ guid }).count()) > 0;
}

export async function getPhotoEntries() {
  console.log("🧊[getPhotoEntries]->");
  const photoEntries = await db.journalEntries
    .orderBy("entryCreatedDate")
    .reverse()
    .filter((entry) => !isEmpty(entry.imageThumbnail))
    .toArray();

  return photoEntries;
}

export async function getFavoriteEntries() {
  //get favorite entries from db ordered by entryCreatedDate in descending order
  const favoriteEntries = await db.journalEntries
    .orderBy("entryCreatedDate")
    .reverse()
    .filter((entry) => entry.isFavorite === true)
    .toArray();
  return favoriteEntries;
}

export async function getJournalEntryDates() {
  const journalEntries = await db.journalEntries.toArray();
  const journalEntryDates = journalEntries.map((p) => {
    return {
      ...p,
      //key: ticksToDate(Number(p.entryCreatedDate)).date.setHours(0, 0, 0, 0),
      key: new Date(
        Number(p.entryCreatedDate) / 1e4 +
          new Date("0001-01-01T00:00:00Z").getTime()
      ).setHours(0, 0, 0, 0),
    };
  });

  //group by key and add count property
  let groupedJournalEntryDates = groupBy(journalEntryDates, "key");
  //console.log("🧊groupedJournalEntryDates", groupedJournalEntryDates);
  return groupedJournalEntryDates;
}

export async function getJournalEntriesByTagName(tagName) {
  return await db.journalEntries
    .orderBy("entryCreatedDate")
    .reverse()
    .filter((entry) => entry.tagsList.some((tag) => tag.name === tagName))
    .toArray();
}

export async function getTags() {
  const journals = await db.journalEntries.toArray();

  const customTags = [
    { name: "ideas" },
    { name: "thoughts" },
    { name: "people" },
    { name: "dreams" },
    { name: "quotes" },
    { name: "facts" },
    { name: "wisdom" },
  ];

  // Extract the names of custom tags
  const customTagNames = customTags.map((tag) => tag.name);

  // combine custom tags and tags from journals
  const allTags = [...customTags, ...journals.flatMap((j) => j.tagsList)];

  // filter out custom tags from allTags
  const allTagsWithoutCustom = allTags.filter(
    (tag) => !customTagNames.includes(tag.name)
  );

  // get unique tags
  const uniqueTags = [...new Set(allTagsWithoutCustom.map((t) => t?.name))];

  // group journals by tag
  const tagsGrouped = uniqueTags?.map((tag) => {
    const journalsWithTag = journals?.filter((journal) =>
      journal?.tagsList.map((t) => t?.name).includes(tag)
    );
    return { tag, journals: journalsWithTag };
  });

  // Sort based on the count of journals
  const sortedTagsGrouped = tagsGrouped?.sort(
    (a, b) => b.journals?.length - a.journals?.length
  );

  // sort customTags
  const customTagGrouped = customTags.map((tag) => {
    const journalsWithTag = journals.filter((journal) =>
      journal.tagsList?.map((t) => t?.name).includes(tag?.name)
    );
    return { tag: tag.name, journals: journalsWithTag };
  });
  return [...customTagGrouped, ...sortedTagsGrouped];
}
//*******************ARTICLES********************/
export async function getArticleEntries() {
  const articleEntries = await db.articleEntries
    .orderBy("clientModified")
    .reverse()
    .filter((article) => !isEmpty(article.url))
    .toArray();

  console.log("🧊[getArticleEntries]->", articleEntries);

  return articleEntries;
}

export async function saveArticleEntry(article) {
  console.log("🧊[saveArticleEntry]->", article);
  await db.articleEntries.put({
    guid: article.name.split(".")[0],
    name: article.name,
    url: article.url,
    clientModified: article.clientModified,
  });
}

export async function saveArticleEntries(articleEntries) {
  console.log("🧊[saveArticleEntries]->", articleEntries);

  const articles = articleEntries.map((article) => {
    return {
      guid: article.name.split(".")[0],
      name: article.name,
      url: article.url,
      clientModified: article.clientModified,
    };
  });

  await db.articleEntries.bulkPut(articles);
}

export async function deleteArticleEntry(guid) {
  console.log("🧊[deleteArticleEntry]->", guid);
  await db.articleEntries.delete(guid);
}

//*************************COUNT*******************************/
export async function getJournalEntriesCount() {
  return await db.journalEntries.count();
}

export async function getDeletedJournalEntriesCount() {
  return await db.deletedJournalEntries.count();
}

export async function getPhotoEntriesCount() {
  //return await db.journalEntries.where("imageThumbnail").notEqual("").count();
  return await db.journalEntries
    .filter((entry) => !isEmpty(entry.imageThumbnail))
    .count();
}

export async function getFavoriteEntriesCount() {
  const favoriteEntries = await db.journalEntries
    .filter((entry) => entry.isFavorite === true)
    .toArray();
  return favoriteEntries?.length;
}
