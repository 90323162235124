import Constants from "expo-constants";
//<!--expo-->
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";

export const openImageLibrary = async () => {
  const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
  //await Permissions.askAsync(Permissions.CAMERA_ROLL);
  if (status !== "granted") {
    alert(
      "No Camera roll permissions!\n  please toggle on 'Camera roll' in Settings."
    );
    return false;
  } else {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    return result.cancelled ? false : result;
  }
};
