const Colors = {
  bgSuccess: "#68D391",
  bgDanger: "#F56565",
  favorite: "#FFD301",
  altFavorite: "#FFC044",
  hintColor: "#8F9BB3",
  accentColor: "#9bdcfc",
  appBlack: "#383838",
  appWhite: "#ffffff",
  appGreen: "#4ab866",
  journalText: "#333333",
  accessoryView: "#EDF2F7",
  profileBackground: "#C5CEE0",
  listIcon: "#8F9BB3",
  //listIcon: "#4299E1",
  activeTint: "#000000",
  inactiveTint: "#",
  appIcon: "#202020",
};
export default Colors;
