import React from "react";
import { Text } from "@ui-kitten/components";

const JournalTitle = ({ title }) => (
  <Text
    numberOfLines={1}
    style={{
      fontSize: 18,
      fontWeight: "700",
      paddingHorizontal: 8,
    }}
  >{`${title}`}</Text>
);
export default React.memo(JournalTitle);
