import React, { useState, useEffect } from "react";
import { View, SafeAreaView, StyleSheet } from "react-native";

/**ui */
import {
  Layout,
  Text,
  Button,
  Input,
  Divider,
  TopNavigation,
  TopNavigationAction,
  Toggle,
} from "@ui-kitten/components";

/**libs */
import { KeyboardAvoidingView } from "react-native-web";

/**redux */
import { useDispatch, useSelector } from "react-redux";
import { setPin } from "./../redux/actions/dispatchers";

import { CloseIcon2, LockIcon, ShieldIcon } from "../components/Icons";
import isEmpty from "lodash.isempty";
import { generateHash } from "../utils/customUtils";
import ErrorBoundary from "../components/ErrorBoundary";

//<!--SECURITY-->
const Security = ({ navigation }) => {
  /**states */
  const [number, setNumber] = useState("");
  const [repeatNumber, setRepeatNumber] = useState("");
  //const [isPinEnabled, setIsPinEnabled] = useState(false);

  /**custom-hooks */
  const dispatch = useDispatch();

  /**selectors */
  const account = useSelector((state) => state.dataStore?.account);
  const securePinEnabled = useSelector(
    (state) => !isEmpty(state.dataStore?.pin)
  );

  /**hooks */

  /**events */
  const navigateBack = () => {
    navigation.goBack();
  };

  const onSavePin = () => {
    if (isEmpty(number)) return alert("🔒Please enter a PIN");

    if (number !== repeatNumber) {
      alert("🔒PINs do not match");
      return;
    }

    const hash = generateHash(number);
    dispatch(setPin(hash));
  };

  const onPinStatusChange = (checked) => {
    if (!checked) {
      dispatch(setPin(""));
    }
  };

  /**function-components */
  const BackAction = () => (
    <TopNavigationAction icon={CloseIcon2} onPress={navigateBack} />
  );

  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <TopNavigation
          alignment="center"
          accessoryLeft={BackAction}
          title="Security"
        />
        <ErrorBoundary>
        <Divider />
        <Layout style={styles.form} level="1">
          <View
            style={{
              height: 75,
              width: 75,
              borderRadius: 37.5,
              backgroundColor: "#C6F6D5",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ShieldIcon fill={"#38A169"} />
          </View>

          {/* title, description */}
          <View
            style={{
              paddingVertical: 12,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text category="s1">{`PIN`}</Text>
            <Text
              style={{
                textAlign: "center",
                paddingHorizontal: 12,
              }}
              appearance="hint"
            >{`Protect your journal's privacy by setting a PIN to keep out unwanted viewers`}</Text>
          </View>

          <Toggle
            checked={securePinEnabled}
            onChange={onPinStatusChange}
          ></Toggle>
          <KeyboardAvoidingView>
            <Input
              disabled={securePinEnabled}
              style={styles.input}
              label="New PIN"
              placeholder="PIN"
              keyboardType="numeric"
              maxLength={4}
              value={number}
              onChangeText={setNumber}
              secureTextEntry={true}
              accessoryLeft={LockIcon}
              tabindex="0"
            />
            <Input
              disabled={securePinEnabled}
              style={styles.input}
              label="Repeat PIN"
              placeholder="Confirm  PIN"
              keyboardType="numeric"
              maxLength={4}
              value={repeatNumber}
              onChangeText={setRepeatNumber}
              secureTextEntry={true}
              accessoryLeft={LockIcon}
              tabindex="1"
            />
            <Button
              disabled={securePinEnabled}
              style={styles.addButton}
              //size="giant"
              status="primary"
              onPress={onSavePin}
            >
              SAVE
            </Button>
          </KeyboardAvoidingView>
        </Layout>
        </ErrorBoundary>
      </SafeAreaView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "background-basic-color-2",
  },
  form: {
    flex: 1,
    paddingHorizontal: 4,
    paddingVertical: 24,
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    marginHorizontal: 12,
    marginVertical: 8,
  },
  middleContainer: {
    flexDirection: "row",
  },
  middleInput: {
    width: 128,
  },
  addButton: {
    marginHorizontal: 16,
    marginVertical: 24,
  },
});

export default React.memo(Security);
