import React, { useState, useEffect, useContext } from "react";
import {
  View,
  StyleSheet,
  Alert,
  Linking,
  Platform,
  SafeAreaView,
} from "react-native";

//<!--expo-->
import { Ionicons } from "@expo/vector-icons";

//<!--libs-->
import {
  List,
  ListItem,
  Divider,
  Layout,
  Text,
  Icon,
  Toggle,
  Button,
  Modal,
  Card,
} from "@ui-kitten/components";
import { useLiveQuery } from "dexie-react-hooks";

//<!-- redux-->
import { connect } from "react-redux";
import {
  logout,
  toggleSelectedTheme,
  toggleRetroMode,
} from "./../redux/actions/dispatchers";

//<!--local-->
import useIsMounted from "../hooks/useIsMounted";
import { ThemeContext } from "../skin/theme-context";
import ScreenHeader from "../components/ScreenHeader";
import Colors from "./../constants/Colors";
import { MenuItems } from "../constants";
import { useIsFocused } from "@react-navigation/native";
import {
  getFavoriteEntriesCount,
  getJournalEntriesCount,
  getPhotoEntriesCount,
  resetDatabase,
} from "../data/db";
import { ScrollView } from "react-native-gesture-handler";
import ErrorBoundary from "../components/ErrorBoundary";

//<!--SETTINGS-->
const Settings = React.memo((props) => {
  /**destructure */
  const {
    navigation,
    selectedTheme,
    isRetroMode,
    logout,
    toggleSelectedTheme,
    toggleRetroMode,
  } = props;

  /**states */
  const [hasDarkTheme, setHasDarkTheme] = useState(false);
  const [visible, setVisible] = useState(false);

  //context
  const themeContext = useContext(ThemeContext);

  /**custom-hooks */
  const isMounted = useIsMounted();
  const isFocused = useIsFocused();
  const journalsCount = useLiveQuery(() => getJournalEntriesCount(), []);
  const photosCount = useLiveQuery(() => getPhotoEntriesCount(), []);
  const favoritesCount = useLiveQuery(() => getFavoriteEntriesCount(), []);

  if (!isMounted) return null;

  /**hooks */
  useEffect(() => {
    console.log(`⚙️[Settings] Loading..`);
    if (selectedTheme === "dark") setHasDarkTheme(true);
  }, []);

  /**hooks */

  //functions
  const onThemeChange = () => {
    setHasDarkTheme(!hasDarkTheme);
    themeContext.toggleTheme();

    //redux persist
    toggleSelectedTheme();
  };

  const onToggleRetroMode = () => {
    console.log(`[onToggleRetroMode]`);
    toggleRetroMode();
  };

  //Logo
  const LOGO = () => {
    return (
      <View
        style={{
          backgroundColor: "",
          height: "40%",
          paddingHorizontal: 50,
          justifyContent: "center",
        }}
      >
        <View
          style={{
            //backgroundColor: Colors.accentColor,
            backgroundColor: "#3366FF",
            height: 60,
            width: 60,
            borderRadius: 30,
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <Ionicons name="ios-stats-chart" size={32} color="#4ab866" />
        </View>

        <View style={styles.statistics}>
          <View style={styles.metrics}>
            <Text category="label">JOURNALS</Text>
            <Text category="p1">{journalsCount}</Text>
          </View>

          <View style={styles.metrics}>
            <Text category="label">PHOTOS</Text>
            <Text category="p1">{photosCount}</Text>
          </View>
          <View style={styles.metrics}>
            <Text category="label">FAVORITES</Text>
            <Text category="p1">{favoritesCount}</Text>
          </View>
        </View>
      </View>
    );
  };

  /**events */
  const showAlert = () => {
    setVisible(true);
  };

  const hideAlert = () => {
    setVisible(false);
  };

  const onLogout = () => {
    resetDatabase();
    logout();
  };

  /**renderers */
  const renderItem = ({ item }) => (
    <ListItem
      title={(evaProps) => <Text category="s1">{item.title}</Text>}
      description={(evaProps) => (
        <Text category="s2" appearance="hint">
          {item.description}
        </Text>
      )}
      accessoryLeft={(props) => (
        //circle around icon
        <View
          style={{
            width: 32,
            height: 32,
            borderRadius: "50%",
            backgroundColor: "#dcfce7",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 12,
          }}
        >
          {/*<Icon fill={Colors.hintColor} {...props} name={item.icon} />*/}
          <Icon fill={"#047857"} {...props} name={item.icon} />
        </View>
      )}
      accessoryRight={(props) => {
        if (item.title === "Theme")
          return (
            <Toggle checked={hasDarkTheme} onChange={onThemeChange}></Toggle>
          );

        if (item.title === "Retro")
          return (
            <Toggle checked={isRetroMode} onChange={onToggleRetroMode}></Toggle>
          );

        return (
          <Icon
            {...props}
            fill={Colors.hintColor}
            name="chevron-right-outline"
          />
        );
      }}
      onPress={() => {
        switch (item.title) {
          case "Account":
            navigation.navigate("Account");
            break;
          case "About":
            navigation.navigate(`About`);
            break;
          case "Secure":
            navigation.navigate(`Secure`);
            break;
          case "Contact":
            Linking.openURL(
              `mailto:support@getglimpses.com?subject=Hello there&body=`
            );
            //navigation.navigate(`Scrap`);
            break;
          case "Logout":
            if (Platform.OS === "web") {
              //return logout();
              return showAlert();
            }

            Alert.alert(
              "LOGOUT",
              "Do you really want to clear data?",
              [
                {
                  text: "Cancel",
                  onPress: () => console.log("Cancel Pressed"),
                  style: "cancel",
                },
                {
                  text: "OK",
                  onPress: () => {
                    console.log("OK Pressed");
                    console.log("Logout");
                    logout();
                  },
                },
              ],
              { cancelable: true }
            );

            break;
        }
      }}
    />
  );

  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <ScreenHeader title={`Menu`} />
        <ErrorBoundary>
        <Divider />
        <Layout style={styles.container}>
          <LOGO />
          <Divider />

          <ScrollView>
            <List
              data={MenuItems}
              renderItem={renderItem}
              ItemSeparatorComponent={Divider}
              keyExtractor={(item, index) => index.toString()}
            />

            {/* application-info */}
            <Layout
              level="2"
              style={{
                paddingTop: 20,
                paddingBottom: 80,
              }}
            >
              <Text
                //appearance="hint"
                style={{
                  textAlign: "center",
                  fontSize: 24,
                  fontWeight: "700",
                  letterSpacing: 2,
                  color: "#738299",
                }}
              >
                <span style={{ fontSize: 24, fontWeight: 700 }}>glimpses</span>
              </Text>
              <Text
                appearance="hint"
                style={{
                  textAlign: "center",
                  fontWeight: "300",
                  fontSize: 12,
                  paddingTop: 3,
                }}
              >
                {`Capture your memories\nto remember life..`}
              </Text>
            </Layout>
          </ScrollView>

          {/* Logout Modal Prompt */}
          <Modal
            animationType="slide"
            visible={visible}
            onBackdropPress={hideAlert}
            style={{
              marginTop: 10,
              borderRadius: 12,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            }}
          >
            <Card>
              <Text category="s1">Are you sure you want to log out?</Text>
              <Layout
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  justifyContent: "space-between",
                }}
              >
                <Button
                  appearance="outline"
                  status="basic"
                  size="medium"
                  onPress={hideAlert}
                >
                  Cancel
                </Button>
                <Button
                  appearance="outline"
                  status="danger"
                  onPress={onLogout}
                  size="medium"
                  style={{ marginLeft: 7 }}
                >
                  Logout
                </Button>
              </Layout>
            </Card>
          </Modal>
        </Layout>
        </ErrorBoundary>
      </SafeAreaView>
    </Layout>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: "hidden",
  },
  statistics: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  metrics: {
    alignItems: "center",
  },
});

const mapStateToProps = (state) => ({
  selectedTheme: state.dataStore.selectedTheme,
  isRetroMode: state.dataStore.isRetroMode,
});

export default connect(mapStateToProps, {
  logout,
  toggleSelectedTheme,
  toggleRetroMode,
})(Settings);
