import React, { useState, useEffect } from "react";
import { SafeAreaView, View, ScrollView, Image, Platform } from "react-native";

//<!-- libs-->
import {
  Divider,
  Layout,
  Text,
  TopNavigation,
  TopNavigationAction,
  OverflowMenu,
  MenuItem,
} from "@ui-kitten/components";
import _, { isEmpty } from "lodash";

// <!--redux-->
import { useDispatch } from "react-redux";
import { updateJournal } from "../redux/actions/dataAction";

//<!--local-->
import {
  BackIcon,
  FavIcon,
  UnFavIcon,
  OverflowMenuIcon,
  AltTrashIcon,
  AltUndoIcon,
} from "../components/Icons";
import MarkdownView from "../components/MarkdownView";
import DateContainer from "../components/DateContainer";
import LocationContainer from "../components/LocationContainer";
import useIsMounted from "../hooks/useIsMounted";
import { ticksToDate } from "../utils/dateUtils";
import { purgeJournalEntry } from "../data/db";
import { Constants } from "./../constants";

//<!--JOURNAL-DETAIL-->
const TrashJournalDetail = (props) => {
  //de-structure from props
  const { navigation, route } = props;

  //de-structure from route-params
  let { journal, isRetroMode } = route.params;

  /**states */
  const [activeJournal, setActiveJournal] = useState(null);
  const [date, setDate] = useState(null);
  const [content, setContent] = useState();
  const [favoriteStatus, setFavoriteStatus] = useState(false);
  const [hasJournalImage, setHasJournalImage] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [position, setPosition] = useState(null);
  const [locationInfo, setLocationInfo] = useState(null);

  //<--custom hooks-->
  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  if (!isMounted) return null;

  /**hooks */
  useEffect(() => {
    console.log(`📖[JournalDetail] Loading...`);
    //set active journal
    let journalObj = {};
    Object.assign(journalObj, journal);
    setActiveJournal(journalObj);

    //set date
    const ticks = Number(journal.entryCreatedDate);
    const dt = ticksToDate(ticks);
    setDate(dt);

    //set favorite status
    if (activeJournal) setFavoriteStatus(activeJournal?.isFavorite);
    else setFavoriteStatus(journal?.isFavorite);

    return () => {
      console.log("TrashJournalDetail: Unloading...");
      setActiveJournal(null);
    };
  }, []);

  //<!--functions-->
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  //<!--event-handlers-->
  const navigateBack = async () => {
    navigation.goBack();
  };

  const handlePurge = async () => {
    if (menuVisible) toggleMenu();

    /**handle purge */
    if (!isEmpty(activeJournal)) {
      await purgeJournalEntry(activeJournal?.guid);
    }

    navigateBack();
  };

  const handleRestore = async () => {
    if (menuVisible) toggleMenu();
    if (!isEmpty(activeJournal)) {
      await purgeJournalEntry(activeJournal?.guid);

      dispatch(updateJournal(activeJournal));
    }
    navigateBack();
  };

  //<!--components/renderers-->
  const BackAction = () => (
    <TopNavigationAction icon={BackIcon} onPress={navigateBack} />
  );

  const renderMenuAction = () => (
    <TopNavigationAction icon={OverflowMenuIcon} onPress={toggleMenu} />
  );

  const renderRightActions = () => {
    return (
      <>
        {favoriteStatus ? (
          <TopNavigationAction icon={FavIcon} />
        ) : (
          <TopNavigationAction icon={UnFavIcon} />
        )}

        <OverflowMenu
          anchor={renderMenuAction}
          visible={menuVisible}
          onBackdropPress={toggleMenu}
        >
          {/* restore */}
          <MenuItem
            accessoryLeft={AltUndoIcon}
            title="Restore"
            onPress={handleRestore}
          />

          {/* purge */}
          <MenuItem
            accessoryLeft={AltTrashIcon}
            title="Purge"
            onPress={handlePurge}
          />
        </OverflowMenu>
      </>
    );
  };

  return (
    <Layout style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>
        {/* <!--header--> */}
        <TopNavigation
          alignment="start"
          accessoryLeft={BackAction}
          accessoryRight={renderRightActions}
        />

        <Divider />

        {/* <!--MainView--> */}
        <>
          {activeJournal && (
            <Layout style={{ flex: 1 }}>
              <ScrollView
                style={{
                  flex: 1,
                }}
              >
                <Layout
                  style={{
                    height: "100%",
                  }}
                >
                  {/* Image */}
                  {activeJournal.imageData ? (
                    <Image
                      source={{
                        uri: activeJournal.imageData?.startsWith(
                          Constants.ImagePrefix
                        )
                          ? `${activeJournal.imageData}`
                          : `${Constants.ImagePrefix}${activeJournal.imageData}`,
                      }}
                      loading="lazy"
                      decoding="async"
                      style={{
                        width: "100%",
                        resizeMode: "cover",
                        height: 400,
                      }}
                    />
                  ) : activeJournal.imageThumbnail ? (
                    <Image
                      source={{
                        uri: activeJournal.imageThumbnail?.startsWith(
                          Constants.ImagePrefix
                        )
                          ? `${activeJournal.imageThumbnail}`
                          : `${Constants.ImagePrefix}${activeJournal.imageThumbnail}`,
                      }}
                      loading="lazy"
                      decoding="async"
                      style={{
                        width: "100%",
                        resizeMode: "cover",
                        height: 400,
                      }}
                    />
                  ) : null}

                  {/* Content */}
                  <Layout
                    style={{
                      flex: 1,
                      paddingHorizontal: 20,
                      //borderWidth: 2,
                      //borderColor: "green",
                    }}
                  >
                    {/* date */}
                    {date && (
                      <DateContainer
                        entryCreatedLongDate={activeJournal?.entryCreatedDate}
                      />
                    )}

                    {/* location */}
                    {activeJournal.location && (
                      <LocationContainer
                        locationInfo={
                          activeJournal.location
                            ? activeJournal.location
                            : locationInfo
                        }
                      />
                    )}

                    <Divider />

                    {/* title */}
                    {activeJournal.title ? (
                      Platform.OS === "web" ? (
                        <Text
                          //category="h4"
                          style={{
                            fontSize: 36,
                            fontWeight: "700",
                            justifyContent: "center",
                            letterSpacing: 0.3,
                          }}
                        >
                          {activeJournal.title}
                        </Text>
                      ) : (
                        <Text style={styles.title} category="h4">
                          {activeJournal.title}
                        </Text>
                      )
                    ) : null}

                    {/* richContent */}
                    <Layout
                      style={{
                        justifyContent: "space-between",
                        paddingTop: 7,
                        paddingBottom: 12,
                        //borderWidth: 2,
                        //borderColor: "Lime",
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                        }}
                      >
                        <MarkdownView isRetroMode={isRetroMode}>
                          {activeJournal.richContent}
                        </MarkdownView>
                      </View>

                      {/* tags */}
                      <View
                        style={{
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        <>
                          {activeJournal.tagsList.map((tag, index) => (
                            <View
                              key={index}
                              style={{
                                paddingRight: 3,
                                paddingLeft: 5,
                                paddingTop: 1,
                                paddingBottom: 2,
                                marginHorizontal: 1,
                                marginTop: 2,
                                backgroundColor: "#EBF8FF",
                                borderRadius: 9,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Text
                                category="c2"
                                style={{
                                  color: "#2B6CB0",
                                  textAlign: "center",
                                }}
                              >{`${tag?.name} `}</Text>
                            </View>
                          ))}
                        </>
                      </View>
                    </Layout>
                  </Layout>
                </Layout>
              </ScrollView>
            </Layout>
          )}
        </>
      </SafeAreaView>
    </Layout>
  );
};

export default TrashJournalDetail;
