import React, { useState } from "react";
import { useFocusEffect } from "@react-navigation/native";

/**ui */
import {
  Text,
  Divider,
  Layout,
  TopNavigation,
  TopNavigationAction,
} from "@ui-kitten/components";

/**libs */
import { SafeAreaView } from "react-native-safe-area-context";

/**local */
import { getJournalEntriesByTagName } from "../data/db";
import JournalEntry from "../models/journalEntry";
import JournalList from "../components/JournalList";
import { BackIcon } from "../components/Icons";
import EmptyView from "../components/EmptyView";
import Enumerations from "../constants/Enumerations";
import Fab from "../components/Fab";
import ErrorBoundary from "../components/ErrorBoundary";

export const Journals = (props) => {
  /**destructure */
  const { navigation, route } = props;

  /**params */
  let { tagName } = route.params;

  /**state */
  const [journals, setJournals] = useState([]);

  /**custom-hooks */

  /**hooks */
  useFocusEffect(
    React.useCallback(() => {
      console.log("📜🪝[Journals] fetchJournals");

      async function fetchTaggedJournals() {
        const journals = await getJournalEntriesByTagName(tagName);
        setJournals(journals);
      }
      fetchTaggedJournals();
    }, [])
  );

  /**components/renderers */
  const BackAction = () => (
    <TopNavigationAction icon={BackIcon} onPress={() => navigation.goBack()} />
  );

  /**event-handlers */
  const navigateToDetails = (item) => {
    navigation.navigate("Journal-Detail", { journal: item });
  };

  const handleCreateEntry = () => {
    let journalEntry = new JournalEntry();
    journalEntry.tagsList = [{ name: tagName }];

    navigation.navigate("Journal-Detail", {
      journal: journalEntry,
      isNewEntry: true,
    });
  };

  return (
    <Layout style={{ flex: 1 }} level="2">
      <SafeAreaView style={{ flex: 1 }}>
        <TopNavigation
          appearance="default"
          accessoryLeft={BackAction}
          title={() => (
            <Layout>
              <Text
                category="h4"
                style={{
                  //paddingTop: 30,
                  //paddingLeft: 6,
                  fontWeight: "700",
                  fontSize: 32,
                  marginBottom: -6,
                }}
              >{`Journals`}</Text>
              <Text
                category="c1"
                appearance="hint"
                style={{
                  fontFamily: "Tox-Typewriter",
                }}
              >
                {`#${tagName}`}
              </Text>
            </Layout>
          )}
        />
        <ErrorBoundary>
        <Divider />

        {journals.length > 0 ? (
          <JournalList
            journals={journals}
            //  toggleFavorites={toggleFavorites}
            //  deleteJournal={deleteJournal}
            navigateToDetails={navigateToDetails}
          />
        ) : (
          <EmptyView
            information={`No journals are found for the tag "${tagName}"!`}
            icon={Enumerations.Journals}
          />
        )}

        <Layout style={{ marginBottom: 30 }}>
          <Fab createNewEntry={handleCreateEntry} />
          {/*<StatusBar />*/}
        </Layout>
        </ErrorBoundary>
      </SafeAreaView>
    </Layout>
  );
};

export default React.memo(Journals);
