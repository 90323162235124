import React from "react";
import { Text } from "@ui-kitten/components";

const JournalDescription = ({ description, isRetroMode }) => (
  <Text
    numberOfLines={2}
    style={{
      fontSize: 14,
      fontFamily: isRetroMode ? "Tox-Typewriter" : "Lora-Regular",
      paddingHorizontal: 8,
    }}
    appearance="hint"
  >
    {description}
  </Text>
);

export default React.memo(JournalDescription);
