import React, { useEffect } from "react";

import {
  makeRedirectUri,
  ResponseType,
  useAuthRequest,
  Prompt,
} from "expo-auth-session";

const useDropboxAuth = () => {
  const discovery = {
    authorizationEndpoint: "https://www.dropbox.com/oauth2/authorize",
    tokenEndpoint: "https://www.dropbox.com/oauth2/token",
  };

  const config = {
    clientId: "fymo7vmrqb2tasr",
    scopes: [],
    extraParams: {
      force_reapprove: true,
      token_access_type: "offline",
    },
    usePKCE: false,
    responseType: ResponseType.Code,
    redirectUri: makeRedirectUri({
      preferLocalhost: false,
      path: "redirect",
      native: "com.getglimpses.app://redirect",
    }),
  };

  let [request, response, promptAsync] = useAuthRequest(config, discovery);

  /**effects */
  useEffect(() => {
    //if (response?.type === "success") {
    //  console.log("[useDropboxAuth][CODE][response] >>", response);
    //}
  }, [response]);

  /**hook returns */
  return [request, response, promptAsync];
};

export default useDropboxAuth;
