import React from "react";
import { View } from "react-native";
import Colors from "./../constants/Colors";
import {
  CameraIcon,
  EmptyGlassIcon,
  EmptyTrashIcon,
} from "./../components/Icons";

//<!--libs-->
import { Layout, Text } from "@ui-kitten/components";
//<!--local-->
import Enumerations from "./../constants/Enumerations";
import {
  EmptyJournalIcon,
  EmptyCameraIcon,
  EmptyFavIcon,
} from "./../components/Icons";

const EmptyView = (props) => {
  const { information, icon } = props;
  return (
    <Layout style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <View
        style={{
          backgroundColor: "#9AE6B4",
          height: 60,
          width: 60,
          borderRadius: 30,
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 20,
        }}
      >
        {icon === Enumerations.Journals && <EmptyJournalIcon />}
        {icon === Enumerations.Photos && <EmptyCameraIcon />}
        {icon === Enumerations.Favorites && <EmptyFavIcon />}
        {icon === Enumerations.Articles && <EmptyGlassIcon />}
        {icon === Enumerations.Trash && <EmptyTrashIcon />}
      </View>

      <Text
        appearance="hint"
        style={{ fontWeight: "300", fontSize: 20, textAlign: "center" }}
      >
        {information}
      </Text>
    </Layout>
  );
};

export default React.memo(EmptyView);
