import { View } from "react-native";
import React from "react";

import { Divider, Layout, Text } from "@ui-kitten/components";
import { ticksToCustomDateFormat } from "../utils/dateUtils";

const DateContainer = ({ entryCreatedLongDate }) => {
  const dtObj = ticksToCustomDateFormat(Number(entryCreatedLongDate));
  return (
    <>
      <View style={{ flexDirection: "row" }}>
        {/* date */}
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Text numberOfLines={1} category="h1" style={{ fontSize: 50 }}>
            {dtObj?.date}
          </Text>
        </View>

        {/* Month,Year,Day,time */}
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: 10,
          }}
        >
          {/* Month,Year */}
          <View style={{}}>
            <Text category="p1">{`${dtObj?.month} ${dtObj?.year}`}</Text>
          </View>

          {/*  */}
          <View style={{}}>
            <Text category="p1">{`${dtObj?.day}, ${dtObj?.time}`}</Text>
          </View>
        </View>
      </View>
    </>
  );
};

export default React.memo(DateContainer);
