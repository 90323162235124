import {
  SET_REFRESH_TOKEN,
  SET_ACCOUNT,
  SET_THEME,
  TOGGLE_RETRO_MODE,
  SET_CURSOR,
  SET_PIN,
  SET_LOADING,
  LOGOUT,
} from "./../actions/types";

/**INITIAL STATE */
const initialState = {
  refreshToken: "",
  account: {},
  loading: false,
  error: null,
  selectedTheme: "light",
  isRetroMode: false,
  cursor: null,
  isLoggedIn: false,
  pin: "",
};

const dataReducer = (state = initialState, action) => {
  // console.log(`xxxxxxxxx ${action.type}xxxxxxxxx`);
  switch (action.type) {
    case SET_CURSOR:
      return {
        ...state,
        error: null,
        cursor: action.payload,
      };
      break;

    case SET_REFRESH_TOKEN:
      return {
        ...state,
        error: null,
        refreshToken: action.payload,
        isLoggedIn: true,
      };
      break;

    case SET_ACCOUNT:
      return {
        ...state,
        error: null,
        account: action.payload,
      };
      break;

    case SET_THEME:
      return {
        ...state,
        error: null,
        selectedTheme: state.selectedTheme === "light" ? "dark" : "light",
      };
      break;

    case TOGGLE_RETRO_MODE:
      console.log(`xxxxxxxxx ${action.type}xxxxxxxxx`);
      return {
        ...state,
        error: null,
        isRetroMode: !state.isRetroMode,
      };
      break;

    case SET_PIN:
      return {
        ...state,
        error: null,
        pin: action.payload,
      };
      break;

    case SET_LOADING:
      return {
        ...state,
        error: null,
        loading: action.payload,
      };
      break;

    case LOGOUT:
      return {
        ...initialState,
      };
      break;

    default:
      return state;
      break;
  }
};

export default dataReducer;
