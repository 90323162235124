import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";

/**UI */
import { Divider, Layout, StyleService, Text } from "@ui-kitten/components";
import { getVersion } from "../../utils/customUtils";

const Footer = () => (
  <Layout>
    <SafeAreaView insets="bottom">
      <>
        <Divider />
        <Layout style={styles.footer}>
          <Text
            category="p2"
            appearance="hint"
          >{`Version ${getVersion()}`}</Text>
        </Layout>
      </>
    </SafeAreaView>
  </Layout>
);

const styles = StyleService.create({
  footer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingLeft: 16,
    paddingBottom: 3,
  },
});

export default React.memo(Footer);
