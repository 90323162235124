import React from "react";

/**UI */
import {
  Drawer,
  DrawerItem,
  IndexPath,
  Layout,
  Text,
} from "@ui-kitten/components";

/**libs */
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  //DrawerItem,
} from "@react-navigation/drawer";
import { useLiveQuery } from "dexie-react-hooks";

/**local */
import { getDeletedJournalEntriesCount } from "../../data/db";
import Header from "./Header";
import Footer from "./Footer";
import { BottomTabNavigator } from "../Tabs";
import {
  ArticleStackScreens,
  TagsStackScreens,
  TrashStackScreens,
} from "../Stacks";
import {
  AltBookIcon,
  AltBookmarkIcon,
  AltEmptyTrashIcon,
  GlassIcon,
} from "../../components/Icons";
import { StyleSheet, View } from "react-native-web";

/**Global */
let theme;
const DrawerNav = createDrawerNavigator();

function DrawerContent({ navigation, state }) {
  const binEntryCount = useLiveQuery(() => getDeletedJournalEntriesCount(), []);

  return (
    <Drawer
      //header pass theme to header
      header={(props) => <Header {...props} selectedTheme={theme} />}
      footer={Footer}
      selectedIndex={new IndexPath(state.index)}
      onSelect={(index) => navigation.navigate(state.routeNames[index.row])}
    >
      {/* home */}
      <DrawerItem
        title="Home"
        accessoryLeft={AltBookIcon}
        //onPress={() => navigation.closeDrawer()}
      />

      {/* tags */}
      <DrawerItem
        title="Tags"
        accessoryLeft={AltBookmarkIcon}
        //onPress={() => navigation.toggleDrawer()}
      />

      {/* articles */}
      <DrawerItem
        title="Articles"
        accessoryLeft={GlassIcon}
        //onPress={() => navigation.toggleDrawer()}
      />

      {/* trash */}
      <DrawerItem
        title="Bin"
        accessoryLeft={(props) => (
          <Layout
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              backgroundColor: "transparent",
              marginRight: binEntryCount > 0 ? -7 : 0,
              marginLeft: 2,
            }}
          >
            <AltEmptyTrashIcon {...props} />

            {binEntryCount > 0 && (
              <View
                style={{
                  backgroundColor: "#EBF8FF",
                  height: 18,
                  width: 18,
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: -10,
                  marginTop: -9,
                  borderRadius: 50,
                }}
              >
                <Text
                  category="c1"
                  style={{
                    color: "#3182CE",
                    fontWeight: "700",
                    fontSize: 12,
                  }}
                >
                  {binEntryCount ? binEntryCount : 0}
                </Text>
              </View>
            )}
          </Layout>
        )}
        //onPress={() => navigation.toggleDrawer()}
      />

      {/* scrap */}
      {/*<DrawerItem title="Test" />*/}
    </Drawer>
  );
}

export const DrawerNavigator = ({ selectedTheme }) => {
  theme = selectedTheme;

  return (
    <DrawerNav.Navigator
      useLegacyImplementation
      screenOptions={{ headerShown: false }}
      drawerContent={(props) => <DrawerContent {...props} />}
    >
      {/*<DrawerNav.Screen name="Feed" component={Feed} />*/}
      <DrawerNav.Screen name="JournalScreen" component={BottomTabNavigator} />
      <DrawerNav.Screen name="TagsScreen" component={TagsStackScreens} />
      <DrawerNav.Screen name="ArticleScreen" component={ArticleStackScreens} />
      <DrawerNav.Screen name="TrashScreen" component={TrashStackScreens} />
      {/*<DrawerNav.Screen name="ScrapScreen" component={ScrapStackScreens} />*/}
    </DrawerNav.Navigator>
  );
};
