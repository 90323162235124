//import { v4 as uuidv4 } from "uuid";
//import { v1 as uuidv1 } from "uuid";
import { dateToTicks } from "../utils/dateUtils";

export default class JournalEntry {
  constructor(data) {
    this.guid = data?.guid ? data.guid : uuidv4();
    this.entryCreatedDate = data?.entrycreateddate
      ? data.entrycreateddate
      : dateToTicks(new Date());
    this.entryModifiedDate = data?.entrymodifieddate
      ? data.entrymodifieddate
      : dateToTicks(new Date());
    this.title = data?.title ? data.title : "";
    this.richContent = data?.richcontent ? data.richcontent : "";
    this.clientRevision = data?.clientrevision;
    this.imageData = data?.imagedata;
    this.clientRev = data?.clientrev ? data.clientrev : "";
    this.isFavorite = data?.isfavorite;
    this.tagsList = data?.tagsList ? data.tagsList : [];
    this.latitude = data?.latitude;
    this.longitude = data?.longitude;
    this.celcius = data?.celcius;
    this.fahrenheit = data?.fahrenheit;
    this.relativeHumidity = data?.relativehumidity;
    this.windSpeedInKph = data?.windspeedinkph;
    //xtra
    this.imageThumbnail = data?.imagethumbnail;
    this.location = data?.location;
    // this.hasImage = hasImage;
  }
}

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
